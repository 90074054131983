import React, { useContext, useState } from "react";
import { Form, Checkbox } from "semantic-ui-react";
import ConfigPopup from "../components/ConfigPopup";
import {getPostHeaders} from "../utils/headers";
import { useForm } from '../util/hooks';
import { AuthContext } from "../context/auth";
// import StyledDropzone from '../components/StyledDropzone';
import FPDrop from '../components/FPDrop';

// import { Helmet } from 'react-helmet'

function AddProperties({handleCallback}) {    
    const { user } = useContext(AuthContext);
    const [errors, setErrors] = useState({});           

    const { onChange, onSubmit, values } = useForm(addProperty, {
        nickname: '',
        airbnb_url: '',
        vrbo_url: '',
        imageId: '',
    });
    
    function performValidation() {        
        if (!values.vrbo_url.includes("unitId=") && values.vrbo_url.length > 1) {            
            setErrors({...errors, vrbo_url: "Invalid VRBO URL, must include unitId="});
            return false;
        }
        if (!values.airbnb_url.includes("/rooms/") && values.airbnb_url.length > 1) {
            setErrors({...errors, airbnb_url: "Invalid Airbnb URL"});
            return false;
        }
        if (values.airbnb_url.length < 1 && values.vrbo_url.length < 1) {
            setErrors({...errors, airbnb_url: "Either Airbnb URL or VRBO URL must not be blank", vrbo_url: "Please enter a valid URL"});
            return false;
        }

        if (values.nickname.length < 1) {
            setErrors({...errors, nickname: "Please enter a nickname"});
            return false;
        }
        setErrors({});
        return true;
    }

    async function addProperty() {        
        const headers = getPostHeaders({values}, "POST");          
        let isValid = performValidation();
        if (!user) { user.context.logout({}); }
        if (isValid) { 
            const response = await fetch(`${process.env.REACT_APP_BE_BASE_URL}/api/add_property`, 
                {...headers});
            if (response.status === 401) {            
                user.context.logout({});
            }            
            const body = await response.json().catch(error => console.log('error', error));        
            handleCallback(true);
        }
    }   

    const handleImageCallback = (id) => {                
        values.imageId = id;
    }

    return (
        <>
        <div className="mt-14"></div>
        <header className="mb-4 bg-white shadow bg-gradient-to-r from-cyan-500 to-blue-500">
          <div className="mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold tracking-tight text-gray-900 ">Add New Listing</h1><span className="text-gray-300 text-md">A 'door' is a rentable unit</span>
          </div>
        </header>
                <Form onSubmit={onSubmit}>                                            
                        <Form.Input 
                            label="Nickname"
                            placeholder='Nickname' 
                            name='nickname'
                            value={values.nickname}
                            error={errors.nickname ? true : false}
                            onChange={onChange}
                        />                                        
                        <ConfigPopup 
                            trigger={                                                  
                                <Form.Input 
                                    label="Airbnb URL"
                                    placeholder='https://www.airbnb.com/rooms/12345678' 
                                    name='airbnb_url'
                                    value={values.airbnb_url}
                                    error={errors.airbnb_url ? true : false}
                                    onChange={onChange}
                                />
                            } 
                            content={"Locate your property on https://airbnb.com and paste the link below"}
                        />  
                    
                    
                        <ConfigPopup
                            trigger={
                                    <Form.Input 
                                    label="VRBO URL"
                                    placeholder='https://www.vrbo.com/1234567?adultsCount=2&arrival=...&unitId=1234567' 
                                    name='vrbo_url'
                                    value={values.vrbo_url}
                                    error={errors.vrbo_url ? true : false}
                                    onChange={onChange}
                                />    
                            }
                            content={"Locate your property on https://vrbo.com and paste the link below"}
                        />  
                        <FPDrop handleImageCallback={handleImageCallback}/>
                        {/* <StyledDropzone /> */}
                        
                    {/* <Form.Field>
                        <Checkbox label='I agree to the Terms and Conditions' />
                    </Form.Field> */}
                    <button type='submit' className="rounded-lg shadow-lg bg-blue-400 p-2 align-top m-2 pl-6 pr-6 text-blue-50">Save</button>
                    {/* <Button type='submit' primary disabled={!performValidation}>Save</Button> */}
            </Form>
        {/* </Segment> */}
        {Object.keys(errors).length > 0 && (
                <div className="ui error message">
                    <ul className="list">
                        {Object.values(errors).map((value) => (
                            <li key={value}>{value}</li>
                        ))}
                    </ul>
                </div>
            )}



      {/* </Container> */}
      </>      
    )
}

export default AddProperties;