import React, {useState} from "react";
import {Icon} from "semantic-ui-react";
import { isMobile } from "react-device-detect";
import {Filesystem, Directory} from "@capacitor/filesystem";
import * as htmlToImage from 'html-to-image';
import { getPostHeaders } from "../../utils/headers";
import { AuthContext } from '../../context/auth';
import Binder from "../../pages/Binder";


export default function SaveToBinderButton({imageSaveIcon, shareable, printRef, postPurpose, selectedPostSize, updatePage, iconStyle, useTest}) {
    const [buttonLoading, setButtonLoading] = useState(false);
    const [buttonCursor, setButtonCursor] = useState("pointer");

    const WIDTHINDEX = 0;
    const HEIGHTINDEX = 1;

    const handleSaveToBinder = async (bookUri, propertyId) => {        
        setButtonLoading(true);
        setButtonCursor("progress")   
        if (useTest) {
            testSaveBinderOnMobile(bookUri, propertyId);
        } else      
        if (isMobile) {
            saveBinderOnMobile(bookUri, propertyId);
            // setButtonLoading(false);
            // setButtonCursor("pointer")
        } else {            
            saveBinderOnStandard(bookUri, propertyId);                        

        }        
    }

    function saveBinderOnMobile(bookUri, propertyId) {
        // this is a hack. The first time the image is saved, it is not complete.
        // The second time it is saved, only text overlay is shown
        // Third time, all is good. This only happens the first time however.
        // added 4th time bc was still not working on mobile when using non default image
        // New images are saved correctly.        
        
        htmlToImage.toPng(printRef.current, {
            cacheBust: true, 
            quality: 1, 
            pixelRatio: 1,             
            style: {}, 
            // width:, 
            // height:,
            canvasWidth: selectedPostSize[WIDTHINDEX], 
            canvasHeight: selectedPostSize[HEIGHTINDEX],
        }).then((dataUrl) => {
                htmlToImage.toPng(printRef.current, {
                    cacheBust: true, 
                    quality: 1, 
                    pixelRatio: 1,             
                    style: {}, 
                    canvasWidth: selectedPostSize[WIDTHINDEX], 
                    canvasHeight: selectedPostSize[HEIGHTINDEX], 
                }).then((dataUrl) => {
                    htmlToImage.toPng(printRef.current, {
                        cacheBust: true, 
                        quality: 1, 
                        pixelRatio: 1,             
                        style: {},       
                        canvasWidth: selectedPostSize[WIDTHINDEX], 
                        canvasHeight: selectedPostSize[HEIGHTINDEX],
                }).then((dataUrl) => {
                    htmlToImage.toPng(printRef.current, {
                        cacheBust: true, 
                        quality: 1, 
                        pixelRatio: 1,             
                        style: {},                      
                        canvasWidth: selectedPostSize[WIDTHINDEX], 
                        canvasHeight: selectedPostSize[HEIGHTINDEX],
                    }).then((dataUrl) => {
                        Filesystem.writeFile({
                            path: 'ownerguest.png',
                            data: dataUrl,
                            directory: Directory.Documents,
                        })
                        bookUri = bookUri.replace(" ", "_")
                        // bookUri = `https://ownerguest.com/book/${bookUri}`
                        handleBinder(dataUrl,bookUri, propertyId, postPurpose);  
                    })            
                })
            })
        })
    }
    
    function testSaveBinderOnMobile(bookUri, propertyId) {
        // this is a hack. The first time the image is saved, it is not complete.
        // The second time it is saved, only text overlay is shown
        // Third time, all is good. This only happens the first time however.
        // added 4th time bc was still not working on mobile when using non default image
        // New images are saved correctly.
        
        console.log(`devicePixelRatio: ${window.devicePixelRatio}`);
        const pixelRatio = Math.min(window.devicePixelRatio, 2); // set a maximum pixel ratio of 2
        console.log(`final devicePixelRatio: ${pixelRatio}`);
        const canvasWidth = Math.min(selectedPostSize[WIDTHINDEX], 8192 / pixelRatio); // reduce the canvas width if it exceeds the maximum allowed
        const canvasHeight = Math.min(selectedPostSize[HEIGHTINDEX], 8192 / pixelRatio); // reduce the canvas height if it exceeds the maximum allowed
        console.log(`canvasWidth: ${canvasWidth}, canvasHeight: ${canvasHeight}`);
        
        const pngProps = {            
            cacheBust: true, 
            quality: 1, 
            pixelRatio: pixelRatio,             
            style: {}, 
            canvasWidth: canvasWidth, 
            canvasHeight: canvasHeight,
         }
        
        htmlToImage.toPng(printRef.current,pngProps)
                .then((dataUrl) => {
                    console.log(JSON.stringify(pngProps));
                    htmlToImage.toPng(printRef.current, pngProps)
                .then((dataUrl) => {
                    htmlToImage.toPng(printRef.current, pngProps)
                .then((dataUrl) => {
                    htmlToImage.toPng(printRef.current, pngProps)
                .then((dataUrl) => {
                        Filesystem.writeFile({
                            path: 'ownerguest.png',
                            data: dataUrl,
                            directory: Directory.Documents,
                        })
                        bookUri = bookUri.replace(" ", "_")
                        // bookUri = `https://ownerguest.com/book/${bookUri}`
                        handleBinder(dataUrl,bookUri, propertyId, postPurpose);  
                    })            
                })
            })
        })

        // debugger;
        
    }

    function saveBinderOnStandard(bookUri, propertyId) {  
        htmlToImage.toPng(printRef.current, {
            cacheBust: true, 
            quality: 1, 
            pixelRatio: 1,             
            style: {}, 
            canvasWidth: selectedPostSize[WIDTHINDEX], 
            canvasHeight: selectedPostSize[HEIGHTINDEX],
        })
        .then((dataUrl) => {             
            bookUri = bookUri.replace(" ", "_")
            handleBinder(dataUrl, bookUri, propertyId, postPurpose);
        }).catch((error) => {            
            console.log(`error saving review to binder, ${error}`)
            console.log(`Saving binder error: ${error}`)
        })
    }


    async function handleBinder(dataUrl, bookUri, propertyId, postPurpose) {  
        // debugger;
        const resizeTo = [selectedPostSize[WIDTHINDEX], selectedPostSize[HEIGHTINDEX]];    
        const headers = getPostHeaders({dataUrl, bookUri, propertyId, resizeTo, postPurpose}, "POST");                  
        const response = await fetch(`${process.env.REACT_APP_BE_BASE_URL}/api/binders/`, 
            {...headers});
        if (response.status === 401) {            
            AuthContext.logout({});
        }            
        const body = await response.json().catch((error) => {
            setButtonLoading(false);            
            console.log('handleBinder error', error)
        });    
        setButtonLoading(false);            
        updatePage(<Binder/>)
        return body;
    }


    return (
        <div>
            <Icon 
                circular 
                inverted 
                color={imageSaveIcon}  
                // active={buttonLoading} 
                loading={buttonLoading} 
                name='save outline' 
                style={iconStyle} 
                onClick={()=>handleSaveToBinder(shareable?.user_property.nickname, shareable?.user_property.id)} 
                 />
        </div>
    )

}