import React, { useRef } from "react"

export default function UseSelectedText({setHighlight, value}) {
  const ref = useRef(null);
     
  const handleMouseUp = () => {
    if (window.getSelection) {
      setHighlight(window.getSelection().toString())
    } else if (typeof document.selection !== "undefined") {
      setHighlight(document.selection().toString());
    }
  }

  const handleTouchEnd = (e) => {
    e.preventDefault();    
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
        // console.log(selection.toString());
      setHighlight(selection.toString());
    }
  }

  return (
    <div ref={ref} onMouseUp={handleMouseUp} onTouchEnd={handleTouchEnd} style={{
        userSelect:"text", 
        WebkitUserSelect: "text",
        cursor: "text",
        position: "relative",
        zIndex: 0}}>
      {value}
    </div>
  );
}