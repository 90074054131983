import React from "react";
import { Container, Button, Header } from "semantic-ui-react";

export default function Insights({backToHome, handleSignUp}) {
    

  return (
    <>
    <Container text style={{paddingBottom:"1%", paddingTop:"1%"}}>
        <Header size='huge'>Why OwnerGuest?</Header>                                                
    </Container>
    <Container text>
      <p style={{fontSize:"1.5rem", marginBottom:"1.5rem"}}>                  
          OwnerGuest makes it super easy for you to market your property on social media by
          combining guest reviews with the property images.
          </p>
          <p style={{fontSize:"1.5rem", marginBottom:"1.5rem"}}>
          Let's face it,
          property images are a big deal when it comes to attracting guests.
          They want to see what they're getting before they book, and this
          product allows you to upload high-quality images that will make your
          property stand out. Plus, these images are optimized for social media
          so they're sure to grab attention. 
          </p>
          <p style={{fontSize:"1.5rem", marginBottom:"1.5rem"}}>
          But it's not just about the images,
          guest reviews are crucial too. They build trust with potential guests
          and make them more likely to book. And with OwnerGuest, you can
          easily gather and display these reviews on your website, social media
          and or even through email. 
          </p>
          <p style={{fontSize:"1.5rem", marginBottom:"1.5rem"}}>
          And the
          best part? OwnerGuest uses AI to help you with the language and text
          used to promote your property. This can help you craft more engaging
          and persuasive descriptions for your posts and tweets, as well as
          landing page text. Saving you hours of time and giving you the chance
          to reach a larger audience. 
          </p>
          <p style={{fontSize:"1.5rem", marginBottom:"1.5rem"}}>
          In short, if you're looking for an
          efficient and effective way to market your short-term rental property
          on social media, this SaaS product is the way to go. It makes it easy
          for you to combine guest reviews with property images, saving you time
          and helping you reach more potential guests. Give it a try, you won't
          be disappointed!
         
          </p>
    </Container>
    <Container text style={{paddingBottom:"1%", paddingTop:"1%"}}>
    <Button color="blue" onClick={backToHome}>Cancel</Button><Button color="blue" onClick={handleSignUp}>Try It Now</Button>
    </Container>
    </>
  );
}
