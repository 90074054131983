import React, {useEffect, useState} from 'react';


export default function DesignImage({    
    postWidth,
    postHeight,     
    currentIncrementImageWidthValue,
    
    currentXImagePositionValue,
    currentYImagePositionValue,
    currentPaddingValue,
    currentRadiusValue,
    currentShadowValue,
    currentBlurValue,
    currentSepiaValue,
    currentGrayScaleValue,
    objectFitConfig,
    
    selectedPicture, 
    shareable}) {
        
    

    function getImageFit() {        
        var viewableWidth = window.innerWidth; 
        var factor = viewableWidth / postWidth;                    
        
        if (factor > 1) factor = 0.9;
        var width = (postWidth* factor) * .8;
        var height = (postHeight * factor) * .8;
        // console.log(`getImageFit: ${width} ${height}`)
    
        if (currentIncrementImageWidthValue === 0) {                    
            if (height > width) {
                height = 'auto';
                width = `${width}px`;
            } else if (width > height) {
                width = 'auto';
                height = `${height}px`;
            } else {                
                width = 'auto';
                height = 'auto';
            }
        }
        else if (currentIncrementImageWidthValue !== 0) {            
            if (width !== 'auto') {
                width += currentIncrementImageWidthValue;
                width = `${width}px`;
            }
            if (height !== 'auto') {
                height += currentIncrementImageWidthValue;
                height = `${height}px`;
            }
        }
        // console.log(`getImageFit, returning: ${width} ${height}`)
        return {width, height}
    }


    function handleInlineImageStyle() {             
        var objectFit = `${objectFitConfig}`;        
        const {width, height} = getImageFit();  
        // debugger;
        // console.log(`handleInlineImageStyle: ${width} ${height}`)
        return {                            
            width: `${width}`,
            height: `${height}`,
            display: 'flex',
            margin: 'auto',            
            alignItems: 'center',
            justifyContent: 'center',
            objectFit: `${objectFit}`,
            padding: `${currentPaddingValue}px`,
            borderRadius: `${currentRadiusValue}rem`,
            boxShadow: `0 9px 36px ${currentShadowValue}px rgba(0,0,0,0.5)`,
            filter: `blur(${currentBlurValue}px) sepia(${currentSepiaValue}) grayscale(${currentGrayScaleValue})`,
            transform: `translate(${currentXImagePositionValue}rem, ${currentYImagePositionValue}rem)`,            
            
        }
    } 
    
    return (
        <>
            <img style={handleInlineImageStyle()}                              
                id="currentImg"                                                            
                src={selectedPicture}
                alt={shareable?.user_property.nickname} 
            />                             
        </>
    )
}