import React, { useContext, useState } from 'react';
import { Container, Segment, Header, Button, Form } from 'semantic-ui-react';
import { useForm } from '../../util/hooks';
import { AuthContext } from '../../context/auth';
import PasswordReset from '../PasswordReset';


function Login({setShowLogin, setShowSignUp}) {    
    const context = useContext(AuthContext);
    const [errors, setErrors] = useState({});
    const [generalError, setGeneralError] = useState('')
    const [loading, setLoading] = useState(false);  
    const [showPasswordReset, setShowPasswordReset] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [enableDemo, setEnableDemo] = useState(false);

    const { onChange, onSubmit, values } = useForm(loginUser, {
        email: '',
        password: '',
    });

    const handleResetPassword = () => {
        setShowPasswordReset(true);
    }
    
    
    function loginUser() {          
        setButtonLoading(true);                                 
        context.login(values, setGeneralError, setButtonLoading);          
    }
 
    return (       
            <Container>
                <Segment.Group></Segment.Group>
                <Segment.Group>
                    <Segment>
                        {showPasswordReset && <PasswordReset                             
                            setGeneralError={setGeneralError} />}
                        {!showPasswordReset && 
                            <>
                            <Header as="h3">
                                Login
                            </Header>
                            
                            <Form onSubmit={onSubmit} noValidate className={loading ? 'loading' : ''}>
                                <Form.Input
                                    label="Email"
                                    placeholder="Email.."
                                    name="email"
                                    type="text"
                                    value={values.email}
                                    error={errors.email? true : false}
                                    onChange={onChange}
                                />
                                <Form.Input                                
                                    label="Password"
                                    placeholder="Password.."
                                    name="password"
                                    type="password"
                                    value={values.password}
                                    error={errors.password? true : false}
                                    onChange={onChange}
                                />                                       
                                <Button loading={buttonLoading} type="submit" primary>
                                    Login
                                </Button>
                            </Form>
                            <button as='link' onClick={()=>handleResetPassword()}>Forgot password?</button>
                            </>
                        }
                    </Segment>
                    {enableDemo && (
                        <Segment>
                            <Header as="h3">
                                Demo Login
                            </Header>
                            <div>Login: hello+demo@ownerguest.com</div>
                            <div>Password: OwnerGuest</div>
                        </Segment>
                    )}
                </Segment.Group>
                {generalError && (
                    generalError.includes('Success') ? (
                        <div className="ui success message">
                            {generalError ? generalError : 'Success'}
                        </div>
                    ) : (
                        <div className="ui error message">
                            {generalError ? generalError : 'no errors'}
                        </div>
                    )
                )} 
                                                    
            
        </Container>
    
    )
}


export default Login;