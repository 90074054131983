import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../context/auth";
import {getGetHeaders} from "../utils/headers";
import { Radio } from "semantic-ui-react";

function DoorSelector({platform, handleDoorSelectionCallback}) {
    const context = useContext(AuthContext);
    const [properties, setProperties] = useState([]);    
    const [selectedDoor, setSelectedDoor] = useState();
    
    useEffect(() => {        
        async function getProperties() {
            let headers = getGetHeaders();
            const resp = await fetch(`${process.env.REACT_APP_BE_BASE_URL}/api/get_properties/`, {
                headers      
            })
            if (resp.status === 401) {
                context.logout({})
            }
            const data = await resp.json(); 

            var filteredProperties = data.filter(function(property){
                if (platform === "airbnb") {
                    return property.airbnb_url != ""; 
                } else if (platform === "vrbo") {
                    return property.vrbo_url != "";
                }
            });
            
            setProperties(filteredProperties);     
            if (filteredProperties.length > 0) {
                setSelectedDoor(filteredProperties[0].id);
                handleDoorSelectionCallback(filteredProperties[0].id);
            }                                              
        }           
        getProperties();                    
    }, []);


    function handleSelectDoor(doorId) {                
        setSelectedDoor(doorId);           
        handleDoorSelectionCallback(doorId);     
    }


    return (
        <>
        {properties.map((property) => (
            <Radio
                key={property.id}
                onClick={() => handleSelectDoor(property.id)}  
                label={property.nickname}              
                // value={property.id}
                // checked={selectedDoor === property.id} 
                className="mr-2 p-2 text-sm text-gray-600"
            />
        ))}                                                   
        </>
    );
    
}
export default DoorSelector;