import React, { useState } from 'react';
import { Container, Segment, Header, Button, Form } from 'semantic-ui-react';
import { useForm } from '../util/hooks';
import { getPostHeaders } from '../utils/headers';

function PasswordReset({setGeneralError}) {           
    const [buttonLoading, setButtonLoading] = useState(false);

    const { onChange, onSubmit, values } = useForm(resetPassword, {
        email: '',

    });

    async function resetPassword() {
        setButtonLoading(true);
        if (values.email === '') {
            setGeneralError("Enter an email address");
            setButtonLoading(false);
            return;
        }        
        let headers = getPostHeaders({email: values.email});     
        // debugger;   
        const response = await fetch(`${process.env.REACT_APP_BE_BASE_URL}/api/password_reset/`,
            {...headers})
            
        var body = await response.json();        
        if (!response.ok) {
            setGeneralError(body["email"]);
            setButtonLoading(false);                       
            return;
        } else {
            setGeneralError("Success! Check email for password reset link");
            setButtonLoading(false);            
            return;
        }                    
    }

 
    return (
            <Container>
                {/* <Segment.Group></Segment.Group> */}
                <Segment.Group>
                    <Segment>
                        <Header as="h3">
                            Password Reset
                        </Header>
                        
                            <Form onSubmit={onSubmit} noValidate >
                                <Form.Input
                                    label="Email"
                                    placeholder="Email.."
                                    name="email"
                                    type="text"                                    
                                    value={values.email}
                                    // error={values.email.includes("Check email for password reset link") ? false : true}
                                    onChange={onChange}
                                />
                                                                
                                <Button loading={buttonLoading} type="submit" primary >
                                    Request Reset
                                </Button>
                            </Form>                                                    
                    </Segment>
                </Segment.Group>
                
            {/* {Object.keys(errors).length > 0 && (
                <div className="ui message">
                    <ul className="list">
                        {Object.values(errors).map((value) => (                            
                            <li key={value}>{value}</li>                                                        
                        ))}
                    </ul>
                </div>
            )} */}
            
        </Container>
    )
}


export default PasswordReset;