import React, { useContext } from 'react';
import { Menu } from 'semantic-ui-react';
import { AuthContext } from '../../context/auth';


function LogoutButton(props) {
  const context = useContext(AuthContext);

  var handleLogout = () => {            
    context.logout({});    
  }

    return(            
      <Menu.Item
        className={props.size === 'md' ? 'text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base text-lg' : 'text-white font-weight-bold text-lg font-medium'}
        name={props.name}
        active={props.activeItem}
        onClick={handleLogout}
      />
    )
}

export default LogoutButton;