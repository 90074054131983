import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../context/auth";
import { Icon, Table } from 'semantic-ui-react';
import Moment from 'react-moment';
import {getPostHeaders} from '../utils/headers'
import {getStars} from '../common/ReviewCommon'
import DoorSelector from "../components/DoorSelector";
import UseSelectedText from "../components/UseSelectedText";
import DesignStickyBase from "./DesignStickyBase";
import SelectPostSize from "../components/SelectPostSize";

function AirbnbReviews({updatePage}) {
    const context = useContext(AuthContext);
    const [airbnbData, setAirbnbData] = useState()
    const [loading, setLoading] = useState(true);
    const [showAll, setShowAll] = useState(false);    
    const [showDesisgnShareable, setShowDesignShareable] = useState(false);
    const [activeReview, setActiveReview] = useState();
    const [doorUpdated, setDoorUpdated] = useState(0);
    const [highlight, setHighlight] = useState('');    
    const [selectedPostSize, setSelectedPostSize] = useState([1200, 628]);
    const [postPurpose, setPostPurpose] = useState("Facebook Post Image");

    const showMore = () => setShowAll(true);
    const showLess = () => setShowAll(false);
    const limit = 150;    
   
    
    const handleDoorSelectionCallback = (doorId) => {        
        setDoorUpdated(doorId)
    }

    function get_review_content(review) {
        if (review.length <= limit) {
            // there is nothing more to show
            return review.replace('<br />', ' ')
          }
        else if (showAll) {
            return review
        } else {
            return review.substring(0, 200).replace('<br />', ' ') + "..."
        }
    }
      
    useEffect(() => {              
        async function queryAirbnb() {                     
            let headers = getPostHeaders({doorId:doorUpdated}, "POST");            
            const resp = await fetch(`${process.env.REACT_APP_BE_BASE_URL}/api/getairbnbreviews`, 
            {...headers})
            if (resp.status === 401) {
                context.logout({});                  
            }
            const data = await resp.json(); 
            // debugger; 
            if (data.length > 0) {                          
                setAirbnbData(data);  
                setLoading(false);                                                
            }
            
        }        
        queryAirbnb(); 
         // eslint-disable-next-line react-hooks/exhaustive-deps                 
    }, [doorUpdated]);

    
    function handleShare(review) {
        setActiveReview(review);
        setShowDesignShareable(!showDesisgnShareable);        
    }    

    const handleShareableCallback = () => {
        setShowDesignShareable(!showDesisgnShareable);
    }
    return (  
        <>                  
        {showDesisgnShareable ? (            
            <DesignStickyBase listingId={activeReview.user_property.id} postPurpose={postPurpose} selectedPostSize={selectedPostSize} updatePage={updatePage} review={activeReview} highlight={highlight} handleShareableCallback={handleShareableCallback} />)
            // <DesignShareablePq updatePage={updatePage} review={activeReview} highlight={highlight} handleShareableCallback={handleShareableCallback} />)
            // <DesignShareable updatePage={updatePage} review={activeReview} highlight={highlight} handleShareableCallback={handleShareableCallback} />) 
        : (
        <>                   
        <DoorSelector platform="airbnb" handleDoorSelectionCallback={handleDoorSelectionCallback}/>                                  
        {loading && airbnbData?.length === 0 ? (<div className="ui active centered inline loader"></div>) : 
        (                        
            <Table collapsing compact unstackable striped className="dnxTable">
                <Table.Header>
                    <Table.Row className="bg-slate-100 shadow-inner rounded-sm">
                        <Table.HeaderCell textAlign="center">Published</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">Reviewer</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center" width={2}><Icon name="star" /></Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">Description</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">Design</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                                                        
                <Table.Body>                    
                    {airbnbData && airbnbData.map((review) => (                                                
                        <Table.Row key={review.id} className="even:bg-sky-50 odd:bg-blue-100 text-slate-600" >
                            <Table.Cell data-label="Published"><Moment fromNow>{review.review_date}</Moment></Table.Cell>
                            <Table.Cell data-label="Reviewer">{review.reviewer}</Table.Cell>
                            <Table.Cell data-label="Stars"><div className="md:text-center">{getStars(review.rating)}</div></Table.Cell>                                
                            <Table.Cell data-label="Description">
                                <UseSelectedText setHighlight={setHighlight} value={get_review_content(review.review)} />
                                
                                {review.review.length > limit ? (
                                    showAll ? 
                                        (<button onClick={showLess} className="rounded-full shadow-lg bg-sky-50 pl-4 pr-4 pb-1 align-top m-2 text-slate-400">less</button> ) : 
                                        (<button onClick={showMore} className="rounded-full shadow-lg bg-sky-50 pl-4 pr-4 pb-1 align-top m-2 text-slate-400">more</button>)
                                    ) : null
                                }                                                                        
                            </Table.Cell>
                            {/* <Table.Cell>
                                <CustomTagPicker key={review.length+review.id} tagList={tagList} reviewId={review.id} handleCallback={handleCallback} >                                        
                                </CustomTagPicker>                                
                                    <CustomTagsDisplay key={review.id} reviewId={review.id} handleCallback={handleCallback} tagsUpdated={tagsUpdated} setTagsUpdated={setTagsUpdated}/>                                
                            </Table.Cell> */}
                            <Table.Cell data-label="Design">   
                                <div className="flex">
                                    <SelectPostSize setSelectedPostSize={setSelectedPostSize} setPostPurpose={setPostPurpose}/>&nbsp;
                                    <div className="tooltip" style={{cursor:"pointer"}} >
                                        <Icon size="big" name="picture" onClick={()=>handleShare(review)} style={{paddingLeft:"5px"}}/>
                                        <span className="tooltiptext">Create new design</span>
                                    </div>
                                </div>                             
                            </Table.Cell>                    
                        </Table.Row>                            
                    ))}                    
                </Table.Body>
            </Table>    
        )}    
           {airbnbData?.length === 0 ? (
                <div className="p-4 m-4">
                    <div className='text-2xl p-4'>No reviews found. You have not received reviews for this
                        property or we did not find any doors with an Airbnb URL configured.
                    </div>
                </div>
            ) : (<></> )}                                            
    </>
    )}
    </>
    );
}

                                                                   
   
export default AirbnbReviews;