import React from 'react'
import { Popup} from 'semantic-ui-react'

function ConfigPopup(props) {
    return (
        <Popup
        trigger={props.trigger}
        content={props.content}
        basic
      />
    )
}

export default ConfigPopup;