import React, {useContext, useState, useEffect} from "react";
import { AuthContext } from "../context/auth";
import { Icon, Label, Grid, Image, Segment, Divider } from 'semantic-ui-react';
import {getGetHeaders} from "../utils/headers";
import ReactTimeAgo from 'react-time-ago';
import '../dashboard.css';
import GraphHits from "./graphs/GraphHits";
import GraphClicks from "./graphs/GraphClicks";
import GraphReferrers from "./graphs/GraphReferrers";



function BinderAssetMetrics({binder, index, nickname}) {    
    const context = useContext(AuthContext);
    const [vrboCount, setVrboCount] = useState([]);
    const [airbnbCount, setAirbnbCount] = useState([]);
    const [enterCount, setEnterCount] = useState([]);
    const [exitCount, setExitCount] = useState([]);
    const [topReferrers, setTopReferrers] = useState([]);
    // const [topCity, setTopCity] = useState([]);
    const [hitTrend, setHitTrend] = useState();
    const [airbnbClickTrend, setAirbnbClickTrend] = useState();
    const [vrboClickTrend, setVrboClickTrend] = useState();    

    const date = new Date();
    const defaultDate = date.getTime();
    

    useEffect(() => {
        async function fetchBinderMetrics() {            
            let headers = getGetHeaders();            
            const resp = await fetch(`${process.env.REACT_APP_BE_BASE_URL}/api/bindermetrics/${binder.id}`, {
                headers
            })
            if (resp.status === 401) {
                context.logout({});
            }
            const data = await resp.json();  
            getActionTaken(data);
            // setTopCity(data.unique_cities[0]);
            setTopReferrers(data.top_referrers);
            setHitTrend(data.hit_trend)   
            setAirbnbClickTrend(data.airbnb_click_trend)
            setVrboClickTrend(data.vrbo_click_trend)              
            
        }
        fetchBinderMetrics();
    }, [binder, context]);

    function getActionTaken(data){        
        data && data.action_taken && Object.values(data.action_taken).map((value, index) => {          
            if (value.action_taken === "VRBO") {
                setVrboCount(value.count)
            } else if ((value.action_taken === "Airbnb") || (value.action_taken === "airbnb")) {
                setAirbnbCount(value.count)                
            } else if (value.action_taken === 'enter') {
                setEnterCount(value.count)
            } else {
                setExitCount(value.count)
            }
            // else if (value.action_taken === 'window.onbeforeunload') {
            //     setExitCount(value.count)
            // }            
        })
    }
   

    return (
        <>        
        <Grid.Row columns={3} >            
        <Grid.Column>                       
            <Segment textAlign="center">
                <div>
                    <a href="/binder" alt="Navigate to binder">
                        <img src={binder.image} alt={binder?.user_property?.nickname} style={{width:"-webkit-fill-available!important"}} />
                    </a>
                </div>
                <div className="mt-1">
                <Label>Created <ReactTimeAgo  date={binder?.created_date ? new Date(binder.created_date) :{defaultDate} } locale="en-US"/></Label>                    
                </div>
            </Segment>            
        </Grid.Column> 
        <Grid.Column width={5}>            
        <Segment textAlign="center">                
                {enterCount >=1  ? (
                    <Label title="Visitors Reached" color="blue" basic>
                        <Icon title="Visitors Reached"  name='announcement' />
                        {enterCount}
                    </Label>                        
                    ) : (
                        <Label title="Share to get more reach" color="grey" basic >
                                No Shares
                        </Label>  
                )}  &nbsp;                   
                {vrboCount >=1 || airbnbCount >= 1 ? (
                    <Label title="Users" basic color="green"  >
                        <Icon name='users' />
                        {vrboCount >=1 || airbnbCount >= 1 ? `${(((vrboCount + airbnbCount) / enterCount)*100).toFixed(2)}%`: ''}                        
                    </Label>    
                    ) : (
                        <Label title="Share to get more engagement" color="grey"  basic >
                                No engagement 
                        </Label>  
                    )}  
            </Segment>
            <Segment textAlign="center">
                <Label basic color="red" title="Number of visitors that clicked on Airbnb button">
                    <Image
                        className="imagestyle"
                        title="Number of visitors that clicked on Airbnb button"
                        floated='left'                        
                        src='/airbnb-1.svg'
                        style={{marginBottom:"1%"}}
                    />                    
                    Airbnb {airbnbCount >= 1 ? airbnbCount : 0}
                </Label> 
                <Label title="Number of visitors that clicked on Vrbo button" basic color="blue">
                    <Image
                        className="imagestyle"
                        title="Number of visitors that clicked on Vrbo button"
                        floated='left'                        
                        src='/vrbov_c_5.png'
                        style={{marginBottom:"1%"}}
                    />
                    Vrbo {vrboCount >= 1 ? vrboCount : 0}
                </Label>      
            </Segment>               
            <Segment textAlign="center">
            {topReferrers?.length > 0 ? 
                <GraphReferrers metrics={topReferrers}/>
                : <Label>No referrers yet</Label>}
            </Segment>           
        </Grid.Column>                        
        <Grid.Column width={5}>            
            <Segment >
                {hitTrend ? 
                <GraphHits metrics={hitTrend} />
                : <></>}
            </Segment>   
            <Segment>
                {airbnbClickTrend ? 
                    <GraphClicks platform={airbnbClickTrend} src="Airbnb" color="255, 99, 132"/>
                    : <></>}
                
            </Segment>   
            <Segment>
                {vrboClickTrend ? 
                    <GraphClicks platform={vrboClickTrend} src="Vrbo" color="53, 162, 235"/>
                    : <></>}
            </Segment>     
        </Grid.Column>                  
        </Grid.Row>       
        <Divider />
        </>
    )
}

export default BinderAssetMetrics;