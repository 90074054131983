import React from 'react';
import {Radio, Segment} from 'semantic-ui-react';
import ReactSlider from 'react-slider';

export default function DesignImageStyle({
    handleImageStyleChange,
    handleImageFilterChange,
    currentPaddingValue,
    currentRadiusValue,
    currentShadowValue,
    currentBlurValue,
    currentGrayScaleValue,   
    currentSepiaValue, 
}) {

    function handlePaddingChange(value) {
        handleImageStyleChange(value, currentRadiusValue, currentShadowValue, currentBlurValue)
    }

    function handleRadiusChange(value) {
        handleImageStyleChange(currentPaddingValue, value, currentShadowValue, currentBlurValue)
    }

    function handleShadowChange(value) {
        handleImageStyleChange(currentPaddingValue, currentRadiusValue, value, currentBlurValue)
    }

    function handleImageBlurChange(value) {
        // console.log(`setting blur to ${value}`);
        handleImageStyleChange(currentPaddingValue, currentRadiusValue, currentShadowValue, value)
    }    

    function handleImageFilters(value) {                                 
        if (value === "sepia") {
            if (currentSepiaValue === 0) {
                handleImageFilterChange("sepia", 100)
            } else {
                handleImageFilterChange("sepia", 0)
            }
        }
        else if (value === "grayscale") {
            if (currentGrayScaleValue === 0) {
                handleImageFilterChange("grayscale", 100)
            } else {
                handleImageFilterChange("grayscale", 0)
            }
        }               
    }

    return (
        <Segment.Group style={{display:"table-caption"}}>             
            <Segment>      
                <label className="pl-8 mt-4">Padding</label>
                <ReactSlider                                
                    className="w-[18rem] mx-auto mt-2 mb-2 h-6 bg-gray-400 opacity-70 rounded-lg max-w-md "
                    trackClassName="h-6 bg-gray-200 text-black background-color: rgba(0,0,0,0.3) rounded-lg "
                    thumbClassName="w-6 h-6 bg-white border-2 border-gray-400 rounded-full shadow-md outline-none cursor-pointer]  "
                    defaultValue={0}
                    max={64}
                    min={0}
                    step={2}
                    label="Padding"
                    ariaLabel="Padding"
                    value={currentPaddingValue}
                    onChange={(value) => handlePaddingChange(value)}
                />
                <label className="pl-8">Radius</label>
                <ReactSlider                                
                    className="w-[18rem] mx-auto mt-2 mb-2 h-6 bg-gray-400 opacity-70 rounded-lg max-w-md "
                    trackClassName="h-6 bg-gray-200 text-black background-color: rgba(0,0,0,0.3) rounded-lg "
                    thumbClassName="w-6 h-6 bg-white border-2 border-gray-400 rounded-full shadow-md outline-none cursor-pointer]  "
                    defaultValue={4}
                    max={12}
                    min={0}
                    step={1}                            
                    ariaLabel="Radius"
                    value={currentRadiusValue}
                    onChange={(value) => handleRadiusChange(value)}
                />
                <label className="pl-8">Shadow</label>
                <ReactSlider                                
                    className="w-[18rem] mx-auto mt-2 mb-2 h-6 bg-gray-400 opacity-70 rounded-lg max-w-md "
                    trackClassName="h-6 bg-gray-200 text-black background-color: rgba(0,0,0,0.3) rounded-lg "
                    thumbClassName="w-6 h-6 bg-white border-2 border-gray-400 rounded-full shadow-md outline-none cursor-pointer]  "
                    defaultValue={14}
                    max={20}
                    min={0}
                    step={1}                            
                    ariaLabel="Shadow"
                    value={currentShadowValue}
                    onChange={(value) => handleShadowChange(value)}
                />
                <label className="pl-8"></label>
            </Segment>                  
            <Segment>                    
                <label className="pl-8 mt-4">Blur</label>
                <ReactSlider                                
                    className="w-[18rem] mx-auto mt-2 mb-2 h-6 bg-gray-400 opacity-70 rounded-lg max-w-md "
                    trackClassName="h-6 bg-gray-200 text-black background-color: rgba(0,0,0,0.3) rounded-lg "
                    thumbClassName="w-6 h-6 bg-white border-2 border-gray-400 rounded-full shadow-md outline-none cursor-pointer]  "
                    defaultValue={0}
                    max={16}
                    min={0}
                    step={1}                            
                    ariaLabel="Blur"
                    value={currentBlurValue}
                    onChange={(value) => handleImageBlurChange(value)}
                /> 
                <Radio toggle checked={currentGrayScaleValue>0} label="Grayscale" className="p-4 pl-6" onClick={()=>handleImageFilters("grayscale")}/> 
                <Radio toggle checked={currentSepiaValue>0} label="Sepia" className="p-4 pl-6" onClick={()=>handleImageFilters("sepia")}/> 
            </Segment>  
        </Segment.Group>                                                                                                         
    )
}