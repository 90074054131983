import React from "react";

// import { Image } from "semantic-ui-react";
import {Carousel} from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

// let elements = [
//     {
//       render: () => {
//         return (
//           <Image src="/images/example1.png" />
//         );
//       }
//     },
//     {
//       render: () => {
//         return <Image src="/images/example2.png" />;
//       }
//     },
//     {
//       render: () => {
//         return (
//           <Image src="/images/example3.png" />
//         );
//       }
//     }
//   ];

function ImageSlider(){
    return (
    <Carousel showArrows={true} showThumbs={false} interval={4000} autoPlay={true} infiniteLoop={true}>                
                <div>
                    <img alt="OwnerGuest image with guest review" src="/images/landing_4.webp" />                    
                </div> 
                <div>
                    <img alt="OwnerGuest image with guest review" src="/images/OwnerGuestShareable_land1.webp" />                    
                </div>
                <div>
                    <img alt="OwnerGuest image with guest review" src="/images/rustic_land3.webp" />                    
                </div>
                              
            </Carousel>
    )
    }

    export default ImageSlider;