import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../context/auth';
import {getGetHeaders} from "../utils/headers";
import { Message, Container, Header, Card  } from 'semantic-ui-react'
import BinderCard from './BinderCard';
// import ReviewsLandingPage from './ReviewsLandingPage';
import GenericListingSelector from "../components/GenericListingSelector";
import MessageOnBoarding from "../common/OnBoardingMessage";


function Binder({updatePage}) {
    const context = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [binder, setBinder] = useState([])
    const [templateList, setTemplateList] = useState([])
    const [landingPageList, setLandingPageList] = useState([])
    const [binderListChanged, setBinderListChanged] = useState(false);    
    const [onTimeOut, setOnTimeOut] = useState(true);
    const [filteredBinder, setFilteredBinder] = useState([]);

    useEffect(() => {
        async function fetchData() {
          let headers = getGetHeaders();          
          const response = await fetch(`${process.env.REACT_APP_BE_BASE_URL}/api/binders`,
            {headers})
          if (response.status === 401) {context.logout({})}
          const data = await response.json();  
          setLoading(false);                                
          setBinder(data);  
          setFilteredBinder(data);                                     
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps               
    }, [binderListChanged]);

    useEffect(() => {
      function unpdateBinderState() {
          setLoading(false);
          setBinderListChanged(false);
      }
      unpdateBinderState();
    }, [binder])

    useEffect(() => {
      async function fetchTemplates() {
        let headers = getGetHeaders();
        const response = await fetch(`${process.env.REACT_APP_BE_BASE_URL}/api/templates`,
          {headers})
        if (response.status === 401) {context.logout({})}
        const data = await response.json();                  
        setTemplateList(data)  
      }
      fetchTemplates();  
       // eslint-disable-next-line react-hooks/exhaustive-deps      
    }, [])

    useEffect(() => {
      async function fetchLandingPages() {
        let headers = getGetHeaders();
        const response = await fetch(`${process.env.REACT_APP_BE_BASE_URL}/api/landingpages`,
          {headers})
        if (response.status === 401) {context.logout({})}        
        const data = await response.json();        
        setLandingPageList(data);                     
      }
      fetchLandingPages();
       // eslint-disable-next-line react-hooks/exhaustive-deps      
    }, []);

    
    const handleBinderDeleted = (binderDeleted) => {
        if (binderDeleted) {
            setBinderListChanged(true);
        }
    }
    
    const handleTimeout = () => {         
        setOnTimeOut(false);        
        setTimeout(() => {            
            setOnTimeOut(true);
        }, 2000);                        
    }


    // const handleChangePage = () => {
    //   updatePage(<ReviewsLandingPage />);
    // }

    const handleListingSelectionCallback = (listingId) => {  
      // filter out id's not matching listingId in assetmetrics        
      const filtered = binder.filter((item) => {            
          return item.user_property_id === listingId;
      })   
      // const result = unFilteredMetrics.filter((elem) => filtered.find(({ id }) => parseInt(elem[0]) === id));
      setFilteredBinder(filtered);
  }

  return (
    <>
    
    {/* {loading &&  (<div className="ui active centered inline loader"></div>)} */}
    
      <>
        <Header as="h2" content="Your portfolio of designs"/>  
        {filteredBinder.length === 0 ? (
            <MessageOnBoarding header="No designs found" message="Designs start by selecting a review. Navigate to Reviews to get started" />
            // <Header.Subheader>No designs found. Designs start by selecting a review. Navigate to Reviews to get started.</Header.Subheader>
          ) : (
        <Header.Subheader>Share, download or view performance metrics for each design</Header.Subheader>)}        
        {loading ? (<div className='text-2xl p-4'>
          <div className="ui active centered inline loader"></div>
          {/* No binders found, to create one, navigate to <button as="link" style={{fontStyle:"underline", color:"blue"}} onClick={handleChangePage}>reviews</button> and click the 'Create Shareable' button. */}
        </div>) : null}
        
        <Container style={{marginTop:"3%"}}>  
                <Message hidden={onTimeOut} compact positive floating  >Copied!</Message>                                  
                <div style={{width:"fit-content", marginLeft:"auto", marginRight:"auto", padding:"2%"}}>
                  <GenericListingSelector handleListingSelectionCallback={handleListingSelectionCallback} /> 
                </div>
                <Card.Group centered >                                
                    {filteredBinder.length > 0 && filteredBinder.map((masterPiece) => (
                        <BinderCard 
                          key={masterPiece.id}                           
                          handleTimeout={handleTimeout}
                          handleCallback={handleBinderDeleted}                           
                          id={masterPiece.id} 
                          image={masterPiece.image} 
                          bookUrl={masterPiece?.bookUrl} 
                          masterPiece={masterPiece}
                          templateList={templateList.filter(template => template.for_property?.id === masterPiece.user_property_id)}
                          landingPageList={landingPageList.filter(landingPage => landingPage.for_property?.id === masterPiece.user_property_id)}/>
                    ))}                
                </Card.Group>                 
                  {/* {!loading && binder.length === 0 && (<div className='text-2xl p-4'>
                    No binders found, to create one, navigate to 'Reviews' and click the 'Create Shareable' button.
                  </div>)} */}
        </Container>
        </>
      {/* )} */}
    </>
  )
}

export default Binder;