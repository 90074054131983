import React from 'react';
import { useNavigate } from "react-router-dom";

function Features() {
  let navigate = useNavigate();

  const handleClick = () => {
      navigate("/register");
  }
  return (
    <div className="bg-white p-6 rounded-lg shadow-lg">
      <h1 className="text-2xl font-bold text-gray-800 mb-4">Vacation Rental Image Creation Tool</h1>
      <p className="text-gray-700 mb-4">Welcome to our vacation rental image creation tool! Our web app is designed specifically for vacation rental owners looking to create beautiful, shareable images that showcase their property and the positive experiences of their guests. By combining images and guest reviews, our tool creates a visually appealing and persuasive marketing asset.</p>
      <h2 className="text-xl font-bold text-gray-800 mb-2">AI-Powered Tweets and Hashtags</h2>
      <p className="text-gray-700 mb-4">Our AI-powered feature allows you to generate tweets and hashtags to further promote your property on social media platforms such as Twitter, Instagram, and Facebook. You can even share your images directly via email.</p>
      <h2 className="text-xl font-bold text-gray-800 mb-2">Dashboard Metrics</h2>
      <p className="text-gray-700 mb-4">To help you track the success of your marketing efforts, we offer a dashboard with metrics that allow you to see how well your images are performing. With this information, you can fine-tune your marketing strategy and continue to attract new guests to your vacation rental.</p>
      <h2 className="text-xl font-bold text-gray-800 mb-2">Get More Bookings</h2>
      <p className="text-gray-700 mb-4">Try our vacation rental image creation tool today and start promoting your property in a visually appealing and effective way.</p>
      <button onClick={handleClick} className="rounded-lg shadow-lg bg-blue-400 p-2 align-top m-2 pl-6 pr-6 text-blue-50">
        Try It Now
      </button>
    </div>
  );
}

export default Features;
