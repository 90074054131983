import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart,
  CategoryScale,
  registerables,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
Chart.register(...registerables, CategoryScale, Title, Tooltip, Legend);

export default function GraphListingHits({ properties, data }) {
  const [availableMetrics, setAvailableMetrics] = useState(null);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Combined traffic for all assets for the last 30 days',
      },
    },
  };

  function setChartData(metrics) {    
    const colors = [
      "#f46636cc",
      "#03a9f4",
      "#4caf50e3",
      "Purple",
      "Orange",
      "Yellow",
      "Black",
      "Pink",
      "Brown",
      "Grey",
      "Cyan",
      "Magenta",
      "Teal",
      "Navy",
      "Maroon",
      "Olive",
      "Lime",
      "Aqua",
      "Fuchsia",
      "Silver",
      "Gray",
      "White",
    ];
    // debugger;
    const datasets = properties?.map((property, index) => ({
      label: property.nickname,
      data: metrics[index]?.data?.map((metric) => ({
        x: metric.date,
        y: metric.count,
      })),
      borderColor: colors[index],
      fill: false,
      tension: 0.4      
    }));
    
    let data = {
      datasets: datasets,
    };
    setAvailableMetrics(data);
  }

  useEffect(() => {    
    // data=null;
    // debugger;
    data && data?.length > 0 ? (setChartData(data)) : (setAvailableMetrics(null));

  }, [data]);

  return availableMetrics ? (
    <Line data={availableMetrics} options={options} />
  ) : (
    <> </>
  );
}
