import React from "react";

export default function DesignGradientStyle({handleGradientColorChange, currentGradientColor}){
    const GRADIENTSELECTORSTYLE = "text-center font-bold p-2 min-w-full	 rounded-lg text-white shadow-lg ";
    
    const GRADIENTCOLORS = {
        "Cyan Blue": "bg-gradient-to-r from-cyan-500 to-blue-500",
        // "Peachy Pink": "bg-gradient-to-r from-[#f26aae] to-[#fa9e9e]",
        "Purple Blue": "bg-gradient-to-r from-[#7f00ff] to-[#e100ff]",
        "Purple Pink": "bg-gradient-to-r from-[#fbb040] to-[#f9ed32]",
        "Green Mint": "bg-gradient-to-r from-[#00a1ff] to-[#00ff8f]",
        "Peach Yellow": "bg-gradient-to-r from-[#ee2a7b] to-[#ff7db8]",
        "Blue Blue" : "bg-gradient-to-r from-[#0085ff] to-[#0085ff4f]",
        "Pink Aqua": "bg-gradient-to-r from-[#ff00d4] to-[#e40c0cb3]",
        "Pink Yellow": "bg-gradient-to-r from-[#ef4136] to-[#fbb040]",
        "Deep Pint Mint": "bg-gradient-to-r from-[#2d388a] to-[#00aeef]",
        "Yellow Orange": "bg-gradient-to-r from-[#E3FF7A] to-[#E27C39]",
        "Deep Purple": "bg-gradient-to-r from-[#4B086D] to-[#ACC0FE]",
        "Dark Green": "bg-gradient-to-r from-[#087549] to-[#1fe42aa6]",
        "Blue Green": "bg-gradient-to-r from-[#00A1FF] to-[#39AE62]",
        "Blue Purple": "bg-gradient-to-r from-[#00A1FF] to-[#7F00FF]",
        "Dark": "bg-gradient-to-r from-[#000000] to-[#0a4071e3]",
        "Aqua Yellow": "bg-gradient-to-r from-[#00FF8F] to-[#C2F6C4]",
        "Aqua Green": "bg-gradient-to-r from-[#00FF8F] to-[#39AE62]",
        "Blue Light": "bg-gradient-to-r from-[#00A1FF] to-[#ffffff]",
        "Light": "bg-gradient-to-r from-[#ffffff] to-[#ffffff]",
        "Grey": "bg-gradient-to-r from-[#808080] to-[#808080]",
        "Grey Light": "bg-gradient-to-r from-[#808080] to-[#ffffff]",
        "camo green": "bg-gradient-to-r from-[#4b5d67] to-[#616e7d]",
        "camo green light": "bg-gradient-to-r from-[#9499B9] to-[#4b5d67]",
        // "camo light from center": "bg-gradient-to-center from-[#4b5d67] to-[#ffffff]",
        // "Green Aqua": "bg-gradient-to-r from-[#39AE62] to-[#45EFCA]",
        // "Blue Aqua": "bg-gradient-to-r from-[#00A1FF] to-[#00FF8F]",
        // "Blue Pink": "bg-gradient-to-r from-[#00A1FF] to-[#F26AAE]",
        // "Blue Yellow": "bg-gradient-to-r from-[#00A1FF] to-[#FBB040]",
        
        // "Blue Deep Purple": "bg-gradient-to-r from-[#00A1FF] to-[#4B086D]",
        // "Blue Dark Green": "bg-gradient-to-r from-[#00A1FF] to-[#087549]",
        // "Blue Dark": "bg-gradient-to-r from-[#00A1FF] to-[#000000]",
        
        // "Aqua Pink": "bg-gradient-to-r from-[#00FF8F] to-[#F26AAE]",
        
        // "Aqua Purple": "bg-gradient-to-r from-[#00FF8F] to-[#7F00FF]",
        // "Aqua Deep Purple": "bg-gradient-to-r from-[#00FF8F] to-[#4B086D]",

    }

    const getColorSelectorClass = (color) => {
        return GRADIENTSELECTORSTYLE + GRADIENTCOLORS[color];
    }

    // const handleGradientColorChange = (value) => {
    //     setGradient(GRADIENTCOLORS[value])        
    // }

    return (
        <div style={{position: "sticky", bottom: 0, zIndex: "999", backgroundColor:"white"}} className="p-4 inline float-left text-left rounded-lg shadow-lg">                                                                                             
            {Object.keys(GRADIENTCOLORS).map((color, index) => (                        
                <div key={index} className=" p-2 inline-block outline-1 outline">                                   
                    <div className={getColorSelectorClass(color)}   onClick={() => handleGradientColorChange(GRADIENTCOLORS[color])}>                                                            
                    </div>
                </div>
            ))}
        </div>     
    )
}
