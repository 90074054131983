import React, {useContext, useState} from "react";
import { AuthContext } from "../context/auth";
import { Icon } from 'semantic-ui-react';
import {getPostHeaders} from "../utils/headers";


function RefreshReviewsButton({doorId, handleRefreshCallback}) {    
    const context = useContext(AuthContext);       
               
    async function queryAirbnb() {        
        let headers = getPostHeaders({doorId, "external":"true"}, "POST");
        const resp = await fetch(`${process.env.REACT_APP_BE_BASE_URL}/api/getairbnbreviews`, 
        {...headers})
        if (resp.status === 401) {
            context.logout({});
        }
        // const data = await resp.json()        
    }        
    
    async function queryVrbo() {        
        let headers = getPostHeaders({doorId, "external":"true"}, "POST");
        const resp = await fetch(`${process.env.REACT_APP_BE_BASE_URL}/api/getvrboreviews`, 
        {...headers})
        if (resp.status === 401) {
            context.logout({});
        }
        const data = await resp.json();            
        
    }        

    async function updateLastReviewRefreshDate() {
        let headers = getPostHeaders({doorId}, "POST");
        const resp = await fetch(`${process.env.REACT_APP_BE_BASE_URL}/api/reviewactivity`, 
        {...headers})
        if (resp.status === 401) {
            context.logout({});
        }
        const data = await resp.json()                       
    }
    
    function handleFetchReviews() { 
        queryAirbnb();
        queryVrbo();                  
        updateLastReviewRefreshDate()     
        handleRefreshCallback();
    }

    return (
        <>
            <Icon
            data-cy="refreshButton"
            className="refresh-button"
            onClick={handleFetchReviews}
            color="blue"
            link={true}
            name="refresh" style={{ margin: 0 }}
            />            
        </>
    );
    
}
export default RefreshReviewsButton;