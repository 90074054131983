import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../context/auth";
import {getGetHeaders} from "../utils/headers";
import { Button, Radio } from "semantic-ui-react";

function GenericListingSelector({handleListingSelectionCallback}) {
    const context = useContext(AuthContext);
    const [properties, setProperties] = useState([]);    
    const [selectedListing, setSelectedListing] = useState();
    
    useEffect(() => {        
        async function getProperties() {
            let headers = getGetHeaders();
            const resp = await fetch(`${process.env.REACT_APP_BE_BASE_URL}/api/get_properties/`, {
                headers      
            })
            if (resp.status === 401) {
                context.logout({})
            }
            const data = await resp.json(); 
            
            setProperties(data);
            if (data.length > 0) {
                setSelectedListing(data[0].id);
                handleListingSelectionCallback(data[0].id);
            }                                              
        }           
        getProperties();                    
    }, []);


    function handleSelectListing(listingId) {                
        setSelectedListing(listingId);           
        handleListingSelectionCallback(listingId);     
    }


    return (
        <>        
        {properties && properties.map((property) => (
            <Radio
                key={property.id}
                onClick={() => handleSelectListing(property.id)}  
                label={property.nickname}              
                value={property.id}
                checked={selectedListing === property.id} 
                className="mr-2 p-2 text-sm text-gray-600 bg-gray-100 mb-2"
            />
        ))}                                                   
        </>
    );
    
}
export default GenericListingSelector;