import React, {useEffect, useState, useContext} from "react";
import {Container, Label, Message, Card, Segment, Header, Icon, Button} from "semantic-ui-react";
import { AuthContext } from '../context/auth';
import { getGetHeaders } from "../utils/headers";
import LandingPageBuilder from "./LandingPageBuilder";
import DeleteButton from "../components/DeleteButton";
import PropertyListDropdown from "../components/PropertyListDropdown";
import { getPostHeaders } from "../utils/headers";

export default function ConfigureLandingPages() {
    const context = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [cursor, setCursor] = useState("pointer");
    const [values, setValues] = useState({})
    const [landingPageList, setLandingPageList] = useState([]);
    const [landingPageListUpdated, setLandingPageListUpdated] = useState(false);
    const [showLandingPageBuilder, setShowLandingPageBuilder] = useState(false);
    const [propId, setPropId] = useState(null);
    const [buttonDisabled, setButtonDisabled] = useState(true);

    useEffect(() => {
        async function fetchData() {
            let headers = getGetHeaders();
            const response = await fetch(`${process.env.REACT_APP_BE_BASE_URL}/api/landingpages`,
                {headers})
            if (response.status === 401) {context.logout({})}
            const data = await response.json();
            if(data.length != 0) {                
                setLandingPageList(data);
                setLandingPageListUpdated(false);
            }
            setLoading(false);
        }
        fetchData();
        
    }, [landingPageListUpdated]);

    const handleCallback = (created) => {
        if (created) {
            setLandingPageListUpdated(true);
            setShowLandingPageBuilder(false);
            setLandingPageListUpdated(true);
        } else {            
            setShowLandingPageBuilder(false);
        }
    }

    const handleShowLandingPageBuilder = () => {
        setShowLandingPageBuilder(!showLandingPageBuilder);
    }

    function copyTemplate(template) {
        navigator.clipboard.writeText(template);
    }

    const handleDeleteCallback = (deleted) => {        
        if (deleted) {
            setLandingPageListUpdated(true);
        }        
    }

    async function generateFromAI() {
        setCursor("wait");
        const headers = getPostHeaders({values}, "POST");
        const response = await fetch(`${process.env.REACT_APP_BE_BASE_URL}/api/generatelp/`,
            {...headers});
        if (response.status === 200) {
            handleCallback(true);
            setCursor("pointer");
        } else if (response.status === 401) {
            context.logout({})
        } else if (response.status === 500) {
            setErrors(true);
            setErrorMessage("Server error. Please try again later.");
            setCursor("pointer");
        }
    }

    function handlePLCallback(propertyId) {      
        if (propertyId !== undefined) {
            setPropId(propertyId);  
            setValues({...values, propertyId: propertyId, request_type: "landing_page"})
            setButtonDisabled(false);
        } else {
            setButtonDisabled(true);            
        }
    }
    return (
        <>            
            <Container>
            {loading ? (
                <div className="ui active centered inline loader"></div>
            ) : (
            
            <Segment>                
                <Header as='h2'>Use landing pages to make an impression </Header>
                {landingPageList && landingPageList.length > 0 ? (
                    <Header.Subheader>Not feeling creative? Try generating a landing page with the help of AI </Header.Subheader>
                ) : (
                    <Header.Subheader>
                        <div className='text-2xl p-4'>
                            You have not created any landing pages yet. 
                        </div>
                    </Header.Subheader>
                )}
                <div className="ui divider"></div>
                <Button.Group toggle >
                    <Button 
                        basic
                        disabled={buttonDisabled}
                        toggle                        
                        active={showLandingPageBuilder}
                        color="blue"
                        onClick={handleShowLandingPageBuilder}                         
                    >
                        Create New
                    </Button>
                    <Button 
                        style={{ cursor: cursor, marginRight:"5px", marginLeft:"2px" }}
                        basic       
                        disabled={buttonDisabled}                  
                        color="green"
                        onClick={generateFromAI}                         
                    >
                        Generate from AI
                    </Button> 
                </Button.Group>
                <PropertyListDropdown handleCallback={handlePLCallback}/> 
                {errors ? (
                    <Message negative>
                        <Message.Header>
                            Error generating from AI
                        </Message.Header>
                        <p>{errorMessage}</p>
                    </Message>                            
                ):(<></>)}
                               
                {showLandingPageBuilder &&                     
                    <LandingPageBuilder handleCallback={handleCallback} propertyId={propId}/>                                                            
                }
                
                <div className="ui divider"></div>
                {landingPageList && landingPageList.length > 0 ? (
                  <>                    
                    <Card.Group>
                    {landingPageList.map((landingPage, index) => (
                            <Card key={index} fluid>
                                <Card.Content>
                                    <Label as='a' color='blue' ribbon style={{marginBottom:"5%"}}>
                                        {landingPage?.for_property?.nickname}
                                    </Label>
                                    <Card.Header>{landingPage?.title}<div title="Click to copy all" className="cursor-pointer float-right border-none"><Icon onClick={()=>copyTemplate(landingPage.title + " " + landingPage.description)} name="copy outline" style={{outline:"none"}}/></div></Card.Header>                                    
                                </Card.Content>
                                <Card.Content>
                                    <Card.Header as='h4'>Description<div title="Click to copy description" className="cursor-pointer float-right border-none"><Icon onClick={()=>copyTemplate(landingPage.description)} name="copy outline" size="small" style={{outline:"none"}}/></div></Card.Header>                                    
                                    <Card.Description>{landingPage?.description}</Card.Description>      
                                </Card.Content>
                                
                                <Card.Content extra>
                                    <DeleteButton handleCallback={handleDeleteCallback} typeOfObject="landingPage" id={landingPage.id} />
                                </Card.Content>
                        </Card>
                    ))}
                    </Card.Group>                   
                
                  </>  
                  ) : (<></>)}
                
            </Segment>
            )}

        </Container>
        </>
    )
}



       


        
