import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart, CategoryScale, registerables } from 'chart.js';
Chart.register(...registerables, CategoryScale);


const GraphReferrers = ({metrics}) => { 
    // debugger; 
    var cleanLabels = metrics.map(metric => {
            try{
                return new URL(metric.referrer).hostname
            } catch (e) {
                // console.log(`Error parsing referrer: ${metric.referrer}`);
                return "";
            }

        }
    );
    const state = {        
        labels: cleanLabels,
        datasets: [
          {
            
            backgroundColor: [
              '#2EC7F6',
              '#00DE7E',
              '#DE4A00',
              '#DAF755',
              '#E739AA'
            ],
            hoverBackgroundColor: [
            '#501800',
            '#4B5000',
            '#175000',
            '#003350',
            '#35014F'
            ],
            data: metrics.map(metric => metric.count),
          }
        ]
      }

     
    

    return (
        <Pie
        data={state}
        options={{
          title:{
            display:true,
            text:'Top Referrers',
            fontSize:20
          },
          legend:{
            display:true,
            position:'right'
          }
        }}
      />
    )
}

export default GraphReferrers;
