import React, { useState, useEffect } from 'react'
import { Disclosure, Menu } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import PropertyList from './PropertyList'
import ReviewsLandingPage from './ReviewsLandingPage'
import LogoutButton from '../components/LoginOut/LogoutButton'
import TemplateList from './TemplateList'
import Binder from './Binder'
import MetricsDashboard from './MetricsDashboard'
import ConfigureLandingPages from './ConfigureLandingPages'
import { Dropdown, StepGroup } from 'semantic-ui-react'


const navigation = [
  { name: 'Metrics', href: '#', component: <MetricsDashboard />, current: true, key:"metricsdashboard" },  
  { name: 'Reviews', href: '#', component: <ReviewsLandingPage />, current: false, key:"reviews" },  
  { name: 'Listings', href: '#', component: <PropertyList />, current: false, key:"listings" },  
   // { name: 'Walls', href: '#', component: <PropertyList />, current: false, key:"walls" },
]

const binderNavigation = [
  { name: 'Designs', href: '#', component: <Binder />, current: false, key:"designs" },
  { name: 'Posts & Tweets', href: '#', component: <TemplateList />, current: false, key:"templateList" },
  { name: 'Landing Pages', href: '#', component: <ConfigureLandingPages />, current: false, key:"landingpages" },
  
]


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Base({title, updatePage}) {
    const [authenticated, setAuthenticated] = useState(false);
    const [activeItem, setActiveItem] = useState('Metrics Dashboard')
    const [page, setPage] = useState(<MetricsDashboard />)    
    const [userName, setUserName] = useState('')
    const [altTitle, setAltTitle] = useState('')
    
    

    const handleItemClick = (item, event) => {     
        setAltTitle('');

        navigation[0].current = !navigation[0].current;
        if (item.name === 'Listings') {            
            navigation[1].current = true;
            setActiveItem('Listings')
            setPage(<PropertyList />)
        } else if (item.name === 'Reviews') {            
            navigation[2].current = true;
            setActiveItem('Reviews')
            setPage(<ReviewsLandingPage updatePage={setPage}/>)        
        } else if (item.name === 'Posts & Tweets') {
          binderNavigation[1].current = true;
            setActiveItem('Posts & Tweets')
            setPage(<TemplateList />) 
        } else if (item.name === 'Landing Pages') {
          binderNavigation[2].current = true;
            setActiveItem('Landing Pages')
            setPage(<ConfigureLandingPages />)                  
        } else if (item.name === 'Designs') {          
            binderNavigation[0].current = true;
            setActiveItem('Designs')
            setPage(<Binder updatePage={setPage}/>)                   
        } else {            
            setActiveItem('Metrics Dashboard')
            setPage(<MetricsDashboard updatePage={setPage}/>)
        }             
    } 

    useEffect(() => {
      async function isAuthenticated() {            
          var token = localStorage.getItem("token"); 
          
          if (token) {
              setAuthenticated(true);
              setUserName(localStorage.getItem("user"));           
          } 
      }
      updatePage ? setPage(updatePage) : setPage(<MetricsDashboard key="md"/>);
      isAuthenticated();
    }, []);

    useEffect(() => {   
      setAltTitle(title);
      // if (title) {
      //   setActiveItem(title)
      // }      
    }, [title]);

    // debugger;

  return (
    <>      
      <div className="min-h-full">
        <Disclosure as="nav" className="bg-gray-800" style={{position:'sticky', top:'0px', zIndex:'999'}}>
          {({ open }) => (
            <>
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="flex h-16 items-center justify-between">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <img
                        className="h-8"
                        src="/images/ogtext_white.png"
                        alt="OwnerGuest"
                      />
                    </div>
                    <div className="hidden md:block">
                      <div className="ml-10 flex items-baseline space-x-4">
                      
                      <Menu>                        
                      {navigation.map((item) => (                                                
                        <Menu.Item key={item.key} style={{cursor:"pointer"}}>
                            {({ active }) => (
                                <button as='link'                                
                                className={classNames(
                                    item.current && activeItem === item.name
                                ? 'bg-gray-900 text-white font-weight-bold text-lg'
                                : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                              'px-3 py-2 rounded-md text-lg font-medium'
                                  )}
                                  aria-current={item.current ? 'page' : undefined}
                                  onClick={() => handleItemClick(item)}
                                >
                                    {item.name}
                                </button>
                            )}
                        </Menu.Item>                                               
                      
                         ))}
                                                                         
                            <Dropdown item text='Assets' style={{color:"rgb(209 213 219)", fontWeight:"bold", fontSize: "1.125rem",lineHeight: "1.75rem"}}>
                              <Dropdown.Menu style={{cursor:"pointer"}}>
                                {binderNavigation.map((item) => (
                                  <Dropdown.Item
                                    tabIndex={item.key}
                                    key={item.name}
                                    as="a"
                                    href={item.href}
                                    style={{color:"black"}}
                                    aria-current={item.current ? 'page' : undefined}
                                    onClick={() => handleItemClick(item)}
                                  >
                                  {item.name}
                                  </Dropdown.Item>
                                ))}
                              </Dropdown.Menu>
                            </Dropdown>
                          
                        </Menu>
                      </div>
                    </div>
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-4 flex items-center md:ml-6">                      
                      <Menu as="div" className="relative ml-3">
                        <div>
                          <Menu.Button className="text-white flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                            {authenticated ? (
                                // <>
                                  <LogoutButton tabIndex="3" name='logout'/>                                    
                                // </>
                            ):(<></>)}
                            
                          </Menu.Button>
                          <a style={{color:"white"}} href="https://billing.stripe.com/p/login/dR69BY3C89cf0aAdQQ">Manage Subscription</a>                                         
                          {/* <a style={{color:"white"}} href="https://billing.stripe.com/p/login/test_7sI03F21agQCelidQQ">Manage Subscription</a>     */}

                        </div>                        
                      </Menu>
                    </div>
                  </div>
                  <div className="-mr-2 flex md:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button key="disc1" className="inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>

              <Disclosure.Panel key="discpanel1" className="md:hidden">
                <div className="space-y-1 px-2 pt-2 pb-3 sm:px-3">
                  {navigation.map((item, index) => (
                    <React.Fragment key={index}>
                    <Disclosure.Button
                      tabIndex={item.key}
                      key={item.name}
                      as="a"
                      href={item.href}
                      className={classNames(
                        item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                        'block px-3 py-2 rounded-md text-base font-medium'
                      )}
                      aria-current={item.current ? 'page' : undefined}
                      onClick={() => handleItemClick(item)}
                    >
                      {item.name} 
                    </Disclosure.Button>                    
                   </React.Fragment>
                  ))}
                </div>
                <div className="border-t border-gray-700 pt-4 pb-3">
                  <div className="flex items-center px-5">                    
                  </div>
                  <div key="disc2" className="mt-3 space-y-1 px-2">
                    {binderNavigation.map((item) => (
                      <Disclosure.Button
                        tabIndex={item.key}
                        key={item.name}
                        as="a"
                        href={item.href}
                        className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                        aria-current={item.current ? 'page' : undefined}
                        onClick={() => handleItemClick(item)}
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))}
                  </div>
                </div>
                  
                  <div className="space-y-1 px-2 pt-2 pb-3 sm:px-3">                
                    <Menu>
                      <Menu.Button 
                          className="text-white flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                        >                    
                        {authenticated ? (
                            <>
                              <LogoutButton tabIndex="5" size="md" name='logout'/>  
                              <a tabIndex="6" href="https://billing.stripe.com/p/login/dR69BY3C89cf0aAdQQ">Manage Subscription</a>                     
                            </>
                        ):(<></>)}                    
                      </Menu.Button>
                    </Menu>                 
                  </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        <header className="bg-white shadow bg-gradient-to-r from-cyan-500 to-blue-500">
          <div className="mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:px-8 h-20">
            <span className="float-left text-3xl font-bold tracking-tight text-gray-900 ">{altTitle ? altTitle : activeItem}</span>
            <span className="float-right text-white align-middle">{userName}</span>
          </div>
        </header>
        <main >
          <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
            {/* Replace with your content */}            
            <div className="px-4 py-6 sm:px-0">
                {page}
            </div>
            {/* /End replace */}
          </div>
        </main>
      </div>
    </>
  )
}
