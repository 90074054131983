import { useEffect, useState } from 'react';
import { getPostHeaders, getGetHeaders } from  "../utils/headers";


export const useOnPageLeave = (binderId) => {
    const [bookingLinks, setBookingLinks] = useState([])
    const [propertyNickname, setPropertyNickname] = useState()
    const [propertyImage, setPropertyImage] = useState()    
    const [landingPageText, setLandingPageText] = useState() 
    const [landingPageTitle, setLandingPageTitle] = useState()     
    const [origIp, setOrigIp] = useState()
    const [origCity, setOrigCity] = useState()
    const [origState, setOrigState] = useState()
    const [origCountry, setOrigCountry] = useState()

    function broofa() {
        // console.log("I am in broofa")
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
            return v.toString(16);
        });
    }
    
    // console.log(broofa())

    var _og_id 
    var uuid;
    
    async function getUuid() {                
            _og_id = localStorage.getItem('_og_id');                                                       
        if (_og_id === null || _og_id === 'undefined') {
            uuid = broofa();   
            localStorage.setItem('_og_id', uuid);                                                
        }  
        setTimeout(function() {
            _og_id = localStorage.getItem('_og_id');
        }, 50);    
        var data = uuid;
        return data;
    }
    
    useEffect(() => {
        async function fetchData() {                                          
            await Promise.all([getPropertyBookingData(), getLandingPageData(), fetchIPData(), getUuid()]).then((data) => {                
                updateMetricsWithThisData(`enter`, data);                
            })            
        }       
        fetchData();   
         // eslint-disable-next-line react-hooks/exhaustive-deps                   
    }, [binderId])

    // get landing page data for binderId
    async function getLandingPageData() {
        let headers = getGetHeaders();        
        const response = await fetch(`${process.env.REACT_APP_BE_BASE_URL}/api/binderlanding/${binderId}`,
            {headers})        
        const data = await response.json();  
        
        setLandingPageText(data.description);
        setLandingPageTitle(data.title)
        return data;                             
    }

    async function getPropertyBookingData() {
        let headers = getGetHeaders();
        const response = await fetch(`${process.env.REACT_APP_BE_BASE_URL}/api/bookinglinks/${binderId}`,
            {headers})        
        const data = await response.json();  
        setBookingLinks({'Airbnb':data.binder.airbnb_url, 'VRBO':data.binder.vrbo_url});
        setPropertyNickname(data.binder.nickname);  
        setPropertyImage(data.image);                 
        return data;                             
    }
    
    async function fetchIPData() {
        var data = {msg:{ip:"", city:"", state:"", country:""}};
        if (origIp === null || origIp === undefined) {        
            let headers = getGetHeaders();
            const response = await fetch(`/.netlify/functions/getIpMetrics/`,
                {headers})                    
            data = await response.json();             
            setOrigIp(data.msg["ip"]);
            setOrigCity(data.msg["city"]);
            setOrigState(data.msg["state"]);
            setOrigCountry(data.msg["country"]);            
            // updateMetricsWithThisData(action_taken, data.msg);
            return data;
        } else {
                return data;
        }      
    }
      
    async function updateMetricsWithThisData(action_taken, data)  {                   
        const referrer = document.referrer;        
        const headers = getPostHeaders({ 
            binder: binderId, 
            referrer:referrer, 
            action_taken: action_taken, 
            uuid: _og_id,
            origination_ip:data[2].msg.ip ? data[2].msg.ip : 'unknown',
            origination_city:data[2].msg.city ? data[2].msg.city : 'unknown',
            origination_state:data[2].msg.state ? data[2].msg.state : 'unknown',
            origination_country:data[2].msg.country ? data[2].msg.country : 'unknown',
        }, 'POST');
        if (binderId !== undefined) {
            const response = await fetch(`${process.env.REACT_APP_BE_BASE_URL}/api/bindermetrics/e/${binderId}/`, 
                { ...headers });
            const respData = await response.json();
        } else {
            console.log("binderId is undefined")
        }
    }
      
    async function updateMetrics(action_taken, og_id)  {          
        if (og_id !== undefined) {
            _og_id = og_id;
        } else {
            if (_og_id === null || _og_id === 'undefined') {         
                _og_id = localStorage.getItem('_og_id');  
            }       
        }
        const referrer = document.referrer;        
        const headers = getPostHeaders({ 
            binder: binderId, 
            referrer:referrer, 
            action_taken: action_taken, 
            uuid: _og_id,
            origination_ip:origIp ? origIp : 'Unknown',
            origination_city:origCity ? origCity : 'Unknown',
            origination_state:origState ? origState : 'Unknown',
            origination_country:origCountry ? origCountry : 'Unknown'
        }, 'POST');
        // && origIp
        if (binderId ) {            
            const response = await fetch(`${process.env.REACT_APP_BE_BASE_URL}/api/bindermetrics/e/${binderId}/`, 
                { ...headers });
            const data = await response.json();            
        } else {
            console.log("binderId or origIp is undefined")
        }
    }


    return { bookingLinks, propertyNickname, propertyImage, landingPageTitle, landingPageText, updateMetrics, fetchIPData};
};
      
