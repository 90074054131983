import { Message }  from 'semantic-ui-react'

export default function MessageOnBoarding({header, message}) {
    return (
        <Message floating warning>
            <Message.Header>{header}
            </Message.Header>
            <p>{message}</p>
        </Message>
    )
}