import React, {useContext, useState} from "react";
import { useForm } from "../util/hooks";
import {AuthContext} from "../context/auth";
import {getPostHeaders} from "../utils/headers";
import {Form} from "semantic-ui-react";


function LandingPageBuilder({handleCallback, propertyId}) {
    const context = useContext(AuthContext);
    const [errors, setErrors] = useState({});
    
    const { onChange, onSubmit, values } = useForm(createLandingPage, {        
        title: '',
        description: '',
        propertyId: propertyId,
    });
    
    
    async function createLandingPage() {                       
        let headers = getPostHeaders({values}, "POST");              
        const response = fetch(`${process.env.REACT_APP_BE_BASE_URL}/api/landingpages/`,
            {...headers})
        await response;
        if (response.status == 401) {
            context.logout({})
        } else {
            handleCallback(true);            
        }        
      }

    const handleClose = () => {
        handleCallback(false);
    }

    return (   
        <>
        
        <Form onSubmit={onSubmit} className="pt-8">                   
            <Form.Input 
                label="Title" 
                name="title"
                placeholder='Spacious home only 5 minutes from Disney' 
                type="text"
                value={values.title}     
                error={errors.title ? true : false}
                onChange={onChange}           
            />                        
            <Form.TextArea 
                label="Description"
                name="description"
                size="large"
                placeholder='Perfect space for a family of 5. Game room to keep the kids happy!' 
                type="text"
                value={values.description}   
                onChange={onChange}             
            />                  
            <button type='submit' className="rounded-lg shadow-lg bg-blue-400 p-2 align-top m-2 pl-6 pr-6 text-blue-50">Save</button>
            <button onClick={handleClose} className="rounded-lg shadow-lg bg-blue-400 p-2 align-top m-2 pl-6 pr-6 text-blue-50">Cancel</button>
        </Form>
        </>  
    )
}

export default LandingPageBuilder;
