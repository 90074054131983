import React, { useContext, useState } from 'react';
import { AuthContext } from '../context/auth';
import { getDeleteHeaders, getPostHeaders } from '../utils/headers';
import { Confirm, Icon } from 'semantic-ui-react';


function DeleteButton({id, typeOfObject, handleCallback}) {    
    const [confirmOpen, setConfirmOpen] = useState(false);    
    const context = useContext(AuthContext);

    async function deleteProperty() {                
        let headers = getPostHeaders({id});                
        const response = await fetch(`${process.env.REACT_APP_BE_BASE_URL}/api/delete_property`, 
            {...headers});
        if (response.status === 200) {
            handleCallback(true);
        } else if (response.status === 401) {
            context.logout({})
        }        
    }   

    async function deleteBinder() {                
        let headers = getDeleteHeaders({id});                 
        const response = await fetch(`${process.env.REACT_APP_BE_BASE_URL}/api/binders/${id}/delete`, 
            {...headers});
        if (response.status === 204) {
            handleCallback(true);
        } else if (response.status === 401) {
            context.logout({})
        }        
    }

    async function deleteTemplate() {        
        let headers = getDeleteHeaders({id});
        const response = await fetch(`${process.env.REACT_APP_BE_BASE_URL}/api/templates/${id}`,
            {...headers});
        if (response.status === 204) {
            handleCallback(true);
        } else if (response.status === 401) {
            context.logout({})
        }
    }

    async function deleteLandingPage() {        
        let headers = getDeleteHeaders({id});
        const response = await fetch(`${process.env.REACT_APP_BE_BASE_URL}/api/landingpages/${id}`,
            {...headers});
        if (response.status === 204) {
            handleCallback(true);
        } else if (response.status === 401) {
            context.logout({})
        }
    }


    const handleDelete = () => {
        if (typeOfObject === "property") {
            deleteProperty();
        } else if (typeOfObject === "binder") {
            deleteBinder();
        } else if (typeOfObject === "template") {
            deleteTemplate();
        } else if (typeOfObject === "landingPage") {
            deleteLandingPage();
        }
        setConfirmOpen(false)
    }

    return (
        <>
            <div style={{float:"right", paddingLeft:"2%"}}>
            <Icon  
            title="Delete" 
            data-cy="deleteButton"                       
            className="trash-button"
            link={true}           
            onClick={() => setConfirmOpen(true)} 
            color="red" 
            name="trash" style={{ margin: 0 }} 
            />
            <Confirm
            open={confirmOpen}
            onCancel={() => setConfirmOpen(false)}
            onConfirm={handleDelete}
            />
            </div>
        </>
    );
}
export default DeleteButton;