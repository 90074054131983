import React, {useState} from "react";
import ReactSlider from "react-slider";
import { Card, Radio, Segment }  from "semantic-ui-react";



export default function DesignTextStyle({
    handleTextStyleChange,
    selectedFontSize,
    currentOpacityValue,
    }) {
    
    
    function handleOpacityChange(value) {
        handleTextStyleChange(value, selectedFontSize)
    }

    function handleFontSizeChnage(value) {
        handleTextStyleChange(currentOpacityValue, value)
    }

    const handleSelectedFontSize = (value) => {        
        if (value === "xSmallFont") {handleFontSizeChnage("text-xs text-white")}
        // sm:text-lg md:text-xl lg:text-2xl xl:text-3xl 
        else if (value === "smallFont") {handleFontSizeChnage("text-base text-white")}
        else if (value === "medFont") {handleFontSizeChnage("text-lg text-white")}
        else if (value === "xlargeFont") {handleFontSizeChnage("text-4xl text-white")}
        else {handleFontSizeChnage("text-2xl text-white")}        
    }

    
    return (
        <div>
            <Segment.Group style={{display:"table-caption"}}>             
            <Segment>
                <label className="pl-8 font-semibold">Text Size</label>
                
                <Radio
                    className="w-[18rem] mx-auto mt-2 mb-2 h-6 opacity-70 rounded-lg max-w-md "
                    label='xSmall'
                    name='radioGroup'
                    value='xSmallFont'
                    checked={selectedFontSize === 'text-xs text-white'}
                    onChange={() => handleSelectedFontSize('xSmallFont')}
                />  
                <Radio
                    className="w-[18rem] mx-auto mt-2 mb-2 h-6 opacity-70 rounded-lg max-w-md "
                    label='Small'
                    name='radioGroup'
                    value='smallFont'
                    //  sm:text-lg md:text-xl lg:text-2xl xl:text-3xl 
                    checked={selectedFontSize === 'text-base text-white'}
                    onChange={() => handleSelectedFontSize('smallFont')}
                />  
                <Radio
                    className="w-[18rem] mx-auto mt-2 mb-2 h-6 opacity-70 rounded-lg max-w-md "
                    label='Medium'
                    name='radioGroup'
                    value='medFont'
                    checked={selectedFontSize === 'text-lg text-white'}
                    onChange={() => handleSelectedFontSize('medFont')}
                />  
                <Radio
                    className="w-[18rem] mx-auto mt-2 mb-4 h-6 opacity-70 rounded-lg max-w-md "
                    label='Large'
                    name='radioGroup'
                    value='largeFont'
                    checked={selectedFontSize === 'text-2xl text-white'}
                    onChange={() => handleSelectedFontSize('largeFont')}
                />    
                <Radio
                    className="w-[18rem] mx-auto mt-2 mb-4 h-6 opacity-70 rounded-lg max-w-md "
                    label='xLarge'
                    name='radioGroup'
                    value='xlargeFont'
                    checked={selectedFontSize === 'text-4xl text-white'}
                    onChange={() => handleSelectedFontSize('xlargeFont')}
                />               
            </Segment>
            <Segment>
                <label className="pl-8 font-semibold">Text Opacity</label>
                <ReactSlider                                
                    className="w-[18rem] mx-auto mt-2 mb-4 h-6 bg-gray-400 opacity-70 rounded-lg max-w-md "
                    trackClassName="h-6 bg-gray-200 text-black background-color: rgba(0,0,0,0.3) rounded-lg "
                    thumbClassName="w-6 h-6 bg-white border-2 border-gray-400 rounded-full shadow-md outline-none cursor-pointer]  "
                    defaultValue={70}
                    max={100}
                    min={0}
                    step={10}                            
                    ariaLabel="Text Background Opacity"
                    value={currentOpacityValue}
                    onChange={(value) => handleOpacityChange(value)}
                />       
                <label></label>                                            
            </Segment>
            </Segment.Group>
        </div>
    )
}