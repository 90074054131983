import React from "react";
import ReactSlider from "react-slider";
import { Card }  from "semantic-ui-react";


export default function DesignTextPosition({
    handleTextChange,
    currentXTextPositionValue,
    currentYTextPositionValue,
    currentTextWidthValue,
    }) {
    

    function handleXTextPositionChange(value) {        
        handleTextChange(value, undefined, undefined)
    }

    function handleTextWidthChange(value) {
        handleTextChange(undefined, undefined, value)
    }
    
    function handleYTextPositionChange(value) {
        handleTextChange(undefined, value, undefined)
    }


    return (
        <div>
            {/* boxShadow:"7px 10px 12px 7px #d4d4d5, 0 0 0 1px #d4d4d5" */}
             <Card  style={{position: "sticky", bottom: 0, boxShadow:"2px 3px 4px 2px #d4d4d5, 0 0 0 1px #d4d4d5"}}>                
                <label className="pl-8 pt-[1rem] font-semibold ">Text Up/Down</label>
                <ReactSlider                                
                    className="w-[18rem] mx-auto mt-2 mb-2 h-6 bg-gray-400 opacity-70 rounded-lg max-w-md "
                    trackClassName="h-6 bg-gray-200 text-black background-color: rgba(0,0,0,0.3) rounded-lg "
                    thumbClassName="w-6 h-6 bg-white border-2 border-gray-400 rounded-full shadow-md outline-none cursor-pointer]  "                                                
                    defaultValue={currentYTextPositionValue}
                    max={590}
                    min={-590}
                    step={1}                                                     
                    ariaLabel="Text Position"
                    value={currentYTextPositionValue}
                    onChange={(value) => handleYTextPositionChange(-value)}
                />   
                <label className="pl-8 font-semibold">Text Left/Right</label>
                <ReactSlider                                
                    className="w-[18rem] mx-auto mt-2 mb-4 h-6 bg-gray-400 opacity-70 rounded-lg max-w-md "
                    trackClassName="h-6 bg-gray-200 text-black background-color: rgba(0,0,0,0.3) rounded-lg "
                    thumbClassName="w-6 h-6 bg-white border-2 border-gray-400 rounded-full shadow-md outline-none cursor-pointer]  "
                    defaultValue={-50}
                    max={200}
                    min={-300}
                    step={1}                              
                    ariaLabel="Text H Position"
                    value={currentXTextPositionValue}
                    onChange={(value) => handleXTextPositionChange(value)}
                />
                <label></label>
                </Card>
                
                <Card   style={{position: "sticky", bottom: 0, boxShadow:"2px 3px 4px 2px #d4d4d5, 0 0 0 1px #d4d4d5"}}>                
                <label className="pl-8  pt-[1rem] font-semibold ">Text Width</label>
                <ReactSlider                                
                    className="w-[18rem] mx-auto mt-2 mb-2 h-6 bg-gray-400 opacity-70 rounded-lg max-w-md "
                    trackClassName="h-6 bg-gray-200 text-black background-color: rgba(0,0,0,0.3) rounded-lg "
                    thumbClassName="w-6 h-6 bg-white border-2 border-gray-400 rounded-full shadow-md outline-none cursor-pointer]  "
                    defaultValue={100}
                    max={100}
                    min={0}
                    step={10}                            
                    ariaLabel="Text Width"
                    value={currentTextWidthValue}
                    onChange={(value) => handleTextWidthChange(value)}
                />
                <label></label>                          
            </Card>                               
        </div>
    )
}