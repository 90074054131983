import React, {useContext, useState, useEffect} from "react";
import { AuthContext } from "../context/auth";
import { Modal, Table, Segment, Icon, Image, Button, Message } from 'semantic-ui-react';
import {getGetHeaders} from "../utils/headers";
import { PinterestIcon, FacebookIcon, TwitterIcon,  } from 'react-share';
// import ReviewsLandingPage from "./ReviewsLandingPage";
// import MetricsRow from "../components/MetricsRow";
import ReactTimeAgo from "react-time-ago";
import GraphListingHits from "../components/graphs/GraphListingHits";
import BinderAssetMetrics from "../components/BinderAssetMetrics";
import GenericListingSelector from "../components/GenericListingSelector";
import PropertyList from "./PropertyList";

function MetricsDashboard({updatePage}) {
    const context = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [properties, setProperties] = useState([]);        
    const [airbnbCount, setAirbnbCount] = useState();
    const [vrboCount, setVrboCount] = useState([]);
    const [binders, setBinders] = useState([]);
    const [binderAssetCount, setBinderAssetCount] = useState(0);
    const [showBinders, setShowBinders] = useState(false);
    const [listingMetrics, setListingMetrics] = useState([]);
    const [assetMetrics, setAssetMetrics] = useState([]);
    const [unFilteredMetrics, setUnFilteredMetrics] = useState([]);
    const [showMetrics, setShowMetrics] = useState(false);
    const [open, setOpen] = useState(false)
    const [modalMetrics, setModalMetrics] = useState([]);
    const [filteredListingId, setFilteredListingId] = useState();

    const MessageNoProperties = () => <Message floating warning>
        <Message.Header>Get started by adding a property listing.
        </Message.Header>
        Select 'Listings' from the menu above and you'll be on your way!
    </Message>


    function handleShowMetrics(binderData) {
        setModalMetrics(binderData)
        setOpen(true);
        setShowMetrics(!showMetrics);
    }

    function toggleMetricsDetail() {
        if (!showBinders) {
            setShowBinders(true);
            
        } else {
            setShowBinders(false);
            
        }
    }


    
    // Shareable metrics
    // get a list of binder items
    // get last 7 days of activity for each binder item

    useEffect(() => {        
        async function getProperties() {
            let headers = getGetHeaders();            
            const resp = await fetch(`${process.env.REACT_APP_BE_BASE_URL}/api/get_properties/`, {
                headers      
            })
            if (resp.status === 401) {
                context.logout({});
            }
            const data = await resp.json();             
            setProperties(data);   
            if (data.length == 0) {updatePage(<PropertyList />) }
        }           
        getProperties();     
        // eslint-disable-next-line react-hooks/exhaustive-deps                    
    }, []);

    useEffect(() => {              
        async function queryReviewCounts() {
            let headers = getGetHeaders();
            const resp = await fetch(`${process.env.REACT_APP_BE_BASE_URL}/api/getreviewcounts`, {
                headers      
            })
            if (resp.status === 401) {
                context.logout({});
            }
            const data = await resp.json();   
            setAirbnbCount(data.airbnb);
            setVrboCount(data.vrbo);
        }
        queryReviewCounts();        
    }, [properties]);

    
    useEffect(() => {
        async function fetchAssetCount() {
            let headers = getGetHeaders();
            const resp = await fetch(`${process.env.REACT_APP_BE_BASE_URL}/api/binderassetcounts`, {
                 headers
            })
            if (resp.status === 401) {
                context.logout({});
            }
            const data = await resp.json();                     
            setBinderAssetCount(data);    
        }
        fetchAssetCount();
    }, []);
    
    useEffect(() => {
        async function fetchBinders() {
            let headers = getGetHeaders();
            const resp = await fetch(`${process.env.REACT_APP_BE_BASE_URL}/api/binders`, {
                headers
            })
            if (resp.status === 401) {
                context.logout({});
            }
            const data = await resp.json();
            setBinders(data);
            // debugger;
            setFilteredListingId(data[0]?.id);
            setLoading(false);
        }
        fetchBinders();        
    }, []);

    useEffect(() => {
        async function fetchAssetMetrics() {
            let headers = getGetHeaders();
            const resp = await fetch(`${process.env.REACT_APP_BE_BASE_URL}/api/assetmetrics30`, {
                headers
            })
            if (resp.status === 401) {
                context.logout({});
            }
            const data = await resp.json();
            
            // const sortable = Object.fromEntries(
            //     Object.entries(data).sort(([,a],[,b]) => a-b)
            // );
            const sorted = Object.entries(data).sort(([,a],[,b]) => b-a)
            
            setAssetMetrics(sorted);
            setUnFilteredMetrics(sorted);
            // debugger;
        }
        fetchAssetMetrics();
    }, [properties]);

    useEffect(() => {
        async function fetchPropertyMetrics() {
            let headers = getGetHeaders();
            const resp = await fetch(`${process.env.REACT_APP_BE_BASE_URL}/api/propertymetrics`, {
                headers
            })
            if (resp.status === 401) {
                context.logout({});
            }
            const data = await resp.json();            
            setListingMetrics(data);                        
        }
        fetchPropertyMetrics();
        // debugger;
    }, [properties]);

    // const handleChangePage = () => {
    //     updatePage(<ReviewsLandingPage/>);
    // }

    const handleListingSelectionCallback = (listingId) => {  
        // filter out id's not matching listingId in assetmetrics        
        const filtered = binders.filter((item) => {            
            return item.user_property_id === listingId;
        })   
        const result = unFilteredMetrics.filter((elem) => filtered.find(({ id }) => parseInt(elem[0]) === id));
        setAssetMetrics(result);
    }


    return (       
        <>              
        {loading && (
            <div key="123loading" className="ui active centered inline loader"></div>
        )}
        {!loading && (
            <>                  
             {listingMetrics ? (             
                <GraphListingHits 
                    properties={properties}                    
                    data={listingMetrics}
                    />            
            ) : (
                <div>no data in listingMetrics</div>
            )} 
                                                         
                     
            <Segment>
               
                
                
            {/* https://deniapps.com/blog/responsive-semantic-ui-table */}

            <GenericListingSelector handleListingSelectionCallback={handleListingSelectionCallback} />   
            
            <Table color='blue' compact celled unstackable striped className="dnxTable">
                {assetMetrics && assetMetrics.length > 0 ? (                
                    <Table.Header>
                        <Table.Row  className="bg-slate-100 shadow-inner rounded-sm">
                            <Table.HeaderCell >Asset</Table.HeaderCell>
                            <Table.HeaderCell >Created</Table.HeaderCell>
                            <Table.HeaderCell >Purpose</Table.HeaderCell>
                            <Table.HeaderCell >Landing Page</Table.HeaderCell>
                            <Table.HeaderCell >Views</Table.HeaderCell>
                            <Table.HeaderCell >Listing</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>       
                ) : (<MessageNoProperties />)}            
                <Table.Body>                                           
                
                {Object.keys(assetMetrics).map((asset, index) => (   
                        // properties.filter(property => property.id === binders.filter(binder => binder.id===parseInt(assetMetrics[index][0]))[0]?.user_property_id)[0]?.id &&                        
                        <Table.Row key={index} >                                                       
                            <Table.Cell data-label="Asset" >                                                                     
                                <Image 
                                    style={{cursor: "pointer"}}                                            
                                    size="tiny"                                     
                                    src={binders.filter(binder => binder.id===parseInt(assetMetrics[index][0]))[0]?.image} 
                                    onClick={()=>handleShowMetrics(binders.filter(binder => binder.id===parseInt(assetMetrics[index][0]))[0])}                                             
                                />                     
                            </Table.Cell>
                            <Table.Cell data-label="Created">                                                                
                                <ReactTimeAgo  date={new Date(binders.filter(binder => binder.id===parseInt(assetMetrics[index][0]))[0]?.created_date)} locale="en-US"/>
                            </Table.Cell>
                            <Table.Cell data-label="Purpose">                                
                                {binders.filter(binder => binder.id===parseInt(assetMetrics[index][0]))[0]?.post_purpose}
                            </Table.Cell>
                            <Table.Cell data-label="Landing Page">                               
                                {binders.filter(binder => binder.id===parseInt(assetMetrics[index][0]))[0]?.landing_page ? binders.filter(binder => binder.id===parseInt(assetMetrics[index][0]))[0]?.landing_page.title : "No Landing Page"}
                            </Table.Cell>
                            <Table.Cell data-label="Views">
                                {assetMetrics[asset][1]}
                            </Table.Cell>
                            <Table.Cell data-label="Listing">                                
                                {properties.filter(property => property.id === binders.filter(binder => binder.id===parseInt(assetMetrics[index][0]))[0]?.user_property_id)[0]?.nickname}                                
                            </Table.Cell>
                        </Table.Row>
                    ))}
                    </Table.Body>
                </Table> 
                <Modal
                    closeIcon={true}
                    size="small"
                    onClose={() => setOpen(false)}
                    onOpen={() => setOpen(true)}
                    open={open}                                    
                    >                                        
                    <Modal.Header>Metrics</Modal.Header>
                    <Modal.Content>
                        <BinderAssetMetrics binder={modalMetrics} />                  
                    </Modal.Content>
                    <Modal.Actions>
                    <Button color='black' onClick={() => setOpen(false)}>
                        Close
                    </Button>                  
                    </Modal.Actions>
                </Modal>                
                
            </Segment>
        </>       
        )}
            
        </>
        
        
    )
}

export default MetricsDashboard;