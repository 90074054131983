import React, {useEffect, useState} from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Base from './pages/Base'
import Landing from './pages/Landing'
import Login from './components/LoginOut/Login'
// import Gallery from './pages/Gallery'
import DesignShareable from './pages/DesignShareable'
import BookingPage from './pages/BookingPage'
import MetricsDashboard from './pages/MetricsDashboard'
import { AuthProvider } from './context/auth'
// import { UserPropertyContext} from './context/UserPropertyContext';
// import { GoogleOAuthProvider } from '@react-oauth/google';
// import { GOOGLE_CLIENT_ID } from './config'
// import './App.css';
import Register from './pages/Register'
import Pitch from './pages/Pitch'
import PrivacyPolicy from './components/PrivacyPolicy'
import PricingPage from './pages/PricingPage'
import Features from './components/Features'
import UpdatePassword from './components/UpdatePassword'
import Insights from './components/Insights'
import Binder from './pages/Binder'
import ListingPictures from './pages/ListingPictures'

function App() {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {    
    async function isAuthenticated() {            
      setToken(localStorage.getItem("token"));            
      if (token) {
        setAuthenticated(true);
      } 
    }
    isAuthenticated();
  }, []);


  return (
    <>
    <AuthProvider>
      {/* <UserPropertyContext.Consumer> */}
    {/* <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>     */}    
        <BrowserRouter>                       
          <Routes>
            {!authenticated ? (
                <>                
                <Route index element={<Pitch />} />
                <Route path='/login' element={<Login />} />
                <Route path='/register' element={<Register />} />
                <Route path='/insights' element={<Insights/>} />
                <Route path='/signup' element={<Register />} />
                {/* <Route path="/fans/:id" element={<Gallery />} /> */}
                <Route path='/book/:binderId' element={<BookingPage />} />
                <Route path='/privacy' element={<PrivacyPolicy />} />
                <Route path='/pricing' element={<PricingPage />} />
                <Route path='/features' element={<Features />} />
                <Route path='/password_reset/confirm/:token' element={<UpdatePassword />} />
                </>
            ) : (
              <>              
              <Route index element={<Landing/>} />                                    
              <Route path='/home' element={<Base/>} />              
              <Route path='/metrics' element={<MetricsDashboard/>} />
              <Route path='/binder' element={<Base title="Binder" updatePage={<Binder />}/>} /> 
              <Route path='/listingpics/:listingId' element={<Base title="Additional Listing Pictures" updatePage={<ListingPictures />}/>} />
              <Route path='/shareables/:review' element={<DesignShareable /> } />              
              <Route path='/book/:binderId' element={<BookingPage />} />
              </>
            )}                      
          </Routes>      
        </BrowserRouter>

    {/* </GoogleOAuthProvider> */}
    {/* </UserPropertyContext.Consumer> */}
    </AuthProvider> 
    </>
  )
}

export default App;