import React, {useEffect, useState} from 'react';
import { PinterestShareButton, PinterestIcon, FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon } from 'react-share';
import {saveAs} from "file-saver";
import HelmetMetaData from './HelmetMetaData';
import {Icon} from 'semantic-ui-react';

function CustomShareButton({imgUrl, txtQuote, bookUrl, hashtags}) {  
    const [twitterHashTags, setTwitterHashTags] = useState([]);        
    var bookUrl = `${process.env.REACT_APP_FE_BASE_URL}/book${bookUrl}`    

    useEffect(() => {
        // sharing component adds a #, so need to remove it from the first hashtag
        // otherwise end up with ##hashtag
        // Twitter also expects an array of hashtags
        // debugger;
        var cleaned = hashtags.substr(1);
        cleaned.split(' ').join('%20');        
        setTwitterHashTags([cleaned]);
        // setTwitterHashTags(hashtags)
        // debugger;
    }, [hashtags])

    function copyTemplate() {                
        navigator.clipboard.writeText(txtQuote);
    }


    const handleSave = ()=>{              
        if (imgUrl.indexOf('http') === -1) {
            // console.log(`in handleSave, url needs fixing, img: ${imgUrl}`);
            imgUrl = `${'http://127.0.0.1:8000'}${imgUrl}`
        }
        saveAs(imgUrl, "OwnerGuestShareable.png");
       }

    return (
        
        <div>                 
        {bookUrl !== null ? (
            <>
            <div className="centerContent">
            <div className="selfCenter spaceBetween pb-4">  
            <HelmetMetaData                 
                url={bookUrl} 
                title={txtQuote}
                quote={txtQuote} 
                description={txtQuote}
                image={imgUrl}
                hashtags={hashtags}
             >   
                <meta charSet="utf-8" />
                <title>OwnerGuest, Where AI meets STR to deliver more bookings </title>
                <link rel="canonical" href={bookUrl} />
             </HelmetMetaData>               
                <TwitterShareButton
                    url={bookUrl.replace("www.", "")}
                    title={txtQuote}
                    hashtags={twitterHashTags} // #hashTag
                    style={{padding:"5px"}}>
                    <TwitterIcon
                    size={32}
                    onClick={handleSave}
                    round />
                </TwitterShareButton>
               {/* Quote is no longer supported by FB, so the user will be instructed to 
               cmd+p or ctrl+p to past it */}
                <FacebookShareButton
                    url={bookUrl}
                    quote={txtQuote}
                    hashtag={hashtags} // #hashTag                    
                    style={{padding:"5px"}}
                >
                    <FacebookIcon
                    size={32}
                    round 
                    onClick={copyTemplate}
                    />
                </FacebookShareButton>
               
                <PinterestShareButton
                    url={bookUrl}
                    media={imgUrl}
                    description={txtQuote}
                    style={{padding:"5px"}}
                >
                    <PinterestIcon
                    size={32}
                    round 
                    />                    
                </PinterestShareButton>
                <div style={{alignSelf: "center", float:"right"}} >
            <Icon name="download" size="large" onClick={handleSave} style={{padding:"5px"}}/>
        </div>        
            </div>                            
        </div>
                  
        </>
        ):(<></>)}
    </div>
    )    
}
export default CustomShareButton;