import React, { useReducer, createContext } from 'react';

// const  {REACT_APP_OG_Auth_Token} = process.env
// const OG_Auth_Token = `${REACT_APP_OG_Auth_Token}`
const OG_Auth_Token = `Basic ${process.env.REACT_APP_OG_Auth_Token}`


const initialState = {
  user: null
};

var token = localStorage.getItem('token')
if (token !== undefined && token !== null) {
  var expired_milliseconds = parseInt(localStorage.getItem('expires'));
  // console.log(`expires ${new Date(expired_milliseconds).toLocaleString()}`)
  var token_expires = new Date(expired_milliseconds);  
  
  if (token_expires < Date.now()) {
    refresh();
  } else {
    initialState.user = token;        
  }
}

async function refresh() {
  var myHeaders = new Headers();
  
  myHeaders.append("Authorization", OG_Auth_Token);

  var formdata = new FormData();
  formdata.append("grant_type", "refresh_token");
  formdata.append("refresh_token", localStorage.getItem('refresh_token'));

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formdata,
    redirect: 'follow'
  };
  
  fetch(`${process.env.REACT_APP_BE_BASE_URL}/api/o/token/`, requestOptions)
  .then(response => response.text())
  .then(result => {        
      // console.log(result);
      var data = JSON.parse(result);   
      var now = new Date();
      var expires = now.getTime() + data.expires_in * 1000;                      
      localStorage.setItem('token', data.access_token);  
      localStorage.setItem('refresh_token', data.refresh_token);  
      localStorage.setItem('expires', expires);
      console.log(`refreshed, new expiration ${new Date(expires).toLocaleString()}`)           
  })
  .catch(error => console.log('error', error));             
}

const AuthContext = createContext({
  user: null,
  error: null,
  login: (userData) => {},
  logout: () => {},
});

function authReducer(state, action) {    
  switch (action.type) {
    case 'LOGIN':      
      if (action.payload.error) {
        return {
          ...state,
          error: 'Login failed. Please check your username and password and try again.'
        };        
      } else {
        window.location.replace("/");
        return {
          ...state,
          user: action.payload        
        };
      }
    case 'ERROR':  
      // console.log(action.payload);      
      return {
        ...state,
        error: action.payload
      };
    case 'LOGOUT':
      window.location.replace("/");
      return {
        ...state,
        user: null
      };      
    default:      
      return state;
  }
}

function AuthProvider(props) {
  const [state, dispatch] = useReducer(authReducer, initialState);

  async function login(userData, setGeneralError, setButtonLoading) {    
    var myHeaders = new Headers();    
    myHeaders.append("Authorization", `${OG_Auth_Token}`);

    var formdata = new FormData();
    formdata.append("grant_type", "password");
    formdata.append("username", userData.email);
    formdata.append("password", userData.password);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_BE_BASE_URL}/api/o/token/`, requestOptions);
      // console.log(JSON.stringify(response));
      if (!response.ok) { 
        setGeneralError('Login failed. Please check your username and password and try again.');
        setButtonLoading(false);               
        dispatch({
          type: 'ERROR',
          payload: response.statusText
        });
      } else {
        setGeneralError('Success');
        setButtonLoading(false);
        const result = await response.text();
        var data = JSON.parse(result);
        var now = new Date();
        var expires = now.getTime() + data.expires_in * 1000;
        localStorage.setItem('token', data.access_token);  
        localStorage.setItem('refresh_token', data.refresh_token);  
        localStorage.setItem('expires', expires);
        localStorage.setItem('user', userData.email);
        // console.log(`expires ${new Date(expires).toLocaleString()}`)
        dispatch({
          type: 'LOGIN',          
          payload: data
        });
      }
    } catch (error) {
      // console.log(`${process.env.REACT_APP_BE_BASE_URL}/api/o/token/, ${error}`);
      dispatch({
        type: 'ERROR',
        payload: error
      });
    }
  }


  function logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('expires');
    dispatch({ type: 'LOGOUT' });
  }

  return (    
    <AuthContext.Provider
      value={{ user: state.user, error: state.error, login, logout }}
      {...props}
    />    
  );
}

export { AuthContext, AuthProvider };