import React from "react";
import { Message, Grid, List, Button } from "semantic-ui-react";
import ImageSlider from "../components/ImageSlider";
import MailchimpFormCustom from "../components/MailChimpFormCustom";

export default function MainPitch({handleSignUp}) {
    return (
    <>
    <Grid stackable centered columns={4}>   
        <Grid.Row className="pb-8">
            <Grid.Column  width={3} />                            
            <Grid.Column width={6}>
                <div className="ui center ">
                    <div className="contents text-[#54c3f2] text-8xl pt-10 pl-10 leading-14 italic">
                    Get Booked!
                    </div>
                    <div className=" text-[#54c3f2] text-4xl leading-14 pt-8 pb-8">
                    Create an instant impression for your vacation rental by combining the power of guest reviews with property images and AI generated content. Boom! Shareable social proof!
                    </div>
                    <Button size="huge" href="/signup" color="orange" className="rounded-lg bg-orange-400" onClick={(e)=>handleSignUp(e)}>Get Started  &gt;</Button>                
                </div>                    
            </Grid.Column>
            <Grid.Column  width={4}>
                <div className="ui ">
                    <img
                        alt="OwnerGuest screenshots"
                        src="/images/landing_screenshots.png" 
                        className="ui centered medium image background-white h-[24em]" 
                    />                          
                </div>      
            </Grid.Column>
            <Grid.Column  width={3} />            
        </Grid.Row>  
                <Grid.Row>
                </Grid.Row>           
            </Grid>
                        
            <Grid centered columns={3} width={16} className="bg-gradient-to-r from-cyan-500 to-blue-500 ">
                <Grid.Column width={2}></Grid.Column>
                <Grid.Column width={10}>
                    <div className="text-white text-4xl leading-14 pt-8 pb-8">
                        <List size="large"  relaxed verticalAlign='middle'>
                            <List.Item>
                                <List.Description>AI Powered</List.Description>
                                <List.Description style={{color:"white"}}>
                                    No more writers block, generate content through the power of AI
                                </List.Description>
                            </List.Item>
                            <List.Item>                                                                   
                                <List.Description>Reviews</List.Description>
                                <List.Description style={{color:"white"}}>
                                    We'll fetch your Airbnb & Vrbo reviews for you
                                </List.Description>
                            </List.Item>
                            <List.Item>
                                <List.Description>Images that Pop!</List.Description>
                                <List.Description style={{color:"white"}}>
                                    No more boring posts, let the images speak
                                </List.Description>
                            </List.Item>
                            <List.Item>
                                <List.Description>Custom Landing Pages</List.Description>
                                <List.Description style={{color:"white"}}>
                                    Share a single link with all your booking platform options 
                                </List.Description>
                            </List.Item>
                            <List.Item>
                            <List.Description>Metrics!</List.Description>
                                <List.Description style={{color:"white"}}>
                                    Finally know how your marketing is performing
                                </List.Description>
                            </List.Item>
                        </List>
                    </div>
                </Grid.Column>
                <Grid.Column width={2}></Grid.Column>
            </Grid>           
            <Grid centered >
                <Grid.Column width={2}></Grid.Column>
                <Grid.Column width={12}>
                    <ImageSlider className="m-2, p-2 w-fit" style={{margin:"auto"}}/>                
                </Grid.Column>     
                <Grid.Column width={2}></Grid.Column>                           
            </Grid>
            <Grid centered columns={3} width={16} >
                <Grid.Column width={2}></Grid.Column>
                <Grid.Column width={12}>                   
                    <Message centered="true" color="black" size="small" style={{ borderRadius: 12}}>                                    
                        <MailchimpFormCustom />                                                               
                    </Message>                
                </Grid.Column>
                <Grid.Column width={2}></Grid.Column>
            </Grid>
            </>
    )
}