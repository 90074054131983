import React, { useState, useEffect, useContext } from 'react';
import { getGetHeaders } from '../utils/headers';
import { AuthContext } from '../context/auth';
import { Dropdown } from 'semantic-ui-react';


function PropertyListDropdown({handleCallback}) {
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const context = useContext(AuthContext);

  useEffect(() => {
    async function getProperties() {    
        let headers = getGetHeaders();               
        const response = await fetch(`${process.env.REACT_APP_BE_BASE_URL}/api/get_properties/`, 
            {headers})
        
        if (response.status == 401) {
            context.logout({});
        }                                
        
        const body = await response.json()   
        // map body to options with nickname and id
        body.map((property) => {
            setOptions(options => [...options, {nickname: property.nickname, value: property.id}]);
        })
        
    }
    getProperties();      
  }, []);

  useEffect(() => {
    setSelectedOption(options?.[0]?.value);     
    handleCallback(options?.[0]?.value);       
  }, [options]);


  function handleChange(event, data) {      
    setSelectedOption(data.value);    
    handleCallback(data.value);        
  }

  return (
    <Dropdown      
      style={{marginRight: '1em'}}
      placeholder='Select property'      
      selection
      options={options.map(option => ({
        key: option.id,
        text: option.nickname,
        value: option.value
      }))}
      onChange={handleChange}
      value={selectedOption ? selectedOption : options?.[0]?.value}
    />
    // <div>
    //   <label htmlFor="dropdown" className="block font-semibold text-gray-700">
    //     Select a property:
    //   </label>
    //   <select id="dropdown" onChange={handleChange} value={selectedOption} className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline-blue">
        
    //     {options.map(option => (
    //       <option key={option.value} value={option.value} >
    //         {option.nikname}
    //       </option>
    //     ))}
    //   </select>
    // </div>
  );
}

export default PropertyListDropdown;
