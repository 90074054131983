import React, {useEffect, useState} from "react";
import { List, Image } from 'semantic-ui-react';
import { getGetHeaders } from "../utils/headers";


export default function PictureSelector({listingId, handlePictureChange}) {
    const [listingPics, setListingPics] = useState([]);

    useEffect(() => {
        async function fetchListingPics() {
            let headers = getGetHeaders();
            const response = await fetch(`${process.env.REACT_APP_BE_BASE_URL}/api/listing_pics/${listingId}`, 
            {headers});
            const body = await response.json();
            setListingPics(body);
        }
        fetchListingPics();
    }, []);

    return (
        <div style={{alignSelf:"center"}}>
            {/* {!listingPics ?? <p>No pictures found</p>} */}
            <List horizontal  relaxed='very'>
            {listingPics.map((pic, index) => (
                <List.Item key={index}>
                <Image size="tiny" src={pic.image} onClick={()=>handlePictureChange(pic.image)}/>                
              </List.Item>
            ))}
            </List>
        </div>
    )
}
