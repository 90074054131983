import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);


const GraphClicks = ({platform, src, color}) => {       
        // var combined_labels = Object.assign(airbnb.map(airbnb => airbnb.x), vrbo.map(vrbo => vrbo.x));
        // debugger;
        const data = {
            labels: platform.map(platform => platform.x),            
            datasets: [
                {
                    label: `${src}`,
                    data: platform.map(platform => platform.y),
                    fill: false,
                    lineTension: 0.1,
                    // backgroundColor: 'rgba(75,192,192,0.4)',
                    // borderColor: 'rgba(75,192,192,1)',
                    borderColor: `rgba(${color})`,
                    backgroundColor: `rgba(${color}, 0.5)`,
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: `rgba(${color},1)`,
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: `rgba(${color},1)`,
                    pointHoverBorderColor: `rgba(${color},1)`,
                    pointHoverBorderWidth: 2,
                    pointRadius: 4,
                    pointHitRadius: 10
                }
                
                // ,
                // {
                //     label: 'Vrbo Clicks by Date',
                //     data: vrbo.map(vrbo => vrbo.y),
                //     fill: false,
                //     lineTension: 0.1,
                //     borderColor: 'rgb(53, 162, 235)',
                //     backgroundColor: 'rgba(53, 162, 235, 0.5)',
                //     borderCapStyle: 'butt',
                //     borderDash: [],
                //     borderDashOffset: 0.0,
                //     borderJoinStyle: 'miter',
                //     pointBorderColor: 'rgba(75,192,192,1)',
                //     pointBackgroundColor: '#fff',
                //     pointBorderWidth: 1,
                //     pointHoverRadius: 5,
                //     pointHoverBackgroundColor: 'rgba(53, 162, 235,1)',
                //     pointHoverBorderColor: 'rgba(220,220,220,1)',
                //     pointHoverBorderWidth: 2,
                //     pointRadius: 1,
                //     pointHitRadius: 10
                // }
            ]        
    }

    return (
        <Line data={data} />
    )
}

export default GraphClicks;
