import React from 'react';
import { Icon } from 'semantic-ui-react';

export default function FlairObject({flair}) {
    console.log(flair);
    // bordered

    
    return(
        // <div className="min-fit relative mr-4 items-center px-4 py-2 pt-2 left-1/2 rounded-lg ">                   
        <div style={{
            width: "100px", 
            height: "100px",
            position: "absolute",
            top: "20px",
            right: "0px"}} 
        >
            <Icon                  
                name={flair} 
                size="large" 
                color="black" 
                style={{}}
            />            
        </div>
        
    )
}