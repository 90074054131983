import React, { Component } from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe"
import { Container, Card, Grid, Button, Form, Message } from "semantic-ui-react";

const CustomForm = ({ status, message, onValidated }) => {    
  let email, name;
  const submit = () =>
    email &&
    name &&
    email.value.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email.value,
      NAME: name.value
    });

  return (
    <>    
        <Form>
            <Form.Group >
            <Message
            warning
            header='Could you check something!'
            list={[
                'That e-mail has been subscribed, but you have not yet clicked the verification link in your e-mail.',
            ]}
            />
                <Form.Field>      
                <input
                    style={{ fontSize: "2em", padding: 5, color: "black" }}
                    ref={node => (name = node)}
                    type="text"
                    placeholder="Name"
                />
                </Form.Field>
                <Form.Field>      
                <input
                    style={{ fontSize: "2em", padding: 5, color: "black" }}
                    ref={node => (email = node)}
                    type="email"
                    placeholder="Email"
                />
                </Form.Field>    
                <Form.Button style={{fontSize:"1.2em"}} color="orange" content='Submit' onClick={submit} />                    
            </Form.Group>
        </Form>
  
        {status === "sending" && <div style={{ color: "#3986f3" }}>sending...</div>}
        {status === "error" && (
            <div
            style={{ color: "red" }}
            dangerouslySetInnerHTML={{ __html: message }}
            />
        )}
        {status === "success" && (
            <div
            style={{ color: "green" }}
            dangerouslySetInnerHTML={{ __html: message }}
            />
        )}  
      </>     
  );
};

class MailchimpFormCustom extends Component {  
  render() {       
    const url = "//ownerguest.us10.list-manage.com/subscribe/post?u=2a29bf1aa27ff938f40eb9922&amp;id=f7e3e51122&amp;f_id=00dee0e5f0";
    return (        
      <div>       
        <h1 className="text-white text-2xl leading-14 pb-2 pl-2 mt-1">I want a free OwnerGuest image of my property!</h1>                        
        <MailchimpSubscribe
          url={url}
          render={({ subscribe, status, message }) => (
            <CustomForm
              status={status}
              message={message}
              onValidated={formData => subscribe(formData)}
            />
          )}
        />
      </div>      
    );
  }
}

export default MailchimpFormCustom;
// render(<Demo />, document.querySelector("#demo"));