import React, { useEffect, useState, useContext } from 'react'
import {getGetHeaders} from "../utils/headers";
import { Card, Container, Message, Label, Image, Popup, Segment, Icon } from 'semantic-ui-react';
import AddProperties from './AddProperties';
import DeleteButton from '../components/DeleteButton';
import { AuthContext } from "../context/auth";
import RefreshReviewsButton from '../components/RefreshReviewsButton';
import ReactTimeAgo from 'react-time-ago'

function PropertyList() {
    // const [properties] = useContext(UserPropertyContextProvider);
    const { user } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);  
    const context = useContext(AuthContext);
    const [properties, setProperties] = useState([]);    
    const [propertyListChanged, setPropertyListChanged] = useState(false);
    const [lastRefreshDate, setLastRefreshDate] = useState(null);
    const [onTimeOut, setOnTimeOut] = useState(true);

    const handleCallback = () => {
        setPropertyListChanged(true)
    }

    const handleRefreshCallback = () => {
        setPropertyListChanged(true)
    }
    
    const date = new Date();
    const defaultDate = date.getTime();
    

    useEffect(() => {
        async function getProperties() {    
            let headers = getGetHeaders();
            if (!user) { context.logout({}); }      
            setLoading(true);        
            const response = await fetch(`${process.env.REACT_APP_BE_BASE_URL}/api/get_properties/`, 
                {headers})
            
            if (response.status === 401) {
                context.logout({});
            }                                
            
            const body = await response.json()                
            setProperties(body);
            setPropertyListChanged(false);
            setLoading(false);
        }
        getProperties();        
        setPropertyListChanged(false);
        setLoading(false);
         // eslint-disable-next-line react-hooks/exhaustive-deps      
    } , [propertyListChanged]);

    useEffect(() => {
        async function getLastRefreshDate() {
            let headers = getGetHeaders();
            if (!user) { context.logout({}); }
            setLoading(true);            
            const response = await fetch(`${process.env.REACT_APP_BE_BASE_URL}/api/reviewactivity`,
                {headers})
            if (response.status === 401) {
                context.logout({});
            }
            const body = await response.json()
            var temp = {};
            try{
                body.forEach(item => {
                    temp[item.user_property] = item.refreshed_date;
                });
            } catch {
                temp[body.user_property] = body.refreshed_date;
            }            
            setLastRefreshDate(temp);            
            setLoading(false);                    
        }
        getLastRefreshDate();
         // eslint-disable-next-line react-hooks/exhaustive-deps      
    }, [propertyListChanged]);

    
    function copyLink(url) {        
        navigator.clipboard.writeText(url);        
        setOnTimeOut(false);        
        setTimeout(() => {            
            setOnTimeOut(true);
          }, 2000);                  
    }

    

    return (
        <>               
            <Container>
                {loading ? (
                    <div className="ui active centered inline loader"></div>
                ) : (                    
                <Segment>
                    <Message hidden={onTimeOut} compact positive floating  >Copied!</Message>
                    <Card.Group>                    
                        {properties.length > 0 && properties.map((property) => (   
                            <Card key={property.id}>
                                <Image src={`${property?.image_url}`} wrapped ui={false}  alt={property.nickname}/> 
                                <Card.Content>                            
                                    <Card.Header className='block fit-content float-left m-auto pt-1'>
                                        {property.nickname}
                                        <a href={`/listingpics/${property.id}`}><Icon name="images outline" title="More images" alt="View additional images" style={{paddingLeft: "0.3em", paddingRight:"0.3em", outline:"none"}}/></a>                                      
                                    </Card.Header>
                                    <Card.Meta className='block fit-content float-right m-auto'>
                                        {property.airbnb_url ? (
                                            <Popup  content={property.airbnb_url} trigger={
                                            // <div title="Click to copy url" className="cursor-pointer float-right border-none"><Icon onClick={()=>copyTemplate(landingPage.description)} name="copy outline" size="small" style={{outline:"none"}}/></div>
                                            <div title="Click to copy url" onClick={()=>copyLink(property.airbnb_url)} style={{cursor:"pointer"}} className="inline p-1 divide-x rounded-lg"><span className="inline-block text-break bg-purple-100 text-purple-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-purple-200 dark:text-purple-900">
                                                Airbnb 
                                                <Icon name="copy outline" size="small" style={{paddingLeft: "1em", outline:"none"}} />
                                                </span>
                                                </div>
                                        } />
                                        ) : (<></>)}
                                        
                                        {property.vrbo_url ? (
                                            <Popup content={property.vrbo_url} trigger={
                                                <div title="Click to copy url" onClick={()=>copyLink(property.vrbo_url)} style={{cursor:"pointer"}} className="inline p-1 divide-x rounded-lg"><span className="inline-block text-break bg-pink-100 text-pink-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-pink-200 dark:text-pink-900">
                                                    VRBO
                                                    <Icon name="copy outline" size="small" style={{paddingLeft: "1em", outline:"none"}} />
                                                    </span></div>
                                            } />   
                                        ) : (<></>)}
                                    </Card.Meta>
                                    <Card.Description>                                     
                                        
                                    </Card.Description>
                                </Card.Content>
                                <Card.Content extra>
                                    <div className='block fit-content float-left m-auto'>
                                        <RefreshReviewsButton handleRefreshCallback={handleRefreshCallback} setLastRefreshDate={setLastRefreshDate} doorId={property.id}/><span className='pl-2'>Refresh reviews</span>
                                    </div>
                                    <div className='block fit-content float-left m-auto'>
                                        {lastRefreshDate && property && property.id in lastRefreshDate ? (
                                            <Label>
                                                Last refresh
                                                <Label.Detail>                                                
                                                    <ReactTimeAgo  date={property?.id ? new Date(lastRefreshDate[property.id]) : {defaultDate}} locale="en-US"/></Label.Detail>
                                            </Label>
                                            ) : (
                                                <></>
                                            )}
                                    
                                    </div>
                                    <div className='block fit-content float-right m-auto'>
                                        <DeleteButton id={property.id} typeOfObject="property" handleCallback={handleCallback} className="block md:inline md:float-right" />
                                    </div>
                                </Card.Content>
                            </Card>
                        ))}
                        {properties.length === 0 && (<div className='text-2xl p-4'>No listings found, why don't you add one?</div>)}
                    </Card.Group>           
                </Segment>
                )}            
            </Container>                          
        <AddProperties handleCallback={handleCallback}/>  
        </>
    );
}

export default PropertyList;