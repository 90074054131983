import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../context/auth";
import { Table, Button, Icon } from 'semantic-ui-react';
import Moment from 'react-moment';
import {getPostHeaders} from '../utils/headers'
import {getStars} from '../common/ReviewCommon'
import DoorSelector from "../components/DoorSelector";
import UseSelectedText from "../components/UseSelectedText";
import SelectPostSize from "../components/SelectPostSize";
import DesignStickyBase from "./DesignStickyBase";

function VRBOReviews({updatePage}) {
    const context = useContext(AuthContext);
    const [vrboData, setVrboData] = useState()
    const [loading, setLoading] = useState(true);
    // const [tagsUpdated, setTagsUpdated] = useState(false);
    const [showAll, setShowAll] = useState(false);
    // const [tagList, setTagList] = useState([]);
    const [showDesisgnShareable, setShowDesignShareable] = useState(false);
    const [activeReview, setActiveReview] = useState();
    const [doorUpdated, setDoorUpdated] = useState(0);
    const [highlight, setHighlight] = useState('');
    const [selectedPostSize, setSelectedPostSize] = useState([1200, 628]);
    const [postPurpose, setPostPurpose] = useState("Facebook Post Image");


    const showMore = () => setShowAll(true);
    const showLess = () => setShowAll(false);
    const limit = 150;
    

    // const handleCallback = () => {    
    //     setTagsUpdated(true)
    // }

    const handleDoorSelectionCallback = (doorId) => {        
        setDoorUpdated(doorId)
    }

    function get_review_content(review) {                                         
        if (review.length <= limit) {
            // there is nothing more to show
            return review.replace('<br />', ' ')
          }
        else if (showAll) {
            return review
        } else {            
            return review.substring(0, 200).replace('<br />', ' ') + "..."
        }
    }

    useEffect(() => {      
        //     // listingId is composed from some 3 digits plus the property id plus the unit id
        //     // https://www.vrbo.com/825205?adultsCount=2&arrival=2022-12-23&departure=2022-12-27&petIncluded=true&1373143
        //     // 321.825205.1373143
        //     // 321 not sure where it comes from. 121 was for Cleveland Ohio, 321 was Duck Creek, UT
        //     // 321 Concord, CA
        //     // 321 Wildwood, NJ

        async function queryVrbo() {
            let headers = getPostHeaders({doorId:doorUpdated}, "POST");
            const resp = await fetch(`${process.env.REACT_APP_BE_BASE_URL}/api/getvrboreviews`, 
                {...headers})
            if (resp.status === 401) {
                context.logout({});                  
            }
            const data = await resp.json();    
            if (data.length > 0) {
                setVrboData(data);
                setLoading(false);           
            }            
        }
        queryVrbo();  
         // eslint-disable-next-line react-hooks/exhaustive-deps            
    }, [doorUpdated]);


    // useEffect(() => {
    //     async function getCurrentTags() {
    //         let headers = getGetHeaders();
    //         const response = await fetch(`${process.env.REACT_APP_BE_BASE_URL}/api/tags/user/`, {
    //             headers
    //         })
    //         const data = await response.json();   
    //         setTagList(buildOptions(data));
    //     }
    //     getCurrentTags();        
    //   }, []);

    function handleShare(review) {
        setActiveReview(review);
        setShowDesignShareable(!showDesisgnShareable);
    }

    const handleShareableCallback = () => {
        setShowDesignShareable(!showDesisgnShareable);
    }
    return (  
        <>
        {showDesisgnShareable ? (
            <DesignStickyBase listingId={activeReview.user_property.id} postPurpose={postPurpose} selectedPostSize={selectedPostSize} updatePage={updatePage} review={activeReview} highlight={highlight} handleShareableCallback={handleShareableCallback} />)
        : ( 
            <>
            <DoorSelector platform="vrbo" handleDoorSelectionCallback={handleDoorSelectionCallback}/>     
            <div className="drop-shadow-xl">                                        
                {loading && vrboData?.length === 0 ? (<div className="ui active centered inline loader"></div>) : 
                (                                                                                      
                    <Table collapsing compact  unstackable striped className="dnxTable">
                        <Table.Header>
                        <Table.Row  className="bg-slate-100 shadow-inner">                        
                            <Table.HeaderCell textAlign="center">Published</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">Reviewer</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center" width={2}>Headline</Table.HeaderCell>
                            <Table.HeaderCell width={2} textAlign="center"><Icon name="star" /></Table.HeaderCell>                        
                            <Table.HeaderCell textAlign="center"  >Description</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">Design</Table.HeaderCell>
                        </Table.Row>
                        </Table.Header>                        

                        <Table.Body>
                            {vrboData && vrboData.map((review) => (                                                
                                <Table.Row key={review.id} className="even:bg-sky-50 odd:bg-blue-100 text-slate-600" >                                
                                    <Table.Cell data-label="Published"><Moment fromNow>{review.review_date}</Moment></Table.Cell>
                                    <Table.Cell data-label="Reviewer">{review.reviewer}</Table.Cell>
                                    <Table.Cell data-label="Headline">{review.headline}</Table.Cell>
                                    <Table.Cell data-label="Stars" textAlign="center"><div className="md:text-center">{getStars(review.rating)}</div></Table.Cell>
                                    <Table.Cell data-label="Description" className="min-width-4">
                                        <UseSelectedText setHighlight={setHighlight} value={get_review_content(review.review)} />
                                        {/* {get_review_content(review.review)} */}
                                            {review.review.length > limit ? (
                                                showAll ? 
                                                    (<Button basic  compact size="mini"  onClick={showLess} style={{marginLeft:"10px"}}>Read less</Button> ) : 
                                                    (<button onClick={showMore} className="rounded-full shadow-lg bg-sky-50 pl-4 pr-4 pb-1 align-top m-2 text-slate-400">more</button>)
                                                ) : null
                                            }   
                                        <div syle={{marginBottom:"10px"}}></div>                                   
                                    </Table.Cell>
                                    {/* <Table.Cell>
                                        <CustomTagPicker key={review.id * 2} tagList={tagList} reviewId={review.id} handleCallback={handleCallback} >                                        
                                        </CustomTagPicker>
                                        <CustomTagsDisplay reviewId={review.id} handleCallback={handleCallback} tagsUpdated={tagsUpdated} setTagsUpdated={setTagsUpdated}/>
                                    </Table.Cell> */}
                                    <Table.Cell data-label="Design">
                                        <div className="flex">
                                            <SelectPostSize setSelectedPostSize={setSelectedPostSize} setPostPurpose={setPostPurpose}/>&nbsp;                        
                                            <div className="tooltip" style={{cursor:"pointer"}} >
                                                <Icon size="big" name="picture" onClick={()=>handleShare(review)} style={{paddingLeft:"5px"}}/>
                                                <span className="tooltiptext">Share</span>
                                            </div>
                                        </div>
                                    </Table.Cell>                                    
                                </Table.Row>                                        
                            ))}
                        </Table.Body>
                </Table>    
                )}  
                {vrboData?.length === 0 ? (
                    <div className="p-4 m-4">
                        <div className='text-2xl p-4'>No reviews found. You have not received reviews for this
                            property or we did not find any listings with a Vrbo URL configured.
                        </div>
                    </div>         
                ) : <></>}            
            </div> 
            </>
            )}        
        </>                                                                                                      
  );    
}

export default VRBOReviews;