import React, {useEffect, useState, useContext} from "react";
import FPDropMulti from "../components/FPDropMulti";
import { useParams } from "react-router-dom";
import {getGetHeaders} from "../utils/headers";
import { AuthContext } from "../context/auth";
import { Grid, Image } from 'semantic-ui-react'

export default function ListingPictures() {    
    const { listingId } = useParams();
    const [imageId, setImageId] = useState({});
    const [listingPics, setListingPics] = useState([]);
    const { user } = useContext(AuthContext);
    const { context } = useContext(AuthContext);        

    const handleImageCallback = (id) => {                
        setImageId(id);
    }

    useEffect(() => {
        async function fetchListingPics() {
            let headers = getGetHeaders();
            if (!user) { context.logout({}); }

            const response = await fetch(`${process.env.REACT_APP_BE_BASE_URL}/api/listing_pics/${listingId}`, 
            {headers});
            const body = await response.json();
            setListingPics(body);
        }
        fetchListingPics();
    }, [listingId]);


  
    return (
        
        <>
            <h1>Additional Listing Pictures</h1>
            <FPDropMulti listingId={listingId} handleImageCallback={handleImageCallback}/>
            <Grid>
                {listingPics.map((pic) => (
                    <Grid.Row key={pic.image}>
                        <Image src={pic.image} alt={pic.id} />
                    </Grid.Row>
                ))}
            </Grid>
        </>
        
    )
}