import React, { useState } from 'react';
import { isMobile } from "react-device-detect";
import { Icon } from "semantic-ui-react";

export default function DesignText({highlight, selectedFontSize, shareable, handleInlineTextStyle}) {
    // implment DragMove here 
    // https://javascript.plainenglish.io/how-to-make-a-simple-custom-drag-to-move-component-in-react-f67d5c99f925
    // https://codesandbox.io/s/dragmove-wrapper-cg4hu?from-embed=&file=/src/App.js:147-354
    const [starRating, setStarRating] = useState();
    let ogFontSize = "text-sm text-white shadow-lg";

    useState(() => {        
        if (isMobile) {
            selectedFontSize = "text-xs text-white";
            ogFontSize = "text-xs text-white shadow-lg";
        }
        
    }, [])

    useState(() => {
        let stars = [];        
        for (let i = 0; i < shareable.rating; i++) {
            stars.push(<Icon key={i} name='star' size='large' color='yellow'/>)
        }
        setStarRating(stars);              
        
    }, [shareable])

    return(
        <div style={handleInlineTextStyle()} className="min-fit relative mr-4 items-center px-4 py-2 pt-2 bg-gray-800  left-1/2 rounded-lg ">                   
        <div>
            {starRating}
            </div>
            <span  className={selectedFontSize}>                                    
                "{highlight !== '' ? highlight : shareable.review}"  
                <div className="pr-4 pt-2 pb-2 text-right">- {shareable.reviewer}</div>
            </span>
            <span 
                style={{display: "table", marginRight: "auto", marginLeft: "auto"}} 
                className={ogFontSize}>
                    OwnerGuest.com
            </span>
        </div>
        
    )
}