import React, { useState, useEffect } from "react";
import Login from "../components/LoginOut/Login";
import Register from "./Register";
import Base from "./Base";

function Landing() {
    const [authenticated, setAuthenticated] = useState(false);

    useEffect(() => {
        async function isAuthenticated() {            
            var token = localStorage.getItem("token");            
            if (token) {
                setAuthenticated(true);
            } 
        }
        isAuthenticated();
    }, []);
   
    return (
        <>
        { authenticated ? <Base /> : 
        (
            <>
            <Login />
            {/* <LoginGoogleButton /> */}
            <Register />
            </>
        )}
        
        </>
    )
}

export default Landing