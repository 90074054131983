import React, {useState, useEffect} from "react";
import { Menu, Image } from "semantic-ui-react";
import AirbnbReviews from "./AirbnbReviews";
import VRBOReviews from "./VRBOReviews";
import { getGetHeaders } from "../utils/headers";
import { AuthContext } from "../context/auth";
import { useContext } from "react";

import MessageOnBoarding from "../common/OnBoardingMessage";


// updatePage is a callback to the parent to enable navigating from the initally hidden 
// DesignShareable component to the Binder listing page
export default function ReviewsLandingPage({updatePage}) {
    const [activeItem, setActiveItem] = useState("airbnb");
    const [hasProperties, setHasProperties] = useState(false);

    const handleItemClick = (item, event) => {                   
        setActiveItem(item)      
    } 

    useEffect(() => {
        async function getProperties() {    
            let headers = getGetHeaders();                           
            const response = await fetch(`${process.env.REACT_APP_BE_BASE_URL}/api/get_properties/`, 
                {headers})                                                     
            const body = await response.json()                
            if (body.length > 0) {
                setHasProperties(true);
            }            
        }  
        getProperties();              
    } , []);


    return (    
        <>   
        {hasProperties ? null : <MessageOnBoarding header="No reviews found" message="Navigate to listings to refresh reviews" />     }
        {hasProperties && 
            <Menu icon='labeled' >
            <Menu.Item
            name='airbnb'
            active={activeItem === 'airbnb'}
            onClick={() => handleItemClick('airbnb')}
            >
            <Image
                size='mini'
                src='/airbnb.png'
                />
            </Menu.Item>

            <Menu.Item
            name='vrbo'
            active={activeItem === 'vrbo'}
            onClick={() => handleItemClick('vrbo')}
            tooltip="VRBO"
            >
            <Image      
            alt='VRBO'                               
            src='/vrbov_c_5.png'
            size='mini' circular
            />                
            </Menu.Item>                
        </Menu>     
}       
        {hasProperties && activeItem === "airbnb" ? <AirbnbReviews updatePage={updatePage}/> : (hasProperties && activeItem === "vrbo" ? <VRBOReviews updatePage={updatePage}/> : null)}    
        
    
        </>
    )
}
            

{/* <MessageOnBoarding header="No reviews found" message="Navigate to listings to refresh reviews" /> */}