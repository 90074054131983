import React, { useEffect, useState, useContext } from "react";
import { FilePond, registerPlugin } from "react-filepond";
import { AuthContext } from "../context/auth";
// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import the Image EXIF Orientation and Image Preview plugins
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

function FPDropMulti({listingId, handleImageCallback}) {
    const { user } = useContext(AuthContext);
    const [files, setFiles] = useState([]);
    const [errors, setErrors] = useState({});
   
    // debugger;
    let headers = {};
    let token = localStorage.getItem('token')
    if (token) {
        headers["Authorization"] = `Bearer ${token}`;              
        headers["user"] = localStorage.getItem('user');  
        headers["listingId"] = listingId;      
    }
    const server_obj = {
        server: `${process.env.REACT_APP_BE_BASE_URL}`,
        process: {
            url: `${process.env.REACT_APP_BE_BASE_URL}/api/upload_listing_pics/`,            
                headers: headers,
                method: "POST",
                onload : (event) => {                    
                    handleImageCallback(event);
                }                     
        },
        fetch: '/api/fetch_property_pic',          
    };
  return (
    <>
    <div className="App">
            <FilePond
                files={files}
                onupdatefiles={setFiles}
                allowMultiple={true}
                maxFiles={3}
                server={server_obj}
                name="files"
                labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
            />
    </div>
        {Object.keys(errors).length > 0 && (
            <div className="ui error message">
                <ul className="list">
                    {Object.values(errors).map((value) => (
                        <li key={value}>{value}</li>
                    ))}
                </ul>
            </div>
        )}
        </>
  );
}

export default FPDropMulti;
