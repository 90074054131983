import React, {useState} from "react";
import { Grid, Menu, Container, Button } from "semantic-ui-react";
import Login from "../components/LoginOut/Login";
import Register from "./Register";
import MainPitch from "../components/MainPitch";
// import Features from "../components/Features";
import PricingPage from "./PricingPage";
import Insights from "../components/Insights";
import PrivacyPolicy from "../components/PrivacyPolicy";
// import EarlyAccess from "../components/EarlyAccess";



export default function Pitch() {
    const [showSignUp, setShowSignUp] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
    // const [showFeatures, setShowFeatures] = useState(false);
    const [showPricing, setShowPricing] = useState(false);
    const [showInsights, setShowInsights] = useState(false);
    const [showPrivacy, setShowPrivacy] = useState(false);

    function handleSignUp(e) {
        e.preventDefault();
        setShowSignUp(true);
        setShowLogin(false);
        // setShowFeatures(false);
        setShowPricing(false);
        setShowInsights(false);
        setShowPrivacy(false);
    }

    function handleLogin(e) {
        e.preventDefault();
        setShowLogin(true);
        setShowSignUp(false);
        // setShowFeatures(false);
        setShowPricing(false);
        setShowInsights(false);
        setShowPrivacy(false);
    }

    function handleCallback(e) {
        e.preventDefault();        
        setShowSignUp(false);
        setShowLogin(true);
        // setShowFeatures(false);
        setShowPricing(false);
        setShowInsights(false);
        setShowPrivacy(false);
    }

    function backToHome(e) {
        e.preventDefault();
        setShowSignUp(false);
        setShowLogin(false);
        // setShowFeatures(false);
        setShowPricing(false);
        setShowInsights(false);
        setShowPrivacy(false);
    }

    function handleShowPricing(e) {
        e.preventDefault();
        setShowPricing(true);
        setShowSignUp(false);
        setShowLogin(false);
        // setShowFeatures(false);
        setShowInsights(false);
        setShowPrivacy(false);
    }

    function handleShowInsights(e) {
        e.preventDefault();        
        setShowSignUp(false);
        setShowLogin(false);
        // setShowFeatures(false);
        setShowPricing(false);
        setShowInsights(true);
        setShowPrivacy(false);
    }

    function handleShowPrivacy(e) {
        e.preventDefault();
        setShowSignUp(false);
        setShowLogin(false);        
        setShowPricing(false);
        setShowInsights(false);
        setShowPrivacy(true);
    }
    
    
    return (
        <>       
        <Grid centered columns={1} width={16}>
            <Grid.Column>
                <div className=" items-center justify-between py-6 md:justify-start md:space-x-10 bg-gradient-to-r from-cyan-500 to-blue-500">                
                    <Menu stackable inverted  secondary size="large">
                        <Container>
                            {/* <Menu.Item as="a" active onClick={backToHome}>
                            OwnerGuest
                            </Menu.Item> */}
                            <div className="flex-shrink-0 self-center">
                            <img
                                className="h-12"
                                src="/images/ogtext_white.png"
                                alt="OwnerGuest"
                                onClick={backToHome}
                                style={{cursor: "pointer"}}
                            />
                            </div>
                            {/* <Menu.Item as="a" onClick={handleShowFeatures}>Features</Menu.Item> */}
                            <Menu.Item style={{fontSize:"1.4rem"}} href="/pricing" as="a" onClick={(e)=>handleShowPricing(e)}>Pricing</Menu.Item>
                            <Menu.Item style={{fontSize:"1.4rem"}} href="/insights" as="a" onClick={(e)=>handleShowInsights(e)}>Insights</Menu.Item>
                            <Menu.Item style={{fontSize:"1.4rem"}} href="https://blog.ownerguest.com/" as="a">Blog</Menu.Item>
                            <Menu.Item style={{fontSize:"1.4rem"}} href="/login" position="right" as="a" onClick={(e)=>handleLogin(e)}>Login</Menu.Item>
                            <Menu.Item>
                                <Button href="/signup" color="orange" className="rounded-lg bg-orange-400" onClick={(e)=>handleSignUp(e)}>Sign Up</Button>
                            </Menu.Item>            
                        </Container>
                    </Menu>
                </div>
            </Grid.Column>
        </Grid> 

        {/* {showPricing ? <PricingPage /> : (
            <></>
        )} */}
        { showLogin && !showSignUp ? (
            <Login setShowLogin={setShowLogin} setShowSignUp={setShowSignUp}/>
        ) : (

        <>
        {showSignUp ? <Register handleCallback={handleCallback}/> : (             
            showPricing ? <PricingPage /> : (
                showInsights ? <Insights backToHome={backToHome} handleSignUp={handleSignUp}/> : (
                    showPrivacy ? <PrivacyPolicy backToHome={backToHome} handleSignUp={handleSignUp}/> : (
                        <MainPitch handleSignUp={handleSignUp}/>
                    )
                )
            )
        )}
        <Grid stackable centered columns={1} width={16}>
            <Grid.Row>
                
            </Grid.Row>
            <Grid.Row>
            <Grid.Column>
                <div className=" items-center justify-between py-6 md:justify-start md:space-x-10 bg-gradient-to-r from-cyan-500 to-blue-500">                
                    <Menu stackable inverted  secondary size="large">
                        <Container>
                            <Menu.Item>
                            OwnerGuest Copyright 2023
                            </Menu.Item>
                            <Menu.Item as="a" href="/privacy" onClick={(e)=>handleShowPrivacy(e)}>Privacy</Menu.Item>
                            {/* <Menu.Item as="a">Terms of Service</Menu.Item>
                            <Menu.Item as="a">Insights</Menu.Item> */}
                            <Menu.Item position="right">
                                <a href="https://twitter.com/OwnerGuestApp" target='new'>Twitter</a>
                            </Menu.Item>
                            <Menu.Item>
                                <a href="https://www.pinterest.com/ownerguest" target='new'>Pinterest</a>
                            </Menu.Item>            
                        </Container>
                    </Menu>
                </div>
            </Grid.Column>
            </Grid.Row>
        </Grid>
       
        <div className="flex items-center h-2">
       
        </div>
        </>
        )};
        </>
    )
}

