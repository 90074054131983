import React from 'react';
import { Segment, Radio } from 'semantic-ui-react'
import ReactSlider from "react-slider";


export default function DesignImagePosition({
    currentYImagePositionValue, 
    currentXImagePositionValue, 
    currentIncrementImageWidthValue,
    objectFitConfig,
    handleImagePositionChange}) {

    function handleXImagePositionChange(value) {
        handleImagePositionChange(value, currentYImagePositionValue, currentIncrementImageWidthValue, objectFitConfig); 
    }

    function handleYImagePositionChange(value) {
        handleImagePositionChange(currentXImagePositionValue, value, currentIncrementImageWidthValue, objectFitConfig);
    }

    function handleImageWidthChange(value) {
        handleImagePositionChange(currentXImagePositionValue, currentYImagePositionValue, value, objectFitConfig);
    }

    function handleObjectFitChange(value) {        
        handleImagePositionChange(currentXImagePositionValue, currentYImagePositionValue, currentIncrementImageWidthValue, value);
    }

    return (
        <Segment.Group style={{display:"table-caption"}}>             
            <Segment>        
                <label className="pl-8 font-medium">Image Up/Down</label>        
                <ReactSlider                                
                    className="w-[18rem] mx-auto mt-2 mb-2 h-6 bg-gray-400 opacity-70 rounded-lg max-w-md "
                    trackClassName="h-6 bg-gray-200 text-black background-color: rgba(0,0,0,0.3) rounded-lg "
                    thumbClassName="w-6 h-6 bg-white border-2 border-gray-400 rounded-full shadow-md outline-none cursor-pointer]  "                            
                    defaultValue={0}
                    max={60}
                    min={-60}
                    step={1}                            
                    ariaLabel="Image Position"
                    value={currentYImagePositionValue}
                    onChange={(value) => handleYImagePositionChange(-value)}
                />                  
                <label className="pl-8 font-medium">Image Left/Right</label>
                <ReactSlider                                
                    className="w-[18rem] mx-auto mt-2 mb-2 h-6 bg-gray-400 opacity-70 rounded-lg max-w-md "
                    trackClassName="h-6 bg-gray-200 text-black background-color: rgba(0,0,0,0.3) rounded-lg "
                    thumbClassName="w-6 h-6 bg-white border-2 border-gray-400 rounded-full shadow-md outline-none cursor-pointer]  "
                    defaultValue={0}
                    max={20}
                    min={-20}                            
                    step={1}                            
                    ariaLabel="Image Position"
                    value={currentXImagePositionValue}
                    onChange={(value) => handleXImagePositionChange(value)}
                />  
                <label className="pl-8 font-medium">Image Width</label>
                <ReactSlider                                
                    className="w-[18rem] mx-auto mt-2 mb-2 h-6 bg-gray-400 opacity-70 rounded-lg max-w-md "
                    trackClassName="h-6 bg-gray-200 text-black background-color: rgba(0,0,0,0.3) rounded-lg "
                    thumbClassName="w-6 h-6 bg-white border-2 border-gray-400 rounded-full shadow-md outline-none cursor-pointer]  "
                    defaultValue={0}
                    max={600}
                    min={-600}
                    step={10}                            
                    ariaLabel="Image Width"
                    value={currentIncrementImageWidthValue}
                    onChange={(value) => handleImageWidthChange(value)}
                />  
                <Radio
                    toggle
                    checked={objectFitConfig==="none"}
                    label='Fit'
                    className="p-4 pl-6 font-medium"                                                                                                
                    onChange={() => handleObjectFitChange('none')}
                />                                   
            </Segment>
        </Segment.Group>
    )
}