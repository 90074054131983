import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Container, Grid, Menu, Segment, Header, Button, Form } from 'semantic-ui-react';
import { useForm } from '../util/hooks';
import { getPostHeaders } from '../utils/headers';

function UpdatePassword() {        
    const { token } = useParams();    
    const [errors, setErrors] = useState({});      
    const [buttonLoading, setButtonLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const { onChange, onSubmit, values } = useForm(handleUpdatePassword, {
        password: '',

    });
      
    let navigate = useNavigate();

    async function handleUpdatePassword() {
        setErrors({});        
        setButtonLoading(true);
        if (values.email === '') {
            setErrors({"email": "Enter email"});
            return;
        }        
        let headers = getPostHeaders({password: values.password, token: token});        
        const response = await fetch(`${process.env.REACT_APP_BE_BASE_URL}/api/password_reset/confirm/`,
            {...headers})
        
        await response.json().then(() => {
            const statusText=response.statusText;
            if (response.status !== 200) {
                setErrors({"password": statusText})
            } 
            else {                
                setSuccess(true);  
            }         
        })
        setButtonLoading(false);                
    }

    const handleClick = () => {
        navigate("/");
    }

    return (
        <>
        <Grid centered columns={1} width={16}>
            <Grid.Column>
                <div className=" items-center justify-between py-6 md:justify-start md:space-x-10 bg-gradient-to-r from-cyan-500 to-blue-500">                
                    <Menu stackable inverted  secondary size="large">
                        <Container>
                            <div className="flex-shrink-0">
                            <img
                                className="h-8"
                                src="/images/ogtext_white.png"
                                alt="OwnerGuest"
                                onClick={handleClick}
                                style={{cursor: "pointer"}}
                            />
                            </div>
                            {/* <Menu.Item as="a" onClick={handleClick} active>
                            OwnerGuest
                            </Menu.Item> */}
                            {/* <Menu.Item as="a" onClick={handleShowFeatures}>Features</Menu.Item> */}
                            {/* <Menu.Item as="a">Pricing</Menu.Item>
                            <Menu.Item as="a">Insights</Menu.Item>
                            <Menu.Item position="right" as="a" onClick={handleLogin}>
                                Login
                            </Menu.Item>
                            <Menu.Item>
                                <Button color="orange" className="rounded-lg bg-orange-400" onClick={handleSignUp}>Sign Up</Button>
                            </Menu.Item>             */}
                        </Container>
                    </Menu>
                </div>
            </Grid.Column>
        </Grid> 

            <Container>
                <Segment.Group></Segment.Group>
                <Segment.Group>
                    <Segment>
                        <Header as="h3">
                            Password Reset
                        </Header>
                        
                            <Form onSubmit={onSubmit} noValidate >
                            <Form.Input                                
                                    label="New Password"
                                    placeholder="New password.."
                                    name="password"
                                    type="password"
                                    value={values.password}
                                    error={errors.password? true : false}
                                    onChange={onChange}
                                /> 
                                
                                                                
                                <Button loading={buttonLoading} type="submit" primary >
                                    Update
                                </Button>
                            </Form>                                                    
                    </Segment>
                </Segment.Group>
                
            {Object.keys(errors).length > 0 && (
                <div className="ui message">
                    <ul className="list">
                        {Object.values(errors).map((value) => (                            
                            <li key={value}>{value}</li>                                                        
                        ))}
                    </ul>
                </div>
            )}
            {success && (
                <div className="ui message">
                    <ul className="list">
                        <li>Success! Please <a href="/">login</a></li>
                    </ul>
                </div>
            )}
            
        </Container>
        </>
    )
}


export default UpdatePassword;