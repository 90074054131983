import React, {useState, useEffect} from "react";
import { Card, Icon, Grid, Portal, Segment } from "semantic-ui-react";
import DesignTextPosition from "../components/design/DesignTextPosition";
import DesignTextStyle from "../components/design/DesignTextStyle";

import DesignText from "../components/design/DesignText";
import PictureSelector from "../components/PictureSelector";
import DesignGradientStyle from "../components/design/DesignGradientStyle";

import DesignImage from "../components/design/DesignImage";
import DesignImagePosition from "../components/design/DesignImagePosition";
import DesignImageStyle from "../components/design/DesignImageStyle";
import SaveToBinderButton from "../components/buttons/SaveToBinderButton";
import DownloadAsset from "../components/buttons/DownloadAsset";
import DesignFlair from "../components/design/DesignFlair";
import FlairObject from "../components/design/FlairObject";
// import DragMove from "../util/DragMove";

export default function DesignStickyBase({listingId, postPurpose, selectedPostSize, updatePage, review, highlight, handleShareableCallback}) {
    const [open, setOpen] = useState(false);
    
    const [txtPositionIcon, setTxtPositionIcon] = useState("grey");
    const [txtStyleIcon, setTxtStyleIcon] = useState("grey");
    const [gradientStyleIcon, setGradientStyleIcon] = useState("grey");
    const [imagePosIcon, setImagePosIcon] = useState("grey");
    const [imageStyleIcon, setImageStyleIcon] = useState("grey");
    const [imageSaveIcon, setImageSaveIcon] = useState("blue");
    const [imageFlairIcon, setImageFlairIcon] = useState("grey");
    // const [imageSaveIconTest, setImageSaveIconTest] = useState("red");
    const [imageDownloadIcon, setImageDownloadIcon] = useState("blue");

    const [gradient, setGradient] = useState('bg-gradient-to-r from-cyan-500 to-blue-500');        
    const [shareable, setShareable] = useState(null);    
    const [selectedPicture, setSelectedPicture] = useState(null);
    const [currentXTextPositionValue, setCurrentXTextPositionValue] = useState(-50);
    const [currentYTextPositionValue, setCurrentYTextPositionValue] = useState(-60);
    const [currentTextWidthValue, setCurrentTextWidthValue] = useState(50);
    const [currentIncrementImageWidthValue, setCurrentIncrementImageWidthValue] = useState(0);
    const [selectedFontSize, setSelectedFontSize] = useState("text-2xl text-white");
    const [currentOpacityValue, setCurrentOpacityValue] = useState(70);
    const [currentXImagePositionValue, setCurrentXImagePositionValue] = useState(0);
    const [currentYImagePositionValue, setCurrentYImagePositionValue] = useState(0);
    const [currentPaddingValue, setCurrentPaddingValue] = useState(0);
    const [currentRadiusValue, setCurrentRadiusValue] = useState(4);
    const [currentShadowValue, setCurrentShadowValue] = useState(14);
    const [currentBlurValue, setCurrentBlurValue] = useState(0);
    const [currentSepiaValue, setCurrentSepiaValue] = useState(0);
    const [currentGrayScaleValue, setCurrentGrayScaleValue] = useState(0);
    const [objectFitConfig, setObjectFitConfig] = useState("cover");
    const [currentFlair, setCurrentFlair] = useState("none");

    const floatMenuStyle = {
        right: '0%',
        position: 'fixed',
        top: window.innerHeight/3,
        zIndex: 1000,
        opacity: 0.9,
    }

    const iconStyle = {
        fontSize: '1.5em',
        outline: 'none',
    }

    // const [translate, setTranslate] = useState({
    //     x: 0,
    //     y: 0
    //   });
    
    const printRef = React.useRef();

    const WIDTHINDEX = 0
    const HEIGHTINDEX = 1

    // const handleDragMove = (e) => {
    //     setTranslate({
    //       x: translate.x + e.movementX,
    //       y: translate.y + e.movementY
    //     });
    //   };
    

    const handleOpenClose = (selection) => {
        setOpen(true);   
        switch (selection) {
            case "textPosition":                
                txtPositionIcon === "grey" ? setTxtPositionIcon("green") : setTxtPositionIcon("grey");
                break;
            case "textStyle":
                txtStyleIcon === "grey" ? setTxtStyleIcon("green") : setTxtStyleIcon("grey");
                break;
            case "gradientStyle":
                gradientStyleIcon === "grey" ? setGradientStyleIcon("green") : setGradientStyleIcon("grey");
                break;
            case "imagePosition":
                imagePosIcon === "grey" ? setImagePosIcon("green") : setImagePosIcon("grey");
                break;
            case "imageStyle":
                imageStyleIcon === "grey" ? setImageStyleIcon("green") : setImageStyleIcon("grey");
                break;
            case "flair":
              imageFlairIcon === "grey" ? setImageFlairIcon("green") : setImageFlairIcon("grey");
              break;
            case "imageSave":
                setImageSaveIcon("green" );
                break;

            default:
                break;
        }
    }
    
    useEffect(() => {        
        async function fetchData() {            
            setShareable(review);
            setSelectedPicture(review.user_property.image_url)
            // setLoading(false);            
        }
        fetchData();     
        let parent = document.getElementById('sticky-element').parentElement;        
        while (parent) {            
            parent = parent.parentElement;
        }   
    }, []);


    function handlePanelSizing() {   
        // console.log(selectedPostSize);    
        const {width, height} = getPanelFit();
        // console.log(`handlePanelSizing: ${width} ${height}`);
        
        // return {position:'sticky', padding: '3%', top:'0px', zIndex:'998', width:'auto', height:'auto'}
        return {position:'sticky', padding: '3%', top:'0px', zIndex:'998', width:`${width}px`, height:`${height}px`}        
    }


    function handleInlinePanelStyle() {        
        return `overflow-hidden ${gradient}`
    }

    function getPanelFit() {     
        // debugger;   
        var viewableWidth = window.innerWidth; 
        var factor = viewableWidth / selectedPostSize[WIDTHINDEX];
        // console.log(`window inner width: ${window.innerWidth}`)     
        if (factor > 1) factor = 0.9; // don't let it get bigger than the original size
        var width = (selectedPostSize[WIDTHINDEX] * factor) * .9; // * ratio;
        var height = (selectedPostSize[HEIGHTINDEX] * factor)  * .9; // * ratio;        
        // console.log(`getPanelFit: ${width} ${height}`)        
        return {width, height}
    }

    function handleInlineTextStyle() {   
        // console.log(currentOpacityValue);     
        return {
            width: `${currentTextWidthValue}%`,
            // width: `${textWidth}px`,
            opacity: `${currentOpacityValue}%`,
            transform: `translate(${currentXTextPositionValue}%, ${currentYTextPositionValue}%)`,
        }        
    }    

    function handleTextChange(xPos, yPos, width) {
        if (xPos) setCurrentXTextPositionValue(xPos);
        if (yPos) setCurrentYTextPositionValue(yPos);
        if (width) setCurrentTextWidthValue(width);        
    }

    
    function handleTextStyleChange(opacity, fontSize) {        
        setCurrentOpacityValue(opacity);
        setSelectedFontSize(fontSize);
    }

    function handlePictureChange(imgSrc) {
        setSelectedPicture(imgSrc);
    }

    function handleGradientColorChange(color) {        
        setGradient(color);
    }

    function handleImagePositionChange(xPos, yPos, incrementWidth, objectFit) {
        setCurrentXImagePositionValue(xPos);
        setCurrentYImagePositionValue(yPos);
        setCurrentIncrementImageWidthValue(incrementWidth);        
        if (objectFitConfig === 'none') {
            setObjectFitConfig('contain');
        } else {            
            setObjectFitConfig(objectFit);
        }
        
    }

    function handleImageStyleChange(padding, radius, shadow, blur) {
        setCurrentPaddingValue(padding);
        setCurrentRadiusValue(radius);
        setCurrentShadowValue(shadow);                   
        setCurrentBlurValue(blur);
    }

    function handleImageFilterChange(currentFilter, value) {        
        if (currentFilter === "sepia") {
            setCurrentSepiaValue(value);
            setCurrentGrayScaleValue(0);
        } else if (currentFilter === "grayscale") {
            setCurrentGrayScaleValue(value);
            setCurrentSepiaValue(0);
        } else {
            setCurrentGrayScaleValue(0);
            setCurrentSepiaValue(0);            
        }
    }

    function handleFlairChange(flair) {
        setCurrentFlair(flair);
    }

  return (
    <Grid columns={1}  textAlign='center'>
        <Grid.Row>
        <Grid columns={16} doubling={true}  textAlign='center'>
        <Grid.Column>
          <Portal
            closeOnTriggerClick
            openOnTriggerClick
            // sm:text-lg md:text-xl lg:text-2xl xl:text-3xl
            trigger={<Icon circular inverted style={iconStyle} name="text width" color={txtPositionIcon}/>}
            onOpen={()=>handleOpenClose("textPosition")}
            onClose={()=>handleOpenClose("textPosition")}
          >              
            <Segment
                style={floatMenuStyle}
            >                                  
            <DesignTextPosition 
                handleTextChange={handleTextChange}
                currentXTextPositionValue={currentXTextPositionValue}
                currentYTextPositionValue={currentYTextPositionValue}
                currentTextWidthValue={currentTextWidthValue}                
                />                
            </Segment>            
          </Portal> 
          </Grid.Column>          
          <Grid.Column>
          <Portal
            closeOnTriggerClick
            openOnTriggerClick
            trigger={                
                <Icon circular inverted style={iconStyle} name="text height" color={txtStyleIcon} />
            }
            onOpen={()=>handleOpenClose("textStyle")}
            onClose={()=>handleOpenClose("textStyle")}
          >
             <Segment
              style={floatMenuStyle}
            >             
              <DesignTextStyle 
                handleTextStyleChange={handleTextStyleChange}
                currentOpacityValue={currentOpacityValue}
                selectedFontSize={selectedFontSize}
              />
            </Segment>
          </Portal>          
        </Grid.Column>
        <Grid.Column>
          <Portal
            closeOnTriggerClick
            openOnTriggerClick
            trigger={<Icon circular inverted  style={iconStyle} name="tint" color={gradientStyleIcon} />}
            onOpen={()=>handleOpenClose("gradientStyle")}
            onClose={()=>handleOpenClose("gradientStyle")}
          >
             <Segment
              style={floatMenuStyle}
            >             
              <DesignGradientStyle 
                handleGradientColorChange={handleGradientColorChange}
                currentGradientValue={gradient}                
              />
            </Segment>
          </Portal>          
        </Grid.Column>
        <Grid.Column>
          <Portal
            closeOnTriggerClick
            openOnTriggerClick
            trigger={            
              <Icon.Group style={iconStyle}  color={imagePosIcon}>
              <Icon circular inverted  name='image outline' style={{outline:"none"}} color={imagePosIcon} />
              <Icon corner  name='arrows alternate' color={imagePosIcon} style={{fontSize:".8em"}}/>
            </Icon.Group>       
            }
            onOpen={()=>handleOpenClose("imagePosition")}
            onClose={()=>handleOpenClose("imagePosition")}
          >
             <Segment
              style={floatMenuStyle}
            >                            
              <DesignImagePosition
                handleImagePositionChange={handleImagePositionChange}
                currentXImagePositionValue={currentXImagePositionValue}
                currentYImagePositionValue={currentYImagePositionValue}
                currentIncrementImageWidthValue={currentIncrementImageWidthValue}
                objectFitConfig={objectFitConfig}
              />
            </Segment>
          </Portal>          
        </Grid.Column>
        <Grid.Column>
          <Portal
            closeOnTriggerClick
            openOnTriggerClick
            trigger={            
              <Icon.Group  style={iconStyle}  color={imageStyleIcon} >
              <Icon circular inverted  name='image outline' style={{outline:"none"}} color={imageStyleIcon}  />
              <Icon size="large" corner name='pencil alternate' color={imageStyleIcon} style={{fontSize:".8em"}} />
            </Icon.Group>       
            }
            onOpen={()=>handleOpenClose("imageStyle")}
            onClose={()=>handleOpenClose("imageStyle")}
          >
            <Segment style={floatMenuStyle}>                            
              <DesignImageStyle
                handleImageStyleChange={handleImageStyleChange}
                handleImageFilterChange={handleImageFilterChange}
                currentPaddingValue={currentPaddingValue}
                currentRadiusValue={currentRadiusValue}
                currentShadowValue={currentShadowValue}
                currentBlurValue={currentBlurValue}
                currentGrayScaleValue={currentGrayScaleValue}
                currentSepiaValue={currentSepiaValue}
              />
            </Segment>
          </Portal>          
        </Grid.Column>  
        
        {/* <Grid.Column>
          <Portal
            closeOnTriggerClick
            openOnTriggerClick            
            trigger={<Icon circular inverted style={iconStyle} name="star outline" color={imageFlairIcon}/>}
            onOpen={()=>handleOpenClose("flair")}
            onClose={()=>handleOpenClose("flair")}
          >              
            <Segment
                style={floatMenuStyle}
            >                                  
            <DesignFlair 
              handleFlairChange={handleFlairChange}
              currentFlair={currentFlair}
            />                
            </Segment>            
          </Portal> 
          </Grid.Column>        */}
        <Grid.Column>
          <Portal
            closeOnTriggerClick
            openOnTriggerClick
            trigger={                          
                <SaveToBinderButton 
                    imageSaveIcon={imageSaveIcon}
                    shareable={shareable}
                    postPurpose={postPurpose}                    
                    printRef={printRef} 
                    selectedPostSize={selectedPostSize}
                    updatePage={updatePage}
                    iconStyle={iconStyle}
                    useTest={false}
                />              
            }
            onOpen={()=>handleOpenClose("SaveIcon")}
            onClose={()=>handleOpenClose("SaveIcon")}
          >
            <Segment style={floatMenuStyle}>                                          
            </Segment>
          </Portal>          
        </Grid.Column>
        {/* <Grid.Column>
          <Portal
            closeOnTriggerClick
            openOnTriggerClick
            trigger={                          
                <SaveToBinderButton 
                    imageSaveIcon={imageSaveIconTest}
                    shareable={shareable}
                    postPurpose={postPurpose}                    
                    printRef={printRef} 
                    selectedPostSize={selectedPostSize}
                    updatePage={updatePage}
                    iconStyle={iconStyle}
                    useTest={true}
                />              
            }
            onOpen={()=>handleOpenClose("SaveIcon")}
            onClose={()=>handleOpenClose("SaveIcon")}
          >
            <Segment style={floatMenuStyle}>                                          
            </Segment>
          </Portal>          
        </Grid.Column> */}
        <Grid.Column>
          <Portal
            closeOnTriggerClick
            openOnTriggerClick
            trigger={                          
                <DownloadAsset
                    printRef={printRef}
                    imageDownloadIcon={imageDownloadIcon}
                    iconStyle={iconStyle}
                     />
            }
            // onOpen={()=>handleOpenClose("SaveIcon")}
            // onClose={()=>handleOpenClose("SaveIcon")}
          >
            <Segment style={floatMenuStyle}>                                          
            </Segment>
          </Portal>          
        </Grid.Column>
        </Grid>          
        </Grid.Row>
        <Grid.Row>        
        <Grid.Column  width={15}>
          <Segment.Group>
            <Segment>                                    
                <Card fluid centered style={{position:'sticky', zIndex:'998', alignItems:"center", boxShadow:"none"}}> 
                <div 
                    id='sticky-element' 
                    ref={printRef} 
                    style={handlePanelSizing()} 
                    className={handleInlinePanelStyle()}>   
                    <DesignImage 
                        postWidth={selectedPostSize[WIDTHINDEX]}
                        postHeight={selectedPostSize[HEIGHTINDEX]}
                        currentIncrementImageWidthValue={currentIncrementImageWidthValue}                        
                        currentXImagePositionValue={currentXImagePositionValue}
                        currentYImagePositionValue={currentYImagePositionValue}
                        currentPaddingValue={currentPaddingValue}
                        currentRadiusValue={currentRadiusValue}
                        currentShadowValue={currentShadowValue}
                        currentBlurValue={currentBlurValue}
                        currentSepiaValue={currentSepiaValue}
                        currentGrayScaleValue={currentGrayScaleValue}
                        objectFitConfig={objectFitConfig}
                        selectedPicture={selectedPicture} 
                        flair={currentFlair}
                        shareable={shareable}/>  
                    <FlairObject
                            flair={currentFlair}
                            shareable={shareable}
                          /> 
                    {shareable && shareable.review ? (                       
                        <DesignText 
                            highlight={highlight}
                            selectedFontSize={selectedFontSize}
                            shareable={shareable}
                            handleInlineTextStyle={handleInlineTextStyle} />                                                                     
                    ):(<>Loading...</>)}                                                                                                                                                                               
                </div>  
                </Card>                
            </Segment>
            <Segment secondary>
                <Card fluid centered>
                    <PictureSelector listingId={listingId} handlePictureChange={handlePictureChange}/>
                </Card>
            </Segment>
          </Segment.Group>
        </Grid.Column>
        </Grid.Row>
      </Grid>
  );
}