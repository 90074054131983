import React, { useState } from 'react';
import axios from 'axios';

import { Container, Segment, Header, Button, Form } from 'semantic-ui-react';
import { Badge } from 'reactstrap';
import { useForm } from '../util/hooks';


function Register({handleCallback}) {    
    // const context = useContext(AuthContext);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const { onChange, onSubmit, values } = useForm(registerUser, {
        email: '',
        password: '',
        confirmPassword: '',
    });


    
    async function createUser() {     
        setLoading(true);     
        await axios.post(
            `${process.env.REACT_APP_BE_BASE_URL}/api/register_user/`,
          { 
            email: values.email,
            password: values.password,                          
          }        
        ).catch((result) => {
            setErrors(result.response.data.error);
            setLoading(false);
        })
        .then((result) => {            
            setSuccess(true);
            setLoading(false);
        })      
      }

    function registerUser() {
        createUser();
    }
    // disabled={!performValidation()}
    function performValidation() {         
        if (!values.email.includes("@")) {
            // setErrors({ email: "Email must contain @" });
            return false;
        }       
        if (!values.email.includes(".com")) {
            // setErrors({ email: "Email must contain @" });
            return false;
        }   
        if (values.password.length < 8) {
            // setErrors({ password: "Password must be at least 8 characters long" });
            return false;
        }
        if (values.password !== values.confirmPassword) {
            // setErrors({ confirmPassword: "Passwords do not match" });
            return false;
        }
        if (values.email.length < 1) {
            // setErrors({ email: "Email is required" });
            return false;
        }
        return values.email.length > 0 && values.password.length > 0;
    }

    function getErrorMessage() { 
        if (!values.email.includes("@") || values.email.length < 1) {
            setErrors({ email: "Email must not be empty and contain @" });             
            // return true;
        }       
        if (values.password.length < 8) {
            setErrors({ password: "Password must be at least 8 characters long" });            
            // return true;
        }
        if (values.password !== values.confirmPassword) {
            setErrors({ confirmPassword: "Passwords do not match" });            
            // return true;
        }
        // return false;
        
    }

    return (
            <Container className='p-4 m-4'>                
                <Segment.Group>
                    <Segment >
                        <Header as="h3">
                            Create an account
                        </Header>
                        <Form onSubmit={onSubmit} noValidate className={loading ? 'loading' : ''}>
                            <Form.Input
                                label="Email"
                                placeholder="Email.."
                                name="email"
                                type="text"
                                value={values.email}
                                error={errors.email? true : false}
                                onChange={onChange}
                            />
                            {errors ? (<p><Badge color="danger">{errors.email}</Badge></p>
                                                    ) : <p></p>
                            } 
                            <Form.Input
                                label="Password"
                                placeholder="Password.."
                                name="password"
                                type="password"
                                value={values.password}
                                error={errors?.password? true : false}
                                onChange={onChange}
                            />
                            <Form.Input
                                label="Confirm Password"
                                placeholder="Confirm Password.."
                                name="confirmPassword"
                                type="password"
                                value={values.confirmPassword}
                                error={errors?.confirmPassword? true : false}
                                onChange={onChange}
                            />  
                            {errors ? (<p><Badge color="danger">{errors.password}</Badge></p>
                                                    ) : <p></p>
                            }               
                            <Button type="submit" primary disabled={!performValidation()} >
                                Register
                            </Button>
                        </Form>
                    </Segment>
                </Segment.Group>
            {Object.keys(errors).length > 0 && (
                <div className="ui error message">
                    {errors}
                    {/* <ul className="list">
                        {Object.values(errors).map((value) => (
                            <li key={value}>{value}</li>
                        ))}
                    </ul> */}
                </div>
            )}
            {success && (
                <div className="text-4xl">
                    Success! You can now <span className="text-blue-400 font-bold" ><a href="/" onClick={handleCallback}>login</a></span>                    
                </div>
            )}
            
        </Container>
    )
}


export default Register;