import React, {useState, useEffect} from "react";
import { Button, Radio, Card, Icon } from 'semantic-ui-react'
import ReactSlider from "react-slider";
import * as htmlToImage from 'html-to-image';
import {getPostHeaders} from "../utils/headers";
import { AuthContext } from '../context/auth';
import {Filesystem, Directory} from "@capacitor/filesystem";
import { isMobile } from "react-device-detect";
import Binder from "./Binder";

// This class will combine a review and property image to create a shareable jpg
// Options for styling which will come from the tailwind filters optins

export default function DesignShareable({updatePage, review, highlight, handleShareableCallback}) {
    const [shareable, setShareable] = useState(null);    
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(true)
    const [imageFilter, setImageFilter] = useState('');
    const [sepia, setSepia] = useState(0);
    const [grayscale, setGrayscale] = useState(0);
    const [currentPaddingValue, setCurrentPaddingValue] = useState(0);
    const [currentRadiusValue, setCurrentRadiusValue] = useState(4);
    const [currentShadowValue, setCurrentShadowValue] = useState(14);
    const [currentOpacityValue, setCurrentOpacityValue] = useState(70);
    const [currentXTextPositionValue, setCurrentXTextPositionValue] = useState(-50);
    const [currentYTextPositionValue, setCurrentYTextPositionValue] = useState(-18);
    const [currentTextWidthValue, setCurrentTextWidthValue] = useState(50);
    const [currentXImagePositionValue, setCurrentXImagePositionValue] = useState(0);
    const [currentYImagePositionValue, setCurrentYImagePositionValue] = useState(0);
    const [currentIncrementImageWidthValue, setCurrentIncrementImageWidthValue] = useState(0);
    const [objectFitConfig, setObjectFitConfig] = useState('cover');
    const [imageBlurValue, setImageBlurValue] = useState(0);    
    const [selectedShareSize, setSelectedShareSize] =  useState("twitterFeed");
    const [gradient, setGradient] = useState('bg-gradient-to-r from-cyan-500 to-blue-500');        
    const [buttonLoading, setButtonLoading] = useState(false);

    const printRef = React.useRef();

    const WIDTHINDEX = 0
    const HEIGHTINDEX = 1
    const PLATFORMSIZES = {
        "square": [500,500],
        "twitterFeed": [1200,675],
        "facebookFeed": [1200, 630],
        "pinterest": [1000, 1500],        
    } 
    const GRADIENTSELECTORSTYLE = "text-center font-bold p-2 min-w-full	 rounded-lg text-white shadow-lg ";

    const GRADIENTCOLORS = {
        "Cyan Blue": "bg-gradient-to-r from-cyan-500 to-blue-500",
        "Peachy Pink": "bg-gradient-to-r from-[#f26aae] to-[#fa9e9e]",
        "Purple Blue": "bg-gradient-to-r from-[#7f00ff] to-[#e100ff]",
        "Purple Pink": "bg-gradient-to-r from-[#fbb040] to-[#f9ed32]",
        "Green Mint": "bg-gradient-to-r from-[#00a1ff] to-[#00ff8f]",
        "Peach Yellow": "bg-gradient-to-r from-[#ee2a7b] to-[#ff7db8]",
        "Blue Blue" : "bg-gradient-to-r from-[#0085ff] to-[#0085ff4f]",
        "Pink Aqua": "bg-gradient-to-r from-[#ff00d4] to-[#e40c0cb3]",
        "Pink Yellow": "bg-gradient-to-r from-[#ef4136] to-[#fbb040]",
        "Deep Pint Mint": "bg-gradient-to-r from-[#2d388a] to-[#00aeef]",
        "Yellow Orange": "bg-gradient-to-r from-[#E3FF7A] to-[#E27C39]",
        "Deep Purple": "bg-gradient-to-r from-[#4B086D] to-[#ACC0FE]",
        "Dark Green": "bg-gradient-to-r from-[#087549] to-[#1fe42aa6]",
        "Dark": "bg-gradient-to-r from-[#000000] to-[#0a4071e3]",
        "Light": "bg-gradient-to-r from-[#ffffff] to-[#ffffff]",
    }

    const getColorSelectorClass = (color) => {
        return GRADIENTSELECTORSTYLE + GRADIENTCOLORS[color];
    }

    const handleGradientColorChange = (value) => {
        setGradient(GRADIENTCOLORS[value])        
    }
   

    async function handleBinder(dataUrl, bookUri, propertyId) {               
        const headers = getPostHeaders({dataUrl, bookUri, propertyId}, "POST");                  
        const response = await fetch(`${process.env.REACT_APP_BE_BASE_URL}/api/binders/`, 
            {...headers});
        if (response.status === 401) {            
            AuthContext.logout({});
        }            
        const body = await response.json().catch((error) => {
            setButtonLoading(false);            
            console.log('handleBinder error', error)
        });               
        setButtonLoading(false);            
        updatePage(<Binder/>)
        return body;
    }

    function saveBinderOnMobile(bookUri, propertyId) {
        // this is a hack. The first time the image is saved, it is not complete.
        // The second time it is saved, only text overlay is shown
        // Third time, all is good. This only happens the first time however.
        // New images are saved correctly.
        htmlToImage.toPng(printRef.current, {
            cacheBust: true, 
            quality: .75, 
            pixelRatio: 1,             
            style: {}, 
        }).then((dataUrl) => {
                htmlToImage.toPng(printRef.current, {
                    cacheBust: true, 
                    quality: .75, 
                    pixelRatio: 1,             
                    style: {}, 
                }).then((dataUrl) => {
                    htmlToImage.toPng(printRef.current, {
                        cacheBust: true, 
                        quality: .75, 
                        pixelRatio: 1,             
                        style: {}, 
                    }).then((dataUrl) => {
                        Filesystem.writeFile({
                            path: 'ownerguest.png',
                            data: dataUrl,
                            directory: Directory.Documents,
                        })
                        // console.log(`saved to ${Directory.Documents}/ownerguest.png`)
                        bookUri = bookUri.replace(" ", "_")
                        // bookUri = `https://ownerguest.com/book/${bookUri}`
                        handleBinder(dataUrl,bookUri, propertyId);  
                    })            
                })
        })
    }
    

    function saveBinderOnStandard(bookUri, propertyId) {          
        htmlToImage.toPng(printRef.current, {
            cacheBust: true, 
            quality: 1, 
            pixelRatio: 1,             
            style: {            
              }, 
        })
        .then((dataUrl) => {                                   
            bookUri = bookUri.replace(" ", "_")
            // bookUri = `https://ownerguest.com/book/${bookUri}`            
            handleBinder(dataUrl, bookUri, propertyId);
        }).catch((error) => {            
            console.log(`error saving review to binder, ${error}`)
            console.log(`Saving binder error: ${error}`)
        })
    }

    const handleSaveToBinder = async (bookUri, propertyId) => {        
        setButtonLoading(true);
        if (isMobile) {
            saveBinderOnMobile(bookUri, propertyId);
            setButtonLoading(false);
        } else {            
            saveBinderOnStandard(bookUri, propertyId);
         
        }        
    }

    
    const handleDownloadImage = async () => {           
        htmlToImage.toPng(printRef.current, {
            cacheBust: true, 
            quality: 1, 
            pixelRatio: 1,             
            style: {            
              }, 
        })
        .then((dataUrl) => {          
            const link = document.createElement('a');        
            if (typeof link.download === 'string') {
                link.href = dataUrl;
                link.download = 'OwnerGuestShareable.png';        
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                window.open(dataUrl);
            }
        });                                          
    };    

    function handleClose() {
        setOpen(false)        
        handleShareableCallback(false)
    }

    function handleImageBlurChange(value) {
        setImageBlurValue(value)                   
    }
    function handlePaddingChange(value) {                 
        setCurrentPaddingValue(value)
    }

    function handleRadiusChange(value) {        
        setCurrentRadiusValue(value)
    }

    function handleShadowChange(value) {
        setCurrentShadowValue(value)
    }

    function handleOpacityChange(value) {
        setCurrentOpacityValue(value)
    }

    function handleXTextPositionChange(value) {
        setCurrentXTextPositionValue(value)
    }

    function handleTextWidthChange(value) {
        setCurrentTextWidthValue(value)
    }
    
    function handleYTextPositionChange(value) {
        setCurrentYTextPositionValue(value)
    }

    function handleXImagePositionChange(value) {
        setCurrentXImagePositionValue(value)
    }

    function handleYImagePositionChange(value) {
        setCurrentYImagePositionValue(value)
    }

    function handleImageWidthChange(value) {
        setCurrentIncrementImageWidthValue(value)
    }

    function handleSelectedShareSize(value) {
        setSelectedShareSize(value);
    }
    
    function handleObjectFitConfig(value) {
        if (objectFitConfig === 'none') {
            setObjectFitConfig('contain');
        } else {            
            setObjectFitConfig(value);
        }
    }

    function getPanelFit() {
        var ratio = getPlatformRatio();        
        var width = PLATFORMSIZES[selectedShareSize][WIDTHINDEX] * ratio;
        var height = PLATFORMSIZES[selectedShareSize][HEIGHTINDEX] * ratio;
        return {width, height}
    }

    function getImageFit() {
        var ratio = getPlatformRatio();  
        var width;
        var height;
        width = (PLATFORMSIZES[selectedShareSize][WIDTHINDEX] * ratio)- 40;
        height = (PLATFORMSIZES[selectedShareSize][HEIGHTINDEX] * ratio) - 70;
    
        if (currentIncrementImageWidthValue === 0) {                    
            if (height > width) {
                height = 'auto';
                width = `${width}px`;
            } else if (width > height) {
                width = 'auto';
                height = `${height}px`;
            } else {
                width = `${width}px`;
                height = `${height}px`;
            }
        }
        else if (currentIncrementImageWidthValue !== 0) {
            // debugger;
            if (width !== 'auto') {
                width += currentIncrementImageWidthValue;
                width = `${width}px`;
            }
            if (height !== 'auto') {
                height += currentIncrementImageWidthValue;
                height = `${height}px`;
            }
        }

        return {width, height}
    }


    
    function handleInlineImageStyle() {             
        var objectFit = `${objectFitConfig}`;        
        const {width, height} = getImageFit();           
        return {                            
            width: `${width}`,
            height: `${height}`,
            display: 'flex',
            margin: 'auto',
            marginTop: '3%',
            alignItems: 'center',
            objectFit: `${objectFit}`,
            padding: `${currentPaddingValue}px`,
            borderRadius: `${currentRadiusValue}rem`,
            boxShadow: `0 9px 36px ${currentShadowValue}px rgba(0,0,0,0.5)`,
            filter: `blur(${imageBlurValue}px) sepia(${sepia}) grayscale(${grayscale})`,
            transform: `translate(${currentXImagePositionValue}rem, ${currentYImagePositionValue}rem)`,            
            
        }
    } 

   function handleInlineTextStyle() {
        return {
            width: `${currentTextWidthValue}%`,
            opacity: `${currentOpacityValue}%`,
            transform: `translate(${currentXTextPositionValue}%, ${currentYTextPositionValue}rem)`,
        }
    }    
    
    function getPlatformRatio() {        
        var platformWidth = PLATFORMSIZES[selectedShareSize][WIDTHINDEX];
        var platformHeight = PLATFORMSIZES[selectedShareSize][HEIGHTINDEX];
        if (platformWidth > platformHeight) {
            return platformHeight / platformWidth;
        } else {
            return platformWidth / platformHeight;
        }        
    }

    

    function handlePanelSizing() {
        // This should be small enough to work with yet respect
        // the ratio of the selected platform size.        
        const {width, height} = getPanelFit();
        return {position:'sticky', top:'0px', zIndex:'998', width:`${width}px`, height:`${height}px`}        
    }


    function handleInlinePanelStyle() {        
        return `overflow-hidden ${gradient}`
    }

    function handleImageFilterChange(value) {                    
        if (imageFilter === value) {
            setImageFilter('');
            setSepia(0);
            setGrayscale(0);
        } else {
            setImageFilter(value);        
            value === "sepia" ? setSepia(100) : setSepia(0);
            value === "grayscale" ? setGrayscale(100) : setGrayscale(0);
        }        
    }


    useEffect(() => {        
        async function fetchData() {            
            setShareable(review);
            setLoading(false);            
        }
        fetchData();     
        let parent = document.getElementById('sticky-element').parentElement;        
        while (parent) {
            // const hasOverflow = getComputedStyle(parent).overflow;
            // if (hasOverflow !== 'visible') {
            //     console.log(hasOverflow, parent);
            // }
            parent = parent.parentElement;
        }   
    }, []);

    

    // "https://ogcdn.sfo3.digitaloceanspaces.com/static/property_images/6aa528a3.f10_1.jpg" 
    return (        
        <>               
            <div className="m-auto grid gap-4 place-content-center h-24">
                
                <Button.Group>
                <input hidden type="file" />
                <Button loading={buttonLoading} color='blue' icon onClick={()=>handleSaveToBinder(shareable?.user_property.nickname, shareable?.user_property.id)} >
                    <Icon name='save' />
                    Save to binder
                </Button>
                <Button icon onClick={handleDownloadImage} color='blue' >
                    <Icon name='download' />
                    Download
                </Button>
                <Button icon onClick={handleClose} color="grey" >
                    <Icon name='close' />
                    Cancel
                </Button>
                </Button.Group>
            </div>                    
        <Card.Group centered>                
            <Card fluid centered style={{position:'sticky', top:'60px', zIndex:'998', alignItems:"center", boxShadow:"none"}}> 
                {/* <CustomShareButton img={shareable?.user_property.image_url} printRef={printRef} review={shareable?.review} bookUrl={`OwnerGuest.com/book/${shareable?.user_property.nickname.replace(/\s/g, "")}`}/>                        */}
                <div id='sticky-element' ref={printRef} style={handlePanelSizing(selectedShareSize)} className={handleInlinePanelStyle()}>                                                          
                    <img style={handleInlineImageStyle()}                              
                        id="currentImg"                         
                        src={shareable?.user_property.image_url}                        
                        alt={shareable?.user_property.nickname} />                             
                    {shareable && shareable.review ? (                        
                            <div style={handleInlineTextStyle()} className="min-fit relative mr-4 items-center px-4 py-2 pt-2 bg-gray-800  left-1/2 -translate-x-1/2 rounded-lg ">                   
                                <span className="text-2xl text-white">                                    
                                    "{highlight !== '' ? highlight : shareable.review}"  
                                    <div className="pr-4 pt-2 pb-2 text-right">- {shareable.reviewer}</div>
                                </span>
                                <span 
                                    style={{display: "table", marginRight: "auto", marginLeft: "auto"}} 
                                    className="text-s text-white shadow-lg">
                                        OwnerGuest.com
                                </span>
                            </div>
                    ):(<>Loading...</>)}                                                                                                    
                </div>            
            </Card>               
            <Card.Group centered>
                <div className="p-4 rounded-lg " style={{width:"min-content"}}>                       
                <Card style={{boxShadow:"7px 10px 12px 7px #d4d4d5, 0 0 0 1px #d4d4d5"}}>
                    <div className="inline float-left  pt-[1rem]">
                        <label className="pl-8">Image Up/Down</label>
                    </div>
                    <div className="inline float-left">
                    <ReactSlider                                
                        className="w-[18rem] mx-auto mt-2 mb-2 h-6 bg-gray-400 opacity-70 rounded-lg max-w-md "
                        trackClassName="h-6 bg-gray-200 text-black background-color: rgba(0,0,0,0.3) rounded-lg "
                        thumbClassName="w-6 h-6 bg-white border-2 border-gray-400 rounded-full shadow-md outline-none cursor-pointer]  "                            
                        defaultValue={0}
                        max={60}
                        min={-60}
                        step={1}                            
                        ariaLabel="Image Position"
                        value={currentYImagePositionValue}
                        onChange={(value) => handleYImagePositionChange(-value)}
                    />  
                    </div>                    
                    <label className="pl-8">Image Left/Right</label>
                    <ReactSlider                                
                        className="w-[18rem] mx-auto mt-2 mb-2 h-6 bg-gray-400 opacity-70 rounded-lg max-w-md "
                        trackClassName="h-6 bg-gray-200 text-black background-color: rgba(0,0,0,0.3) rounded-lg "
                        thumbClassName="w-6 h-6 bg-white border-2 border-gray-400 rounded-full shadow-md outline-none cursor-pointer]  "
                        defaultValue={0}
                        max={20}
                        min={-20}                            
                        step={1}                            
                        ariaLabel="Image Position"
                        value={currentXImagePositionValue}
                        onChange={(value) => handleXImagePositionChange(value)}
                    />  
                    <label className="pl-8">Image Width</label>
                    <ReactSlider                                
                        className="w-[18rem] mx-auto mt-2 mb-2 h-6 bg-gray-400 opacity-70 rounded-lg max-w-md "
                        trackClassName="h-6 bg-gray-200 text-black background-color: rgba(0,0,0,0.3) rounded-lg "
                        thumbClassName="w-6 h-6 bg-white border-2 border-gray-400 rounded-full shadow-md outline-none cursor-pointer]  "
                        defaultValue={0}
                        max={600}
                        min={-600}
                        step={10}                            
                        ariaLabel="Image Width"
                        value={currentIncrementImageWidthValue}
                        onChange={(value) => handleImageWidthChange(value)}
                    />                    
                    <Radio
                        toggle
                        checked={objectFitConfig==="none"}
                        label='Fit'
                        className="p-4"                                                                                                
                        onChange={() => handleObjectFitConfig('none')}
                    />                    
                    <label className="pl-8">Padding</label>
                    <ReactSlider                                
                        className="w-[18rem] mx-auto mt-2 mb-2 h-6 bg-gray-400 opacity-70 rounded-lg max-w-md "
                        trackClassName="h-6 bg-gray-200 text-black background-color: rgba(0,0,0,0.3) rounded-lg "
                        thumbClassName="w-6 h-6 bg-white border-2 border-gray-400 rounded-full shadow-md outline-none cursor-pointer]  "
                        defaultValue={0}
                        max={64}
                        min={0}
                        step={2}
                        label="Padding"
                        ariaLabel="Padding"
                        value={currentPaddingValue}
                        onChange={(value) => handlePaddingChange(value)}
                    />
                    <label className="pl-8">Radius</label>
                    <ReactSlider                                
                        className="w-[18rem] mx-auto mt-2 mb-2 h-6 bg-gray-400 opacity-70 rounded-lg max-w-md "
                        trackClassName="h-6 bg-gray-200 text-black background-color: rgba(0,0,0,0.3) rounded-lg "
                        thumbClassName="w-6 h-6 bg-white border-2 border-gray-400 rounded-full shadow-md outline-none cursor-pointer]  "
                        defaultValue={4}
                        max={12}
                        min={0}
                        step={1}                            
                        ariaLabel="Radius"
                        value={currentRadiusValue}
                        onChange={(value) => handleRadiusChange(value)}
                    />
                    <label className="pl-8">Shadow</label>
                    <ReactSlider                                
                        className="w-[18rem] mx-auto mt-2 mb-2 h-6 bg-gray-400 opacity-70 rounded-lg max-w-md "
                        trackClassName="h-6 bg-gray-200 text-black background-color: rgba(0,0,0,0.3) rounded-lg "
                        thumbClassName="w-6 h-6 bg-white border-2 border-gray-400 rounded-full shadow-md outline-none cursor-pointer]  "
                        defaultValue={14}
                        max={20}
                        min={0}
                        step={1}                            
                        ariaLabel="Shadow"
                        value={currentShadowValue}
                        onChange={(value) => handleShadowChange(value)}
                    />
                    
                    <label className="pl-8">Blur</label>
                    <ReactSlider                                
                        className="w-[18rem] mx-auto mt-2 mb-2 h-6 bg-gray-400 opacity-70 rounded-lg max-w-md "
                        trackClassName="h-6 bg-gray-200 text-black background-color: rgba(0,0,0,0.3) rounded-lg "
                        thumbClassName="w-6 h-6 bg-white border-2 border-gray-400 rounded-full shadow-md outline-none cursor-pointer]  "
                        defaultValue={0}
                        max={16}
                        min={0}
                        step={2}                            
                        ariaLabel="Blur"
                        value={imageBlurValue}
                        onChange={(value) => handleImageBlurChange(value)}
                    />                        
                    <Radio toggle checked={imageFilter==="grayscale"} label="Grayscale" className="p-4" onClick={()=>handleImageFilterChange("grayscale")}/>                        
                    <Radio toggle checked={imageFilter==="sepia"} label="Sepia" className="p-4" onClick={()=>handleImageFilterChange("sepia")}/>                                                                                         
                </Card>          
                </div>
                <div className="p-4 inline float-left text-left rounded-lg" style={{width:"min-content"}}>                                                                       
                <Card style={{boxShadow:"7px 10px 12px 7px #d4d4d5, 0 0 0 1px #d4d4d5"}}>                
                    <label className="pl-8 pt-[1rem]">Text Up/Down</label>
                    <ReactSlider                                
                        className="w-[18rem] mx-auto mt-2 mb-2 h-6 bg-gray-400 opacity-70 rounded-lg max-w-md "
                        trackClassName="h-6 bg-gray-200 text-black background-color: rgba(0,0,0,0.3) rounded-lg "
                        thumbClassName="w-6 h-6 bg-white border-2 border-gray-400 rounded-full shadow-md outline-none cursor-pointer]  "                                                
                        defaultValue={-14}
                        max={45}
                        min={-75}
                        step={1}                                                     
                        ariaLabel="Text Position"
                        value={currentYTextPositionValue}
                        onChange={(value) => handleYTextPositionChange(value)}
                    />   
                    <label className="pl-8">Text Right/Left</label>
                    <ReactSlider                                
                        className="w-[18rem] mx-auto mt-2 mb-2 h-6 bg-gray-400 opacity-70 rounded-lg max-w-md "
                        trackClassName="h-6 bg-gray-200 text-black background-color: rgba(0,0,0,0.3) rounded-lg "
                        thumbClassName="w-6 h-6 bg-white border-2 border-gray-400 rounded-full shadow-md outline-none cursor-pointer]  "
                        defaultValue={-50}
                        max={100}
                        min={-200}
                        step={1}                              
                        ariaLabel="Text H Position"
                        value={currentXTextPositionValue}
                        onChange={(value) => handleXTextPositionChange(value)}
                    />
                    <label className="pl-8">Text Width</label>
                    <ReactSlider                                
                        className="w-[18rem] mx-auto mt-2 mb-2 h-6 bg-gray-400 opacity-70 rounded-lg max-w-md "
                        trackClassName="h-6 bg-gray-200 text-black background-color: rgba(0,0,0,0.3) rounded-lg "
                        thumbClassName="w-6 h-6 bg-white border-2 border-gray-400 rounded-full shadow-md outline-none cursor-pointer]  "
                        defaultValue={100}
                        max={100}
                        min={0}
                        step={10}                            
                        ariaLabel="Text Width"
                        value={currentTextWidthValue}
                        onChange={(value) => handleTextWidthChange(value)}
                    />
                    <label className="pl-8">Text Opacity</label>
                    <ReactSlider                                
                        className="w-[18rem] mx-auto mt-2 mb-2 h-6 bg-gray-400 opacity-70 rounded-lg max-w-md "
                        trackClassName="h-6 bg-gray-200 text-black background-color: rgba(0,0,0,0.3) rounded-lg "
                        thumbClassName="w-6 h-6 bg-white border-2 border-gray-400 rounded-full shadow-md outline-none cursor-pointer]  "
                        defaultValue={70}
                        max={100}
                        min={0}
                        step={10}                            
                        ariaLabel="Text Background Opacity"
                        value={currentOpacityValue}
                        onChange={(value) => handleOpacityChange(value)}
                    />
                    <hr className="float-left mt-4 mb-[1rem]"/>
                    <Radio
                        className="w-[18rem] mx-auto mt-2 mb-2 h-6 opacity-70 rounded-lg max-w-md "
                        label='Twitter Feed'
                        name='radioGroup'
                        value='twitterFeed'
                        checked={selectedShareSize === 'twitterFeed'}
                        onChange={() => handleSelectedShareSize('twitterFeed')}
                    />                
                    <Radio
                        className="w-[18rem] mx-auto mt-2 mb-2 h-6 opacity-70 rounded-lg max-w-md "
                        label='Facebook Feed'
                        name='radioGroup'
                        value='facebookFeed'
                        checked={selectedShareSize === 'facebookFeed'}
                        onChange={() => handleSelectedShareSize('facebookFeed')}
                    />
                        <Radio
                        className="w-[18rem] mx-auto mt-2 mb-2 h-6 opacity-70 rounded-lg max-w-md "
                        label='Pinterest'
                        name='radioGroup'
                        value='pinterest'
                        checked={selectedShareSize === 'pinterest'}
                        onChange={() => handleSelectedShareSize('pinterest')}
                    />   
                    <Radio
                        className="w-[18rem] mx-auto mt-2 mb-2 h-6 opacity-70 rounded-lg max-w-md "
                        label='Square'
                        name='radioGroup'
                        value='square'
                        checked={selectedShareSize === 'square'}
                        onChange={() => handleSelectedShareSize('square')}
                    />                
                </Card>
                </div>                                
                <Card style={{boxShadow:"7px 10px 12px 7px #d4d4d5, 0 0 0 1px #d4d4d5"}}>
                    <div className="p-4 inline float-left text-left rounded-lg shadow-lg">                                                                     
                        <label className="pl-8">Gradient</label>
                        {/* <div className="p-4 min-w-8 bg-gradient-to-r from-[#EEBD89] to-[#D13ABD]">what is in here</div> */}
                        {Object.keys(GRADIENTCOLORS).map((color, index) => (                        
                            <div key={index} className="block p-2">                                   
                                <div className={getColorSelectorClass(color)}  onClick={() => handleGradientColorChange(color)}>                                                            
                                </div>
                            </div>
                        ))}
                    </div>
                </Card> 
            </Card.Group>  
            {/* <Card.Group>
                <Card centered style={{alignItems:"center"}}>
                  <Card.Content>
                        <input hidden type="file" />
                        <Button icon="save" onClick={()=>handleSaveToBinder(shareable?.user_property.nickname, shareable?.user_property.id)} positive />
                        <Button icon='download' onClick={handleDownloadImage} positive />
                        <Button icon='cancel' onClick={handleClose} color="grey" />
                    </Card.Content>               
                </Card>
            </Card.Group>                          */}
        </Card.Group>        
        </>                              
    )
}
