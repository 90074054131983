const POSTSIZE = {
    "Choose Size": [0, 0],
    "Instagram Post Image":[1080, 1080],
    "Facebook Post Image":[1200, 628],
    "Twitter Post Image":[1200, 675], 
    "Pinterest Post Image":[735, 1102],  
    "Instagram Story":[1080, 1920],  
    "Facebook Story":[1080, 1920],            
    "Instagram Profile Image": [440, 440],      
    "Twitter Profile Image":[400, 400],
    "Facebook Header":[820, 312],  
    "Twitter Header":[1500, 500],       
  }



export default function SelectPostSize({setSelectedPostSize, setPostPurpose}) {    
    const handleButtonClick = (value) => {        
        setSelectedPostSize(POSTSIZE[value]);
        setPostPurpose(value);        
    }

    return (
        <select onChange={e => handleButtonClick(e.target.value)}>
        {Object.keys(POSTSIZE).map((key) => (
        <option key={key} value={key}>
            {key}
            </option>
        ))}        
        </select>     

    )
}

