import React, { useContext, useEffect, useState } from "react";
import { getGetHeaders } from "../utils/headers";
import { Container, Label, Message, Segment, Header, Icon, Card, Button } from 'semantic-ui-react';
import DeleteButton from "../components/DeleteButton";
import { AuthContext } from "../context/auth";
import TemplateBuilder from "./TemplateBuilder";
import PropertyListDropdown from "../components/PropertyListDropdown";
import { getPostHeaders } from "../utils/headers";

function TemplateList() {
    // Form to add a new template that accepts a name, description and a list of hashtags
    const { user } = useContext(AuthContext);
    const { context } = useContext(AuthContext);
    const [errors, setErrors] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [values, setValues] = useState({})
    const [templates, setTemplates] = useState([]);
    const [templatesUpdated, setTemplatesUpdated] = useState(false);
    const [showTemplateBuilder, setShowTemplateBuilder] = useState(false);
    const [aIEnabled, setAiEnabled] = useState(false);
    const [cursor, setCursor] = useState('pointer');
    const [propId, setPropId] = useState(null);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    
    useEffect(() => {
        async function fetchTeamplates() {        
            const headers = getGetHeaders();
            setLoading(true);
            if (!user) { context.logout({}); }
            
            fetch(`${process.env.REACT_APP_BE_BASE_URL}/api/templates/`, {
                headers
            })
            .then(response => response.json())
            .then(data => {
                if (data.status === 401) {
                    context.logout({});
                }                                        
                setTemplates(data);
                setLoading(false);                
            })
            .catch(error => console.log('error', error));
        }
        fetchTeamplates();
        setTemplatesUpdated(false);
         // eslint-disable-next-line react-hooks/exhaustive-deps      
    }, [templatesUpdated]);

    function handleShowTemplateBuilder() {
        setShowTemplateBuilder(!showTemplateBuilder);
    }

    const handleCreateTemplateCallback = (created) => {
        if (created) {
            setTemplatesUpdated(true);
        }
    }


    function copyTemplate(template) {
        navigator.clipboard.writeText(template);
    }

    async function generateFromAI() {
        setCursor('wait');
        const headers = getPostHeaders({values}, "POST");
        const response = await fetch(`${process.env.REACT_APP_BE_BASE_URL}/api/generatelp/`,
            {...headers})
            .catch(error => {setErrors(true); setErrorMessage(error.message);})
        if (response.status === 200) {
            handleCreateTemplateCallback(true);
            setCursor('pointer');
        } else if (response.status === 401) {
            context.logout({})
        } else if (response.status === 500) {
            setErrors(true);            
            setErrorMessage("Did you select a property?");
            setCursor('pointer');
        }
        
    }

    const handleDeleteCallback = (deleted) => {
        if (deleted) {
            setTemplatesUpdated(true);
        }
    }

    function handlePLCallback(propertyId) {          
        if (propertyId !== undefined) { 
            setPropId(propertyId);     
            setValues({...values, propertyId: propertyId, request_type: "template"})
            setButtonDisabled(false)
        } else {
            setButtonDisabled(true);
            setAiEnabled(false);
        }
    }

    return (
        <Container>
            {loading ? (
                <div className="ui active centered inline loader"></div>
            ) : (
            
            <Segment>                                
                <Header as="h2">Create posts & tweet content </Header>
                <Header.Subheader>Feeling creative? Roll your own, otherwise, use AI to generate content by analyzing your last few reviews.</Header.Subheader>
                {templates && templates.length > 0 ? (
                    <>
                    
                    </>
                ) : (
                    <Header.Subheader>
                        <div className='text-2xl p-4'>
                            You have not created any posts or tweets yet
                        </div>
                    </Header.Subheader>
                )}
                    <div className="ui divider"></div>
                    <Button.Group toggle>
                        <Button 
                            key="b1"                             
                            basic  
                            disabled={buttonDisabled}
                            toggle                            
                            active={showTemplateBuilder}
                            color="blue"                         
                            onClick={handleShowTemplateBuilder}                             
                        >
                                Create New
                        </Button>                                                         
                        <Button 
                            key="b2"
                            style={{ cursor: cursor, marginRight:"5px", marginLeft:"2px" }}
                            basic   
                            disabled={buttonDisabled}                         
                            color="green"                            
                            // enabled={aIEnabled}
                            onClick={generateFromAI}                     
                        >
                            Generate from AI
                        </Button>                         
                        </Button.Group>                                                              
                        <PropertyListDropdown handleCallback={handlePLCallback}/> 
                        {errors ? (
                            <Message negative>
                                <Message.Header>
                                    Error generating from AI
                                </Message.Header>
                                <p>{errorMessage}</p>
                            </Message>                            
                        ):(<></>)}
                                                                   
                
                {showTemplateBuilder &&                     
                    <TemplateBuilder handleCallback={handleCreateTemplateCallback} propertyId={propId}/>                    
                }
                
                

                <div className="ui divider"></div>
                {templates.length > 0 ? (
                  <>                    
                    <Card.Group>
                    {templates.map((template, index) => (
                            <Card key={template.id}>                                
                                <Card.Content>
                                <Label as='a' color='blue' ribbon style={{marginBottom:"5%"}}>
                                    {template.for_property?.nickname}
                                </Label>
                                    <Card.Header>{template?.name}<div title="Click to copy all" className="cursor-pointer float-right border-none"><Icon onClick={()=>copyTemplate(template.description + " " + template.hashtags)} name="copy outline" style={{outline:"none"}}/></div></Card.Header>                                    
                                    
                                </Card.Content>
                                <Card.Content>
                                    <Card.Header as='h4'>Description<div title="Click to copy description" className="cursor-pointer float-right border-none"><Icon onClick={()=>copyTemplate(template.description)} name="copy outline" size="small" style={{outline:"none"}}/></div></Card.Header>                                    
                                    <Card.Description>{template?.description}</Card.Description>      
                                </Card.Content>
                                <Card.Content>                          
                                        <Card.Header as='h4'>Hashtags<div title="Click to copy hashtags" className="cursor-pointer float-right border-none"><Icon onClick={()=>copyTemplate(template.hashtags)} name="copy outline" size="small" style={{outline:"none"}}/></div></Card.Header>
                                        <div className="p-2"></div>
                                        {template?.hashtags?.split("#").map((hashtag, index) => (                                                                          
                                            <React.Fragment key={index}>
                                                {hashtag.length > 0 ? (
                                                    <div key={hashtag.id} className="text-blue-400 bg-blue-50 rounded-lg shadow-lg w-fit float-left  px-2 py-1 m-2 ">#{hashtag}</div>
                                                    
                                                ) : (
                                                    <></>
                                                )} 
                                            </React.Fragment>
                                        ))}
                                </Card.Content>
                                <Card.Content extra>
                                    <DeleteButton handleCallback={handleDeleteCallback} typeOfObject="template" id={template.id} />
                                </Card.Content>
                        </Card>
                    ))}
                    </Card.Group>                   
                
                  </>  
                  ) : (<></>)}
                
            </Segment>
            )}

        </Container>
    );
}

export default TemplateList;


//     const context = useContext(AuthContext);
//     const [templates, setTemplates] = useState([]);
//     const [errors, setErrors] = useState({});
//     const [loading, setLoading] = useState(false);
//     const [success, setSuccess] = useState(false);

//     useEffect(() => {
//         const getTemplates = async () => {
//             setLoading(true);
//             const headers = getGetHeaders();
//             const response = await fetch(`${process.env.REACT_APP_BE_BASE_URL}/api/templates`, headers);
//             const body = await response.json().catch(error => console.log('error', error));
//             setTemplates(body);
//             setLoading(false);
//         }
//         getTemplates();
//     }, []);

//     const addTemplate = async () => {
//         const headers = getPostHeaders({}, "POST");
//         const response = await fetch(`${process.env.REACT_APP_BE_BASE_URL}/api/add_template`, headers);
//         const body = await response.json().catch(error => console.log('error', error));
//         // handleCallback(true);
//         setSuccess(true);
//     }

//     const { onSubmit, values } = useForm(addTemplate, {
//         name: '',
//         description: '',
//         hashtags: '',
//     });

//     function onChange(event) {
//         values[event.target.name] = event.target.value;
//     }

//     function onHashTagChange(e) {
//         console.log(e.target.value);
//     }

//     function performValidation() {                           
//         return values.name.length > 0 && values.description.length > 0;
//     }

//     return (
//         <Container className='p-4 m-4'>                
//             <Segment.Group>
//                 <Segment >
//                     <Header as="h3">
//                         Create a template
//                     </Header>
//                     <Form onSubmit={onSubmit} noValidate className={loading ? 'loading' : ''}>
//                         <Form.Input
//                             label="Name"
//                             placeholder="Name.."
//                             name="name"
//                             type="text"
//                             value={values.name}
//                             error={errors.name? true : false}
//                             onChange={onChange}
//                         />
//                         {errors ? (<p><Badge color="danger">{errors.name}</Badge></p>
//                                                 ) : <p></p>
//                         } 
//                         <Form.TextArea
//                             label="Description"
//                             placeholder="What do you want to say about your property.."
//                             name="description"
//                             type="text"
//                             value={values.description}
//                             error={errors?.description? true : false}
//                             onChange={onHashTagChange}

//                         />
//                         <Form.TextArea
//                             label="Hashtags"
//                             placeholder="Hashtags"
//                             name="hashtags"
//                             type="text"
//                             value={values.hashtags}
//                             error={errors?.hashtags? true : false}
//                             onChange={onChange}
//                         />  
//                         {errors ? (<p><Badge color="danger">{errors.hashtags}</Badge></p>
//                                                 ) : <p></p>
//                         }    
//                         <TweetTextarea />           
//                         <Button type="submit" primary disabled={!performValidation()} >
//                             Create Template
//                         </Button>
//                     </Form>
//                 </Segment>
//             </Segment.Group>
//         {Object.keys(errors).length > 0 && (
//             <div className="ui error message">
//                 {errors}
//                 {/* <ul className="list">
//                     {Object.values(errors).map((value) => (
//                         <li key={value}>{value}</li>
//                     ))}
//                 </ul> */}
//             </div>
//         )}
//         {success && (
//             <div className="ui message">
//                 Template created!                     
//             </div>
//         )}
        
//     </Container>
// )

// }

// export default TemplateList;