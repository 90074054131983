import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, Header, Button } from "semantic-ui-react";

function PrivacyPolicy({backToHome, handleSignUp}) {
    let navigate = useNavigate();

    // const handleClick = () => {
    //     navigate("/");
    // }

  return (
    <>
    <Container text>
    <div className="text-xl leading-14 pt-8 pb-8">
      <Header as='h1'>Privacy Policy</Header>
      <p>
        This privacy policy applies to the web app ("app", "we", "us", "our")
        and explains how we collect, use, and share information about you when
        you use our app.
      </p>
      <p>
        By using our app, you agree to the collection, use, and sharing of your
        information as described in this privacy policy. If you do not agree
        with our policies and practices, do not use our app.
      </p>
      <Header as='h2'>Information We Collect</Header>
      <p>We collect information about you when you use our app, including:</p>
      <ul>
        <li>
          IP address: We log your IP address for security and debugging
          purposes. Your IP address does not personally identify you, but it can
          be used to determine your location and referrer.
        </li>
        <li>
          Location: We use your IP address to determine your approximate
          location (city). This information is used to provide personalized
          content and to improve our app.
        </li>
        <li>
          Referrer: We log the referrer (the webpage that linked to our app) for
          metrics, security and debugging purposes. This information does not personally
          identify you, but it may be used to understand how users are accessing
          our app.
        </li>
      </ul>
      <Header as='h2'>Use of Your Information</Header>
      <p>We use the information we collect about you to:</p>
      <ul>
        <li>
          Provide, maintain, and improve our app: We use your information to
          provide, maintain, and improve our app. This may include using your
          location to provide personalized content, or using your referrer to
          understand how users are accessing our app.
        </li>
        <li>
          Communicate with you: We may use your information to communicate with
          you, such as to respond to your requests or questions.
        </li>
        <li>
          Protect our app and users: We use your information to protect our app
          and users, such as by detecting and preventing fraud and abuse.
        </li>
      </ul>
      <Header as='h2'>Sharing of Your Information</Header>
      <p>
        We do not share your personal information with third parties, except as
        described in this privacy policy.
      </p>
      <p>
        We may share your information with third parties for the following
        purposes:
      </p>
      <ul>
        <li>
          Service providers: We may share your information with service
          providers who perform services on our behalf, such as hosting and
          maintenance, analytics, and security. These service providers are
          required to protect your information and may only use it as directed
          by us.
        </li>
        <li>
          Legal purposes: We may share your information if we believe it is
          necessary to comply with a legal obligation, such as a court order or
          subpoena. We may also share your information if we believe it is
          necessary to protect the rights, property, or safety of us, our users,
          or the public.
        </li>
        <li>
          Business transfers: In the event that we are acquired by or merged
          with a third party, we may transfer your information to the new owner
          as part of the acquisition or merger.
        </li>
      </ul>      
      <h2>Security of Your Information</h2>
      <p>
        We take reasonable measures to help protect your information from loss,
        theft, misuse, and unauthorized access, disclosure, alteration, and
        destruction.
      </p>
      <Header as='h2'>Your Choices and Rights</Header>
<p>
You have the following choices and rights regarding your information:
</p>
<p>
Opting out: You can opt out of certain uses of your information, such as by unsubscribing from our emails.
</p>
<p>
Access and correction: You can access and correct your information by contacting us at ownerguestapp at gmail.com.
</p>
<p>
Deletion: You can request that we delete your information by contacting us at  ownerguestapp at gmail.com. Please note that some information may be retained for legal or security purposes.
</p>
      <Header as='h2'>Changes to Our Privacy Policy</Header>
      <p>
        We may change this privacy policy from time to time. If we make any
        changes, we will change the Last Updated date above.
      </p>
      <p>
      Contact Us

If you have any questions or concerns about our privacy policy or the use of your information, please contact us at ownerguestapp at gmail.com.
      </p>
    </div>
    
    </Container>
     <Container text style={{paddingBottom:"1%", paddingTop:"1%"}}>
     <Button color="blue" onClick={backToHome}>Cancel</Button><Button color="blue" onClick={handleSignUp}>Try It Now</Button>
     </Container>
     </>
  );
};

export default PrivacyPolicy;
