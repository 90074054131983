import React from "react";
import { Popup, Grid, Image, Button, Placeholder, Header, Container, Segment } from "semantic-ui-react";
import { useParams } from "react-router-dom";
import { useOnPageLeave } from "../util/leaving";
import { Helmet } from "react-helmet";

export default function BookingPage() {
  const { binderId } = useParams();
  const {
    bookingLinks,
    propertyNickname,
    propertyImage,
    landingPageTitle,
    landingPageText,
    updateMetrics,
    fetchIPData,
  } = useOnPageLeave(binderId);
  const og_id = localStorage.getItem('_og_id');  

  window.onbeforeunload = () => {  
    updateMetrics(`window.onbeforeunload`, og_id);
  };

  return (
    <>
      <Helmet>
        <title>OwnerGuest</title>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@ownerguestapp" />
        <meta name="twitter:title" content={`OwnerGuest: ${landingPageTitle}`}/>
        <meta name="twitter:description" content={landingPageText} />
        <meta name="twitter:image" content={propertyImage} />
        <meta name="twitter:image:alt" content="An OwnerGuest Property" />
      </Helmet>

      <Grid
        stackable
        centered
        columns={1}
        width={16}
        className="bg-gradient-to-r from-cyan-500 to-blue-500"
      >
        <Grid.Column width={16}>
          <a href={process.env.REACT_APP_FE_BASE_URL}>
            <Image
              size="medium"
              src="/images/ogtext_white.png"
              alt="OwnerGuest"
            />
          </a>
        </Grid.Column>
      </Grid>

      <Grid stackable>
        <Grid.Row style={{ paddingTop: "0px" }}>
          <Grid.Column width={3}>
            <div className="text-4xl p-4 text-bold object-contain">
              <span>Booking Partners</span>
            </div>
            {Object.entries(bookingLinks).map(([key, value]) => (
              <React.Fragment key={key}>
                {value === "" ? (
                  <Popup
                    trigger={
                      <Button
                        key={key}
                        onClick={() => updateMetrics(key)}
                        size="huge"
                        className="!opacity-100 p-2 m-2"
                        style={{ margin: "0.5rem" }}
                        color="grey"
                      >
                        {key}
                      </Button>
                    }
                    content={`Sorry, we do not support ${key} yet, let us know if you would like us to add it.`}
                    on="click"
                  />
                ) : (
                  <Button
                    key={key}
                    onClick={() => updateMetrics(key, og_id)}
                    size="huge"
                    className="m-2"
                    style={{ margin: "0.5rem" }}
                    color="blue"
                    href={value}
                    target="_blank"
                  >
                    {key}
                  </Button>
                )}
              </React.Fragment>
            ))}
          </Grid.Column>
          <Grid.Column width={13}>
            {propertyImage ? (
              <>
                <Container>
                <p></p>
                <Header size='huge' attached="top">{landingPageTitle ? landingPageTitle : "An OwnerGuest supported property"}</Header>                                                
                <Segment attached>
                    {landingPageText ? landingPageText : "OwnerGuest helps vacation property owners get more bookings from their favorite booking sites. We combine the power of AI with real guest reviews to get you more bookings."}
                </Segment>
                <Image src={propertyImage} fluid className="relative" alt="Booking property image"/>                
                </Container>
              </>
            ) : (
              <Placeholder
                style={{
                  height: 600,
                  width: 600,
                  marginLeft: "5%",
                  marginTop: "5%",
                  marginBottom: "5%",
                }}
              >
                <Placeholder.Image />
              </Placeholder>
            )}
            
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
}
