import React, {useState} from "react";
import {Icon} from "semantic-ui-react";
import * as htmlToImage from 'html-to-image';

export default function DownloadAsset({ printRef, imageDownloadIcon, iconStyle}) {
    const [buttonLoading, setButtonLoading] = useState(false);

    const handleDownloadImage = async () => {  
        setButtonLoading(true);                        
        htmlToImage.toPng(printRef.current, {
            cacheBust: true, 
            quality: 1, 
            pixelRatio: 1,                     
            style: {},  
        })
        .then((dataUrl) => {               
            const link = document.createElement('a');        
            if (typeof link.download === 'string') {
                link.href = dataUrl;
                link.download = 'OwnerGuestShareable.png';        
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                window.open(dataUrl);
            }
            setButtonLoading(false);
        });                                          
    };    

    return(        
        <div>
        <Icon 
            circular 
            inverted 
            color={imageDownloadIcon}  
            active={buttonLoading} 
            loading={buttonLoading} 
            name='download' 
            style={iconStyle}
            onClick={handleDownloadImage} 
             />
    </div>
)
}