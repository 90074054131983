import React from "react";

const PricingPage = () => {
  return (
    // <stripe-pricing-table pricing-table-id="prctbl_1MPng8AiBBsFq5cLg2GZ3SbD"
    // publishable-key="pk_test_51MNmpQAiBBsFq5cLAXUwGpDLU4kSVy0o3pPME8SwkRjgnvm4X8cGOGd3uhNmluxn6aWQGxC210Kbg3fw75Wald7f00YdlUUaRv">
    // </stripe-pricing-table>

    <stripe-pricing-table
      pricing-table-id="prctbl_1MOqMPAiBBsFq5cLpbjn6wS5"
      publishable-key="pk_live_51MNmpQAiBBsFq5cLv0Tj2ycKpn1mlQPr7Vk6O34bKA814OmMXntgq74cvtk8ElFsnQro5SdY32XzePKio3eNrENf00AyeSpWdI"
    ></stripe-pricing-table>
  );
};

export default PricingPage;
