import { Icon } from 'semantic-ui-react';

export function getStars(rating) {
    let stars = []
    for (let i = 0; i < rating; i++) {
        stars.push(<Icon key={i} name='star' size='small' color='yellow'/>)
    }
    return stars
}

export function buildOptions(tags) {    
    let options = [];
    for (let i = 0; i < tags.length; i++) {
      try {
        options.push({
            key: tags[i].id, 
            text: tags[i].tag, 
            value: tags[i].tag})
      } catch (error) {
        console.log(error)
      }          
    }                     
    return options;
  }