import React, {useState, useContext, useEffect} from 'react'
import { Card, Image, Dropdown, Icon, Label, Modal, Button,  } from 'semantic-ui-react'
import CustomShareButton from '../components/CustomShareButton';
import DeleteButton from '../components/DeleteButton';
import { getPutHeaders } from '../utils/headers';
import { AuthContext } from '../context/auth';
import BinderAssetMetrics from '../components/BinderAssetMetrics';

function BinderCard({handleTimeout, handleCallback, id, image, bookUrl, masterPiece, templateList, landingPageList})  { 
    const context = useContext(AuthContext);
    const [isHover, setIsHover] = useState(false);
    // const [selectedTemplate, setSelectedTemplate] = useState("");
    const [hashtags, setHashtags] = useState("");
    const [showMetrics, setShowMetrics] = useState(false);
    const [open, setOpen] = useState(false)


    const printRef = React.useRef();
    const handleDeleteCallback = (deleted) => {
        if (deleted) {
            handleCallback(true);
        }
    }
    
   
    // when a landing page is selected, update the binder card
    const updateBinderCard = (objectId, type) => {        
        let headers;
        type = type === "template" ? headers = getPutHeaders({template_id: objectId}) : 
            headers = getPutHeaders({landing_page_id: objectId})        
                
        fetch(`${process.env.REACT_APP_BE_BASE_URL}/api/binders/${id}/`, {            
            ...headers            
        })
        .then(response => {
            if (response.status === 401) {context.logout({})}
            if (response.status === 200) {                
                handleCallback(true);
            }
        })
        .catch(error => {
            console.log("updateBinderCard error", error)
        })
    }

    

    const handleMouseEnter = () => {
        setIsHover(true);
    };
    const handleMouseLeave = () => {
        setIsHover(false);
    };

    const handleTemplateSelect = (e, data) => {                
        let values = templateList.filter(x => x.id===data.value)[0]                
        // setSelectedTemplate(`${values.description} ${values.hashtags}`);    
        setHashtags(values.hashtags);
        updateBinderCard(values.id, "template");    
    }

  
    const handleLandingPageSelect = (e, data) => {        
        let values = landingPageList.filter(x => x.id===data.value)[0]        
        // setSelectedLandingPage(`${values.description} ${values.hashtags}`);        
        updateBinderCard(values.id, "landingPage");
    }




   const boxStyle = {
        position: isHover ? 'relative' : 'none',
        top: isHover ? '50%' : '0px',
        left: isHover ? '0%' : '0px',
        display: 'block',
        zIndex: isHover ? '999' : '1',
        cursor: isHover ? 'none': 'zoom-in',
        WebkitTransitionProperty: isHover ? 'all' : 'none',
        WebkitTransitionDuration: isHover ? '0.5s' : 'none',
        WebkitTransitionTimingFunction: isHover ? 'ease' : 'none',
        transform: isHover ? 'scale(2.5)' : 'scale(1)',
   };

   const templateNames = templateList.map((template) => {
         return {key: template.id, text: template.name, value: template.id}
    })

    const landingPageTitles = landingPageList.map((landingPage) => {
        return {key: landingPage.id, text: landingPage.title, value: landingPage.id}
    })

    useEffect(() => {               
        if (masterPiece?.sharing_template?.description) {
            // setSelectedTemplate(`${masterPiece?.sharing_template?.description} ${masterPiece?.sharing_template?.hashtags}`)
            setHashtags(`${masterPiece?.sharing_template?.hashtags}`);

        }
        // if (masterPiece?.landing_page?.title) {
        //     setSelectedLandingPage(`${masterPiece?.landing_page?.title} ${masterPiece?.landing_page?.hashtags}`)
        // }
        // console.log(masterPiece?.sharing_template?.description);
    }, [masterPiece])

    function copyTemplate() {        
        let fullUrl = `${process.env.REACT_APP_FE_BASE_URL}/book${bookUrl}`;        
        navigator.clipboard.writeText(fullUrl);
        // console.log("copied", fullUrl);
        fullUrl = fullUrl.replace(/^https?:\/\/(www\.)?/, 'https://');
        // console.log("copied", fullUrl);
        handleTimeout();
    }

    function handleCopy(value){   
        // debugger;     
        navigator.clipboard.writeText(value);        
        handleTimeout();
    }

    function handleCopyAll(masterPiece) {
        let fullUrl = `${process.env.REACT_APP_FE_BASE_URL}/book${bookUrl}`;
        fullUrl = fullUrl.replace(/^https?:\/\/(www\.)?/, 'https://');
        let hashtags = masterPiece?.sharing_template?.hashtags;
        let description = masterPiece?.sharing_template?.description
        let fullText = `${description} ${fullUrl} ${hashtags}`;
        navigator.clipboard.writeText(fullText);
        // console.log("copied", fullText);
        handleTimeout();
    }

    function handleShowMetrics() {
        setShowMetrics(!showMetrics);
    }

    return (
        <Card raised key={id} style={{margin:"1%"}}>
            <Card.Content >  
            <Label as='a' color='blue' ribbon style={{marginBottom:"5%"}}>{masterPiece?.post_purpose}</Label>               
                <div ref={printRef}>         
                    <Image    
                        alt="Booking landing page cover"                                        
                        src={image}
                        style={boxStyle}
                        // onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        onClick={handleMouseEnter}
                    />                                                                                       
                </div>
                
                <Card.Meta>                    
                                
                </Card.Meta>  
                <Card.Meta>
                    <Card.Content>
                        <Card.Header>
                            {/* aftert clicking, display a check or something that fades */}
                            <div 
                                cursor="pointer"
                                title="Copy sharing link" 
                                className="float-left border-none cursor-pointer"
                                onClick={()=>copyTemplate(bookUrl)} >                                                                    
                                <Icon 
                                    onClick={()=>copyTemplate(bookUrl)} 
                                    name="linkify" 
                                    style={{outline:"none"}}/>                                                                    

                            </div>
                            <div style={{paddingLeft:".5rem", display:"inline"}}>|</div>
                            <span className='p-2'>                           
                                <a href={`${process.env.REACT_APP_FE_BASE_URL}/book${bookUrl}`} title="Open landing page" target="_new">Go!</a>
                            </span>
                            <DeleteButton handleCallback={handleDeleteCallback} typeOfObject="binder" id={id} />
                            <Modal
                                closeIcon={true}
                                size="small"
                                onClose={() => setOpen(false)}
                                onOpen={() => setOpen(true)}
                                open={open}
                                trigger={<Icon 
                                    style={{float:"right", outline:"none"}}                                
                                    title="View metrics" 
                                    name="chart bar" onClick={handleShowMetrics}></Icon>}
                                >
                                <Modal.Header>Metrics</Modal.Header>
                                <Modal.Content>
                                    <BinderAssetMetrics binder={masterPiece} />                  
                                </Modal.Content>
                                <Modal.Actions>
                                <Button color='black' onClick={() => setOpen(false)}>
                                    Close
                                </Button>                  
                                </Modal.Actions>
                            </Modal> 
                            <div 
                                cursor="pointer"
                                title="Copy hashtags" 
                                className="float-left border-none cursor-pointer"
                                // onClick={()=>navigator.clipboard.writeText(hashtags)} >                                    
                                onClick={()=>handleCopy(hashtags)} >
                                <Icon 
                                    // onClick={()=>navigator.clipboard.writeText(hashtags)} 
                                    onClick={()=>handleCopy(hashtags)}
                                    name="hashtag" 
                                    style={{outline:"none"}}/>                                     
                            </div>   
                            

                            <div 
                                cursor="pointer"
                                title="Copy description, link and hashtags" 
                                className="float-left border-none cursor-pointer"
                                onClick={()=>handleCopyAll(masterPiece)} >                                    
                                <Icon 
                                    onClick={()=>handleCopyAll(masterPiece)} 
                                    name="copy outline" 
                                    style={{outline:"none"}}/>                                
                                </div>   
                            </Card.Header>                                    
                    </Card.Content>                    
                </Card.Meta>
                          

                
                <Card.Description>
                    <Dropdown 
                        fluid 
                        selection
                        placeholder={masterPiece?.sharing_template?.name ? masterPiece?.sharing_template?.name : 'Select template'}
                        options={templateNames} 
                        value={`${masterPiece?.sharing_template?.description} ${masterPiece?.sharing_template?.hashtags}`} 
                        onChange={handleTemplateSelect}
                        // value={masterPiece?.sharing_template?.name}
                    />
                    <Dropdown 
                        fluid 
                        selection
                        placeholder={masterPiece?.landing_page?.title ? masterPiece?.landing_page?.title : 'Select landing page'}
                        options={landingPageTitles} 
                        value={`${masterPiece?.landing_page?.title} ${masterPiece?.landing_page?.hashtags}`}
                        onChange={handleLandingPageSelect}
                        // value={masterPiece?.landing_page?.title}
                    />
                </Card.Description>
            
            </Card.Content>   
            <Card.Content extra>    
                <CustomShareButton 
                    imgUrl={image}
                    txtQuote={masterPiece?.sharing_template?.description}                                                           
                    bookUrl={bookUrl}
                    hashtags={hashtags}/>                                       
                {/* <DeleteButton handleCallback={handleDeleteCallback} typeOfObject="binder" id={id} /> */}
            </Card.Content>                 
             
        </Card>                   

    )
}

export default BinderCard;